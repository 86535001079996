import { Flex, Select } from "@chakra-ui/react"
import React from "react"
import { IntlShape, useIntl } from "react-intl"
import { TcoCalculationItem } from "../../../../models/calculation/tcoCalculationItem"
import {
  isSmallPipe,
  mapToLocalisedPreInsulatedPipe,
} from "../../mappers/mapToLocalisedPreInsulatedPipeKind"

type SelectSystemProps = {
  tcoCalculationItems: TcoCalculationItem[]
  onSystemChange: (tcoCalculationItem: TcoCalculationItem) => void
}
export const SelectSystem: React.FC<SelectSystemProps> = ({
  tcoCalculationItems,
  onSystemChange,
}) => {
  const intl = useIntl()
  return (
    <Flex direction={"column"}>
      <Select
        placeholder={intl.formatMessage({
          id: "select-system-type",
          defaultMessage: "Select type of system",
        })}
        onChange={(event) => {
          const tcoCalculationItemIndex = Number(event.target.value)
          onSystemChange(tcoCalculationItems[tcoCalculationItemIndex])
        }}
      >
        {tcoCalculationItems.map((tcoItem, index) => {
          return (
            <option key={index} value={index}>
              {mapToOptionName(tcoItem, intl)}
            </option>
          )
        })}
      </Select>
    </Flex>
  )
}

const mapToOptionName = (
  tcoCalculationItem: TcoCalculationItem,
  intl: IntlShape
): string => {
  return mapToLocalisedPreInsulatedPipe(
    tcoCalculationItem.pipeSystemPreInsulatedPipeKind,
    intl,
    undefined,
    isSmallPipe(tcoCalculationItem),
    tcoCalculationItem.insulationSeriesKind
  )
}
