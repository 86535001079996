import { ContentWidth } from "../shared/ContentWidth/ContentWidth"
import React from "react"
import { TcoGraphs } from "./TcoGraphs/TcoGraphs"
import { useCalculation } from "../../rest/useCalculation"
import { Heading, HStack } from "@chakra-ui/react"
import { useShowNetworkError } from "../../hooks/useShowNetworkError"
import { HeatLossGraphs } from "./HeatLossGraphs/HeatLossGraphs"
import { EmissionGraphs } from "./EmissionGraphs/EmissionGraphs"
import {
  CompareSystemGraphs,
  ReportSelectedPipes,
} from "./CompareSystemGraphs/CompareSystemGraphs"
import { Accordion } from "./Accordion/Accordion"
import { useFormData } from "../../FormDataContext"
import { FormattedMessage, useIntl } from "react-intl"
import { TooltipComponentOption } from "echarts/components"
import { TopLevelFormatterParams } from "echarts/types/dist/shared"
import { PopUp } from "../shared/PopUp/PopUp"
import { InfoIcon } from "@chakra-ui/icons"

export type Unified<T> = Exclude<T, T[]>
export const asTooltipFormat = (p: TopLevelFormatterParams) => {
  return p as Unified<
    Parameters<
      Exclude<NonNullable<TooltipComponentOption["formatter"]>, string>
    >[0]
  >
}

export const asMultipleTooltipFormat = (p: TopLevelFormatterParams) => {
  return p as Unified<
    Parameters<
      Exclude<NonNullable<TooltipComponentOption["formatter"]>, string>
    >[0]
  >[]
}

type Props = {
  children?: React.ReactNode
}

export const GraphHeading: React.FunctionComponent<Props> = ({ children }) => (
  <Heading
    alignSelf="start"
    textAlign="left"
    mt={12}
    mb={5}
    color="brand.grey"
    fontSize={20}
  >
    {children}
  </Heading>
)

type TcoResultProps = {
  printMode?: boolean
  selectedPipes?: ReportSelectedPipes
}

export const TcoResult: React.FC<TcoResultProps> = ({
  printMode = false,
  selectedPipes = null,
}) => {
  const intl = useIntl()
  const { formData } = useFormData()
  const { data, isLoading, error } = useCalculation(formData)
  useShowNetworkError(error)

  if (isLoading) {
    return (
      <ContentWidth>
        <Heading>
          <FormattedMessage
            id="result.calculating"
            defaultMessage="Calculating..."
            description="Calculation TCO result"
          />
        </Heading>
      </ContentWidth>
    )
  }

  if (!data || !formData) {
    return null
  }

  return (
    <ContentWidth>
      {data && (
        <>
          <HStack mb="4">
            <Heading size={"lg"} fontSize="24px">
              <FormattedMessage
                id="calculation.results"
                defaultMessage="Results of calculation"
              />
            </Heading>
            {!printMode && (
              <PopUp triggerElement={<InfoIcon />}>
                <FormattedMessage
                  id="calculation.results.info"
                  defaultMessage="Use the sliders above to customize cost distribution (e.g., 50% excavation, 30% pipe material, 20% installation) for your desired pie chart representation."
                />
              </PopUp>
            )}
          </HStack>
          <TcoGraphs tcoCalculationGroups={data.tcoCalculationGroups} />

          {printMode ? (
            <EmissionGraphs tcoCalculationGroups={data.tcoCalculationGroups} />
          ) : (
            <Accordion
              title={intl.formatMessage({
                id: "co2-emission",
                defaultMessage: "CO₂ emission",
              })}
              isExpandedInitially={true}
            >
              <EmissionGraphs
                tcoCalculationGroups={data.tcoCalculationGroups}
              />
            </Accordion>
          )}

          {printMode ? (
            <HeatLossGraphs tcoCalculationGroups={data.tcoCalculationGroups} />
          ) : (
            <Accordion
              title={intl.formatMessage({
                id: "energy-loss-first-year",
                defaultMessage: "Energy loss for first year",
              })}
              isExpandedInitially={false}
            >
              <HeatLossGraphs
                tcoCalculationGroups={data.tcoCalculationGroups}
              />
            </Accordion>
          )}

          {printMode ? (
            <CompareSystemGraphs
              printMode
              tcoCalculationGroups={data.tcoCalculationGroups}
              selectedPipes={selectedPipes}
            />
          ) : (
            <Accordion
              title={intl.formatMessage({
                id: "compare-systems",
                defaultMessage: "Compare 2 systems",
              })}
              isExpandedInitially={false}
            >
              <CompareSystemGraphs
                tcoCalculationGroups={data.tcoCalculationGroups}
              />
            </Accordion>
          )}
        </>
      )}
    </ContentWidth>
  )
}
