import { Box, Center, Divider, Grid, Text } from "@chakra-ui/react"
import { useIntl } from "react-intl"
import { useLocation } from "react-router-dom"
import { useAuth } from "../../../keycloak"
import { ReportPipesAndConfig } from "../ConfigureReport"

export const ReportHeader = () => {
  const intl = useIntl()
  const location = useLocation()
  const state = location.state as ReportPipesAndConfig | undefined
  const config = state?.config
  const { username } = useAuth()

  if (config == null) {
    return null
  }

  //   const { data: logoUrl } = useLogo()

  //   const company = data?.companyDTO
  //   const dates =
  //     data?.weldings?.flatMap((w) =>
  //       w.inputDateTime ? [w.inputDateTime] : []
  //     ) || []
  //   const startDate = dates.reduce((a, b) => {
  //     return a < b ? a : b
  //   })
  //   const endDate = dates.reduce((a, b) => {
  //     return a > b ? a : b
  //   })

  return (
    <Box
      w="100%"
      ps="2"
      pe="2"
      minH="92px"
      pt="2"
      color="brand.blue"
      fontSize="sm"
    >
      <Grid templateColumns="repeat(1, 1fr)" gap={6}>
        {/* <Box w="100%">
          {config.companyLogo && logoUrl && (
            <Img
              ms="2"
              w="200px"
              h="80px"
              objectFit="contain"
              src={logoUrl}
              alt="Logo"
            />
          )}
        </Box>
        <Box w="100%">
          <Text as="b">{company?.name}</Text>
          <Text>
            {intl.formatDate(startDate, { dateStyle: "long" })}
            {" - "}
            {intl.formatDate(endDate, { dateStyle: "long" })}
          </Text>
        </Box>
        <Box w="100%">
          {company?.address} <br />
          {company?.postalCode} {company?.city}
          <br />
          {company?.country}
        </Box>
        <Box w="100%">
          <Text>{company?.telephone}</Text>
          <Text>{company?.faxNumber}</Text>
          <Text>{company?.emailAdress}</Text>
        </Box> */}
        <Box w="100%" textAlign="end">
          <Text>{intl.formatDate(Date(), { dateStyle: "full" })}</Text>
          <Text>
            {username.charAt(0).toUpperCase() + username.substring(1)}
          </Text>
        </Box>
      </Grid>
      {config.headerSubtitle && (
        <Center mt="4">
          <Text fontSize="md">{config.subtitleValue}</Text>
        </Center>
      )}
      <Divider mt="8" />
    </Box>
  )
}
