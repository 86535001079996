import { TcoCalculationGroup } from "../../../models/calculation/tcoCalculationGroup"
import React from "react"
import { Flex } from "@chakra-ui/react"
import { TcoGraph } from "./TcoGraph"
import { useIntl } from "react-intl"
import { getGraphTitle } from "../utils/getGraphTitle"

export type TcoGraphsProps = {
  tcoCalculationGroups: TcoCalculationGroup[]
}
export const TcoGraphs: React.FC<TcoGraphsProps> = ({
  tcoCalculationGroups,
}) => {
  const intl = useIntl()

  return (
    <Flex direction={"column"}>
      {tcoCalculationGroups.map((group, index) => (
        <TcoGraph
          key={index}
          title={getGraphTitle(
            intl,
            group.nominalDiameters,
            group.pipeSystemKind
          )}
          tcoDataItems={group.systems}
        />
      ))}
    </Flex>
  )
}
