import {
  useToast,
  VStack,
  HStack,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Box,
  Textarea,
  Button,
  Text,
} from "@chakra-ui/react"
import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { useIntl } from "react-intl"
import { useFormData } from "../../../FormDataContext"
import { useSavedQuery } from "../../../hooks/useSavedQuery"
import { useAuth } from "../../../keycloak"
import { CreateLeadCommand } from "../../../rest/openapi"
import { mapToSavedQueryDto } from "../../../rest/useCalculation"
import { useContact } from "../../../rest/useContact"
import { LogstorLoadingOverlay } from "../KingspanLoading"

export type ContactFormProps = {
  onClose: () => void
}

export const ContactForm: React.FC<ContactFormProps> = ({ onClose }) => {
  const minimumLength = 2
  const minimumPhoneLength = 8
  const { token } = useAuth()
  const { mutate, isLoading } = useContact()
  const savedQuery = useSavedQuery()
  const toast = useToast()
  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<CreateLeadCommand>()
  const { formData } = useFormData()
  const intl = useIntl()

  useEffect(() => {
    if (savedQuery) {
      setValue("saveQueryName", savedQuery.name)
    }
  }, [savedQuery, setValue])

  return (
    <LogstorLoadingOverlay showOverlay={isLoading}>
      <form
        onSubmit={handleSubmit((data) => {
          let queryData
          // Same name as favorite, and user has clicked calculate
          if (data.saveQueryName === savedQuery?.name && formData) {
            queryData = mapToSavedQueryDto(formData) // overwrite with possibly new data

            // same name as favorite, but without having calculated
          } else if (data.saveQueryName === savedQuery?.name || !formData) {
            queryData = savedQuery?.queryData // overwrite with saved data

            // no saved favorite
          } else if (formData) {
            queryData = mapToSavedQueryDto(formData) // create new favorite
          }
          const dto = {
            ...data,
            calculationQuery: queryData,
          }
          mutate(
            {
              createLeadCommand: dto,
              token: token,
            },
            {
              onSuccess: () => {
                toast({
                  title: intl.formatMessage({
                    id: "toast.title.success",
                    defaultMessage: "Success",
                  }),
                  description: intl.formatMessage({
                    id: "toast.contactFormSentSuccess",
                    defaultMessage: "The contact form was successfully sent",
                  }),
                  status: "success",
                  duration: 6000,
                  isClosable: true,
                })
                onClose()
              },
              onError: () => {
                toast({
                  title: intl.formatMessage({
                    id: "toast.title.error",
                    defaultMessage: "Error",
                  }),
                  description: intl.formatMessage({
                    id: "toast.contactFormSentError",
                    defaultMessage:
                      "The contact form was not sent properly, please try again",
                  }),
                  status: "error",
                  duration: 6000,
                  isClosable: true,
                })
                onClose()
              },
            }
          )
        })}
      >
        <VStack w={"100%"}>
          <Text w={"100%"}>
            {!formData &&
              !savedQuery &&
              intl.formatMessage({
                id: "contactForm.explainerTextNoCalculation",
                defaultMessage:
                  "If you wish to attach calculation data to your contact request, please first make a calculation.",
              })}
            {formData &&
              !savedQuery &&
              intl.formatMessage({
                id: "contactForm.explainerTextWithCalculation",
                defaultMessage:
                  "Submitting the contact form will also save your calculation as a favorite.",
              })}
          </Text>
          <HStack spacing={"50px"} w="100%">
            <FormControl isInvalid={errors.name?.message !== undefined}>
              <FormLabel htmlFor="name">
                {intl.formatMessage({
                  id: "contactForm.name",
                  defaultMessage: "Name",
                })}
                {
                  <Text color={"red"} display={"inline"}>
                     *
                  </Text>
                }
              </FormLabel>
              <Input
                isDisabled={isLoading}
                id="name"
                placeholder={intl.formatMessage({
                  id: "contactForm.placeholder.name",
                  defaultMessage: "Your name",
                })}
                {...register("name", {
                  required: intl.formatMessage({
                    id: "contactform.required",
                    defaultMessage: "This is required",
                  }),
                  minLength: {
                    value: minimumLength,
                    message: intl.formatMessage(
                      {
                        id: "contactForm.minLength",
                        defaultMessage: "Minimum length is {value}",
                      },
                      { value: minimumLength }
                    ),
                  },
                })}
              />
              <FormErrorMessage>
                {errors.name && errors.name.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.email?.message !== undefined}>
              <FormLabel htmlFor="email">
                {intl.formatMessage({
                  id: "contactForm.email",
                  defaultMessage: "Email",
                })}
                {
                  <Text color={"red"} display={"inline"}>
                     *
                  </Text>
                }
              </FormLabel>
              <Input
                isDisabled={isLoading}
                id="email"
                placeholder={intl.formatMessage({
                  id: "contactForm.placeholder.email",
                  defaultMessage: "Email",
                })}
                {...register("email", {
                  required: intl.formatMessage({
                    id: "contactform.required",
                    defaultMessage: "This is required",
                  }),
                  minLength: {
                    value: minimumLength,
                    message: intl.formatMessage(
                      {
                        id: "contactForm.minLength",
                        defaultMessage: "Minimum length is {value}",
                      },
                      { value: minimumLength }
                    ),
                  },
                })}
              />
              <FormErrorMessage>
                {errors.email && errors.email.message}
              </FormErrorMessage>
            </FormControl>
          </HStack>

          <HStack spacing={"50px"} w="100%">
            <FormControl isInvalid={errors.business?.message !== undefined}>
              <FormLabel htmlFor="business">
                {intl.formatMessage({
                  id: "contactForm.business",
                  defaultMessage: "Business",
                })}
                {
                  <Text color={"red"} display={"inline"}>
                     *
                  </Text>
                }
              </FormLabel>
              <Input
                isDisabled={isLoading}
                id="business"
                placeholder={intl.formatMessage({
                  id: "contactForm.placeholder.business",
                  defaultMessage: "Business name",
                })}
                {...register("business", {
                  required: intl.formatMessage({
                    id: "contactform.required",
                    defaultMessage: "This is required",
                  }),
                  minLength: {
                    value: minimumLength,
                    message: intl.formatMessage(
                      {
                        id: "contactForm.minLength",
                        defaultMessage: "Minimum length is {value}",
                      },
                      { value: minimumLength }
                    ),
                  },
                })}
              />
              <FormErrorMessage>
                {errors.business && errors.business.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.name?.message !== undefined}>
              <FormLabel htmlFor="phone">
                {intl.formatMessage({
                  id: "contactForm.phone",
                  defaultMessage: "Phone number",
                })}
                {
                  <Text color={"red"} display={"inline"}>
                     *
                  </Text>
                }
              </FormLabel>
              <Input
                isDisabled={isLoading}
                id="phone"
                placeholder={intl.formatMessage({
                  id: "contactForm.placeholder.phone",
                  defaultMessage: "Phone number",
                })}
                {...register("phoneNumber", {
                  required: intl.formatMessage({
                    id: "contactform.required",
                    defaultMessage: "This is required",
                  }),
                  minLength: {
                    value: minimumLength,
                    message: intl.formatMessage(
                      {
                        id: "contactForm.minLength",
                        defaultMessage: "Minimum value is {value}",
                      },
                      { value: minimumPhoneLength }
                    ),
                  },
                })}
              />
              <FormErrorMessage>
                {errors.phoneNumber && errors.phoneNumber.message}
              </FormErrorMessage>
            </FormControl>
          </HStack>

          {savedQuery && (
            <Text pt={"20px"}>
              {intl.formatMessage(
                {
                  id: "contactForm.alreadySavedQueryAndWithCalculation",
                  defaultMessage: `You are about to overwrite existing favorite {savedQueryName}. If you prefer to save a new favorite, please provide a different name in the input field below.`,
                },
                { savedQueryName: savedQuery?.name }
              )}
            </Text>
          )}
          <VStack w="100%">
            {(formData || savedQuery) && (
              <FormControl
                isInvalid={errors.name?.message !== undefined}
                w={"50%"}
              >
                <FormLabel htmlFor="saveQueryName">
                  {intl.formatMessage({
                    id: "contactForm.saveQueryName",
                    defaultMessage: "Calculation/project name",
                  })}
                  {
                    <Text color={"red"} display={"inline"}>
                       *
                    </Text>
                  }
                </FormLabel>
                <Input
                  id="saveQueryName"
                  placeholder={savedQuery?.name}
                  {...register("saveQueryName", {
                    required: intl.formatMessage({
                      id: "contactform.required",
                      defaultMessage: "This is required",
                    }),
                    minLength: {
                      value: minimumLength,
                      message: intl.formatMessage(
                        {
                          id: "contactForm.minLength",
                          defaultMessage: "Minimum value is {value}",
                        },
                        { value: minimumLength }
                      ),
                    },
                  })}
                />
                <FormErrorMessage>
                  {errors.saveQueryName && errors.saveQueryName.message}
                </FormErrorMessage>
              </FormControl>
            )}

            <Box w={"100%"}>
              <FormLabel htmlFor="comment">
                {intl.formatMessage({
                  id: "contactForm.comment",
                  defaultMessage: "Comments",
                })}
              </FormLabel>
              <Textarea
                isDisabled={isLoading}
                {...register("comment")}
                h={"100px"}
                placeholder={intl.formatMessage({
                  id: "contactForm.placeholder.comment",
                  defaultMessage: "Anything you wish to add?",
                })}
                size={"lg"}
              />
            </Box>
          </VStack>

          <Button
            isDisabled={isLoading}
            mt={4}
            background={"brand.red"}
            color={"brand.white"}
            isLoading={isSubmitting}
            type="submit"
          >
            {intl.formatMessage({
              id: "contactForm.submit",
              defaultMessage: "Submit",
            })}
          </Button>
        </VStack>
      </form>
    </LogstorLoadingOverlay>
  )
}
