import { IntlShape } from "react-intl"
import {
  InsulationSeriesKind,
  PipeSystemPreInsulatedPipeKind,
  TcoCalculationItem,
} from "../../../models/calculation/tcoCalculationItem"
import { mapToSeriesAbbreviationName } from "../CompareSystemGraphs/shared/mapToSelectedSystem"
import { numberFormatter } from "../utils/decimalConverter"

export const mapToLocalisedPreInsulatedPipe = (
  key: PipeSystemPreInsulatedPipeKind,
  intl: IntlShape,
  lambda?: number,
  isSmallPipes?: boolean,
  seriesKind?: InsulationSeriesKind
): string => {
  const series = seriesKind ? mapToSeriesAbbreviationName(seriesKind) : ""
  const lambdaText = lambda
    ? `\nλ = ${numberFormatter.format(lambda)} W/mK`
    : ""

  switch (key) {
    case "AluFlextraPairOfPipe":
    case "CuFlexPairOfPipe":
    case "PexFlextraPairOfPipe":
    case "PertFlextraPairOfPipe":
      return intl.formatMessage(
        {
          id: "pipe_kinds.pair_of.pipes.with_diffussionbarrier",
          defaultMessage:
            "Pair of pipes, {series}\nwith diffussionbarrier{lambdaText}",
        },
        { series, lambdaText }
      )
    case "AluFlextraTwinPipe":
    case "CuFlexTwinPipe":
    case "PexFlextraTwinPipe":
    case "PertFlextraTwinPipe":
      return intl.formatMessage(
        {
          id: "pipe_kinds.twin_pipe",
          defaultMessage:
            "TwinPipe, {series}\nwith diffusionbarrier{lambdaText}",
        },
        { series, lambdaText }
      )
    case "TraditionalPairOfPipeWithoutDiffusionBarrier":
      return intl.formatMessage(
        {
          id: "pipe_kinds.pair_of_pipes_traditional",
          defaultMessage:
            "Pair of pipes\nTraditional, {series}\nwithout diffusionbarrier{lambdaText}",
        },
        { series, lambdaText }
      )
    case "ContiPairOfPipeWithDiffusionBarrier": {
      const suffix = isSmallPipes && lambda ? " *" : ""
      return intl.formatMessage(
        {
          id: "pipe_kinds.pair_of_pipes_conti_with",
          defaultMessage:
            "Pair of pipes\nConti, {series}\nwith diffussionbarrier{lambdaText}{suffix}",
        },
        { series, lambdaText, suffix }
      )
    }
    case "TraditionalTwinPipeWithoutDiffusionBarrier":
      return intl.formatMessage(
        {
          id: "pipe_kinds.twin_pipes_traditional",
          defaultMessage:
            "TwinPipe\nTraditional, {series}\nwithout diffusionbarrier{lambdaText}",
        },
        { series, lambdaText }
      )
    case "ContiTwinPipeWithDiffusionBarrier":
      const suffix = isSmallPipes && lambda ? " *" : ""
      return intl.formatMessage(
        {
          id: "pipe_kinds.twin_pipes_conti",
          defaultMessage:
            "TwinPipe\nConti, {series}\nwith diffusionbarrier{lambdaText}{suffix}",
        },
        { series, lambdaText, suffix }
      )
    case "ContiPairOfPipeWithAndWithoutDiffusionBarrier":
      return intl.formatMessage(
        {
          id: "pipe_kinds.pair_of_pipes_conti_without",
          defaultMessage:
            "Pair of pipes\nConti, {series}\nwithout diffusionbarrier{lambdaText}",
        },
        { series, lambdaText }
      )
  }
}

export const isSmallPipe = (data: TcoCalculationItem): boolean => {
  return (
    data.nominalDiameters[0] <= 200 && data.pipeSystemKind === "BondedSteel"
  )
}
export const isSmallPipes = (data: TcoCalculationItem[]): boolean => {
  return isSmallPipeMax100(data[0])
}

export const isSmallPipeMax100 = (data: TcoCalculationItem): boolean => {
  return (
    data.nominalDiameters[0] <= 100 && data.pipeSystemKind === "BondedSteel"
  )
}
export const isSmallPipesMax100 = (data: TcoCalculationItem[]): boolean => {
  return isSmallPipeMax100(data[0])
}

export const isSmallPipeOver100 = (data: TcoCalculationItem): boolean => {
  return (
    data.nominalDiameters[0] > 100 &&
    data.nominalDiameters[0] <= 200 &&
    data.pipeSystemKind === "BondedSteel"
  )
}
export const isSmallPipesOver100 = (data: TcoCalculationItem[]): boolean => {
  return isSmallPipeOver100(data[0])
}

export const isFlexPipe = (data: TcoCalculationItem): boolean => {
  return data.pipeSystemKind !== "BondedSteel"
}

export const isFlexPipes = (data: TcoCalculationItem[]): boolean => {
  return isFlexPipe(data[0])
}
