import { Box, Grid, GridItem, Heading, Select, Text } from "@chakra-ui/react"
import React, { useEffect } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { PopUp } from "../../../shared/PopUp/PopUp"
import { InfoIcon } from "@chakra-ui/icons"
import { NumberControl } from "../../controls/NumberControl"
import { getKeyToKeyMap } from "../../../../utils/getKeyToKeyMap"
import { useFormContext, useWatch } from "react-hook-form"
import { TcoFormData } from "../../TcoForm"
import { useCurrencyAndExchangeRate } from "../../../../rest/useCurrencyAndExchangeRate"
import { CurrencyCode } from "../../../../models/currency"

export type TCOCalculationControlsData = {
  currency: string
  exchangeRate: string
  interest: string
  periodTcoCalc: string
  energyUnit: string
  priceEnergy: string
  yearlyRiseInEnergyPrice: string
}

export const getDefaultTCOCalculationData = (): TCOCalculationControlsData => ({
  currency: "",
  exchangeRate: "1",
  interest: "0.0",
  periodTcoCalc: "30",
  energyUnit: "MWh",
  priceEnergy: "40.0",
  yearlyRiseInEnergyPrice: "0",
})

const tcoCalculationKeys = getKeyToKeyMap(getDefaultTCOCalculationData())

type TCOCalculationProps = {
  namePrefix: string
  printMode?: boolean
  savedQueryCurrency?: string
}

export const TCOCalculation: React.FC<TCOCalculationProps> = ({
  namePrefix,
  printMode = false,
  savedQueryCurrency,
}) => {
  const intl = useIntl()
  const { register, control, setValue } = useFormContext<TcoFormData>()
  const { data: currencies } = useCurrencyAndExchangeRate()
  const textColor = printMode ? "black" : "white"

  // When currencies is fetched, set default currency
  useEffect(() => {
    if (currencies && !printMode) {
      const defaultCurrency = (): string => {
        // TODO: check users locale and set default currency
        const lookupCurrencyCode = savedQueryCurrency ?? CurrencyCode.Eur
        const defaultCurrencyId = currencies?.find(
          (currency) => currency.code === lookupCurrencyCode
        )
        return defaultCurrencyId ? defaultCurrencyId.code : ""
      }

      setValue("tcoCalculation.currency", defaultCurrency(), {
        shouldValidate: true,
      })
    }
  }, [currencies, setValue, printMode, savedQueryCurrency])

  const currencyCode = useWatch({
    control: control,
    name: "tcoCalculation.currency",
  })

  // Set default exchangeRate when other currency is selected
  useEffect(() => {
    if (currencyCode) {
      const exchangeRate = currencies?.find(
        (currency) => currency.code === currencyCode
      )?.exchangeRate

      setValue("tcoCalculation.exchangeRate", exchangeRate || "", {
        shouldValidate: true,
      })
    }
  }, [currencyCode, setValue, currencies])

  return (
    <Box>
      <Heading color={textColor} as="h5" size="md">
        <FormattedMessage
          id="app.tcoCalculation.title"
          defaultMessage="TCO Calculation data"
          description="TCO Calculation data"
        />
      </Heading>

      <Grid
        marginTop={12}
        templateColumns="200px 120px 20px"
        templateRows="repeat(7, minmax(30px, auto))"
        gap={2}
      >
        {/* Currency */}
        <GridItem rowSpan={1} colSpan={1}>
          <Text color={textColor}>
            <FormattedMessage
              id="app.tcoCalculation.currency"
              defaultMessage="Currency"
              description="Currency for TCO calculation"
            />
          </Text>
        </GridItem>
        <GridItem rowSpan={1} colSpan={1}>
          <Select
            css={{ textAlignLast: "center", paddingLeft: "32px" }}
            {...register("tcoCalculation.currency")}
            bg="white"
            size="xs"
            isReadOnly={printMode}
            pointerEvents={printMode ? "none" : undefined}
          >
            {currencies &&
              currencies.map((currency) => {
                return (
                  <option key={currency.id} value={currency.code}>
                    {currency.code}
                  </option>
                )
              })}
          </Select>
        </GridItem>
        <GridItem rowSpan={1} colSpan={1} />

        {/* Exchange rate */}
        <GridItem rowSpan={1} colSpan={1}>
          <Text color={textColor}>
            <FormattedMessage
              id="app.tcoCalculation.exchangeRate"
              defaultMessage="Exchange rate"
              description="Exchange rate"
            />
          </Text>
        </GridItem>
        <GridItem rowSpan={1} colSpan={1}>
          <NumberControl
            control={control}
            name={namePrefix + tcoCalculationKeys.exchangeRate}
            rules={{
              required: {
                value: true,
                message: intl.formatMessage({
                  id: "calculation.required",
                  defaultMessage: "Required",
                }),
              },
              min: {
                value: 0,
                message: intl.formatMessage(
                  {
                    id: "calculation.min",
                    defaultMessage: "Min val is {value}",
                  },
                  { value: "0" }
                ),
              },
            }}
            isReadOnly={printMode}
            allowDecimals={true}
          />
        </GridItem>
        <GridItem rowSpan={1} colSpan={1}>
          <PopUp triggerElement={<InfoIcon color="white" />}>
            <FormattedMessage
              id="app.tcoCalculation.exchangeRateInfoIcon"
              defaultMessage="To update the proposed exchange rate, enter the new value here. Exchange rate is the value of 1 EUR in another currency"
              description="Exchange rate info icon description"
            />
          </PopUp>
        </GridItem>

        {/* Interest [%] */}
        <GridItem rowSpan={1} colSpan={1}>
          <Text color={textColor}>
            <FormattedMessage
              id="app.tcoCalculation.interest"
              defaultMessage="Interest [%]"
              description="Interest percentage"
            />
          </Text>
        </GridItem>
        <GridItem rowSpan={1} colSpan={1}>
          <NumberControl
            control={control}
            name={namePrefix + tcoCalculationKeys.interest}
            rules={{
              required: {
                value: true,
                message: intl.formatMessage({
                  id: "emission.required",
                  defaultMessage: "Required",
                }),
              },
              min: {
                value: 0,
                message: intl.formatMessage(
                  {
                    id: "calculation.min",
                    defaultMessage: "Min val is {value}",
                  },
                  { value: "0" }
                ),
              },
              max: {
                value: 10,
                message: intl.formatMessage(
                  {
                    id: "calculation.max",
                    defaultMessage: "Max val is {value}",
                  },
                  { value: "10" }
                ),
              },
            }}
            isReadOnly={printMode}
            allowDecimals={true}
          />
        </GridItem>
        <GridItem rowSpan={1} colSpan={1}>
          <PopUp triggerElement={<InfoIcon color="white" />}>
            <FormattedMessage
              id="app.tcoCalculation.interestInfoIcon"
              defaultMessage="The general inflation (0% to 10%) that affects the present value calculation of the heat loss cost."
              description="Interest info icon description"
            />
          </PopUp>
        </GridItem>

        {/* Period TCO calc [years] */}
        <GridItem rowSpan={1} colSpan={1}>
          <Text color={textColor}>
            <FormattedMessage
              id="app.tcoCalculation.periodTcoCalc"
              defaultMessage="Period TCO calc [years]"
              description="Period TCO calc in years"
            />
          </Text>
        </GridItem>
        <GridItem rowSpan={1} colSpan={1}>
          <NumberControl
            control={control}
            name={namePrefix + tcoCalculationKeys.periodTcoCalc}
            rules={{
              required: {
                value: true,
                message: intl.formatMessage({
                  id: "emission.required",
                  defaultMessage: "Required",
                }),
              },
              min: {
                value: 0,
                message: intl.formatMessage(
                  {
                    id: "calculation.min",
                    defaultMessage: "Min val is {value}",
                  },
                  { value: "0" }
                ),
              },
              max: {
                value: 50,
                message: intl.formatMessage(
                  {
                    id: "calculation.max",
                    defaultMessage: "Max val is {value}",
                  },
                  { value: "50" }
                ),
              },
            }}
            isReadOnly={printMode}
            allowDecimals={false}
          />
        </GridItem>
        <GridItem rowSpan={1} colSpan={1}>
          <PopUp triggerElement={<InfoIcon color="white" />}>
            <FormattedMessage
              id="app.tcoCalculation.periodTcoCalcInfoIcon"
              defaultMessage="Min 1 year - Max 50 years. It is the TCO period, used to calculate financial influence and energy  loss (the period of ownership)"
              description="Period TCO calculation info icon description"
            />
          </PopUp>
        </GridItem>

        {/* Energy unit */}
        <GridItem rowSpan={1} colSpan={1}>
          <Text color={textColor}>
            <FormattedMessage
              id="app.tcoCalculation.energyUnit"
              defaultMessage="Energy unit"
              description="Energy unit"
            />
          </Text>
        </GridItem>
        <GridItem rowSpan={1} colSpan={1}>
          <Select
            css={{ textAlignLast: "center", paddingLeft: "32px" }}
            {...register("tcoCalculation.energyUnit")}
            bg="white"
            size="xs"
            isReadOnly={printMode}
            pointerEvents={printMode ? "none" : undefined}
          >
            <option value="MWh">MWh</option>
            <option value="GJ">GJ</option>
          </Select>
        </GridItem>
        <GridItem rowSpan={1} colSpan={1}>
          <PopUp triggerElement={<InfoIcon color="white" />}>
            <FormattedMessage
              id="app.tcoCalculation.energyUnitInfoIcon"
              defaultMessage="Choose the actual energy unit used for the energy price below"
              description="Energy unit info icon description"
            />
          </PopUp>
        </GridItem>

        {/* Price Energy [price/unit] */}
        <GridItem rowSpan={1} colSpan={1}>
          <Text color={textColor}>
            <FormattedMessage
              id="app.tcoCalculation.energyPriceUnit"
              defaultMessage="Energy price [price/unit]"
              description="Energy price pr. unit"
            />
          </Text>
        </GridItem>
        <GridItem rowSpan={1} colSpan={1}>
          <NumberControl
            control={control}
            name={namePrefix + tcoCalculationKeys.priceEnergy}
            rules={{
              required: {
                value: true,
                message: intl.formatMessage({
                  id: "emission.required",
                  defaultMessage: "Required",
                }),
              },
            }}
            isReadOnly={printMode}
            allowDecimals={true}
          />
        </GridItem>
        <GridItem rowSpan={1} colSpan={1}>
          <PopUp triggerElement={<InfoIcon color="white" />}>
            <FormattedMessage
              id="app.tcoCalculation.priceEnergyUnitInfoIcon"
              defaultMessage="The price in the chosen currency for the energy unit"
              description="Price energy unit info icon description"
            />
          </PopUp>
        </GridItem>
        {/* Energy price rise */}
        <GridItem rowSpan={1} colSpan={1}>
          <Text color={textColor}>
            <FormattedMessage
              id="app.tcoCalculation.energyRise"
              defaultMessage="Rise in energy price [%]"
              description="Yearly rise in energy price as a percentage"
            />
          </Text>
        </GridItem>
        <GridItem rowSpan={1} colSpan={1}>
          <NumberControl
            control={control}
            name={namePrefix + tcoCalculationKeys.yearlyRiseInEnergyPrice}
            rules={{
              min: {
                value: 0,
                message: intl.formatMessage(
                  {
                    id: "calculation.min",
                    defaultMessage: "Min val is {value}",
                  },
                  { value: "0" }
                ),
              },
              max: {
                value: 10,
                message: intl.formatMessage(
                  {
                    id: "calculation.max",
                    defaultMessage: "Max val is {value}",
                  },
                  { value: "10" }
                ),
              },
              required: {
                value: true,
                message: intl.formatMessage({
                  id: "emission.required",
                  defaultMessage: "Required",
                }),
              },
            }}
            isReadOnly={printMode}
            allowDecimals={true}
          />
        </GridItem>
        <GridItem rowSpan={1} colSpan={1}>
          <PopUp triggerElement={<InfoIcon color="white" />}>
            <FormattedMessage
              id="app.tcoCalculation.energyRiseInfoIcon"
              defaultMessage="Set annual energy price increase (0% to 10%) aligning with the (inflationary) interest rate."
              description="Rise in energy price info icon description"
            />
          </PopUp>
        </GridItem>
      </Grid>
    </Box>
  )
}
