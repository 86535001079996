import {
  ArrowForwardIcon,
  CopyIcon,
  EmailIcon,
  HamburgerIcon,
  InfoOutlineIcon,
} from "@chakra-ui/icons"
import { memo } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useLocalStorage } from "../../../../hooks/useLocalStorage"
import { useAuth } from "../../../../keycloak"
import { useNavigate } from "react-router-dom"
import { getDefaultLanguage, reloadPage } from "../../../../utils/utilFuctions"
import { GetFullVersion } from "../../../../rest/useVersion"
import {
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Portal,
  useToast,
} from "@chakra-ui/react"

export const langauageKey = "language"
const supportedLanguages = [
  { key: "da", name: "Dansk" },
  { key: "de", name: "Deutsch" },
  { key: "en", name: "English" },
  { key: "fr", name: "Français" },
  { key: "it", name: "Italiano" },
  { key: "lt", name: "Lietuviškai" },
  { key: "nl", name: "Nederlands" },
  { key: "pl", name: "Polski" },
  { key: "se", name: "Svenska" },
]
const supportEmail = "logstor.digital@kingspan.com"

const HeaderMenu = () => {
  const intl = useIntl()
  const { logout, username } = useAuth()
  const [language, setLanguage] = useLocalStorage(
    langauageKey,
    getDefaultLanguage()
  )
  const navigate = useNavigate()
  const toast = useToast()
  const version = GetFullVersion()

  return (
    <Menu isLazy>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<HamburgerIcon />}
        color="white"
        backgroundColor="brand.blue"
        borderColor="white"
        borderRadius="lg"
        _hover={{ bg: "brand.red" }}
        _expanded={{ bg: "brand.red" }}
      />
      <Portal>
        <MenuList>
          <MenuOptionGroup
            defaultValue={language}
            type="radio"
            title={intl.formatMessage({
              id: "menu.group.language",
              defaultMessage: "Language",
            })}
          >
            {supportedLanguages.map((language) => (
              <MenuItemOption
                value={language.key}
                key={language.key}
                onClick={() => {
                  setLanguage(`${language.key}`)
                  reloadPage(navigate)
                }}
              >
                {language.name}
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
          <MenuDivider />
          <MenuGroup title={username}>
            <MenuItem icon={<ArrowForwardIcon />} onClick={logout}>
              <FormattedMessage id="menu.logout" defaultMessage="Log out" />
            </MenuItem>
          </MenuGroup>
          <MenuDivider />
          <MenuGroup
            title={intl.formatMessage({
              id: "menu.group.support",
              defaultMessage: "Support",
            })}
          >
            <MenuItem
              icon={<EmailIcon />}
              onClick={() => (window.location.href = `mailto:${supportEmail}`)}
            >
              <FormattedMessage
                id="menu.group.support.sendEmail"
                defaultMessage="Send email"
              />
            </MenuItem>
            <MenuItem
              icon={<CopyIcon />}
              onClick={() => {
                navigator.clipboard.writeText(supportEmail)
                toast({
                  title: `${intl.formatMessage({
                    defaultMessage: "Email copied to clipboard.",
                    id: "menu.group.support.clipboardToast.title",
                  })}`,
                  position: "top",
                })
              }}
            >
              <FormattedMessage
                id="menu.group.support.copyEmail"
                defaultMessage="Copy email to clipboard"
              />
            </MenuItem>
          </MenuGroup>
          <MenuDivider />
          <MenuGroup
            title={intl.formatMessage({
              id: "menu.group.version",
              defaultMessage: "Version",
            })}
          >
            <MenuItem icon={<InfoOutlineIcon />} fontSize="13">
              {version}
            </MenuItem>
          </MenuGroup>
        </MenuList>
      </Portal>
    </Menu>
  )
}

export const MemoizedHeaderMenu = memo(HeaderMenu)
