import { Configuration } from "./openapi"

export class BaseConfiguration extends Configuration {
  constructor(
    accessToken?:
      | string
      | Promise<string>
      | ((name?: string, scopes?: string[]) => string)
      | ((name?: string, scopes?: string[]) => Promise<string>)
  ) {
    super({
      accessToken: accessToken,
      basePath:
        process.env.REACT_APP_API_URL ||
        "https://ksdpnelogstortcoprd.azurefd.net",
    })
  }
}
