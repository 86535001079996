import { TcoFormData } from "../TcoForm/TcoForm"
import React from "react"
import { useFormContext } from "react-hook-form"
import { Box, Heading, HStack } from "@chakra-ui/react"
import { InvestmentSliders } from "./InvestmentSliders"
import { FormattedMessage } from "react-intl"
import { InfoIcon } from "@chakra-ui/icons"
import { PopUp } from "../shared/PopUp/PopUp"

type TcoSlidersProps = {
  onSubmit: (tcoFormData: TcoFormData) => void
  printMode?: boolean
}

export const TcoSliders: React.FC<TcoSlidersProps> = ({
  onSubmit,
  printMode = false,
}) => {
  const { handleSubmit } = useFormContext<TcoFormData>()
  return (
    <Box w="100%">
      <HStack mb="4">
        <Heading size="lg" fontSize="24px">
          <FormattedMessage
            id="app.sliders.header"
            defaultMessage="Adjust project calculation"
          />
        </Heading>
        <PopUp triggerElement={<InfoIcon />}>
          <FormattedMessage
            id="app.sliders.info"
            defaultMessage="With these sliders, you can adjust your project calculation. The default setting reflects an average project type. Slider adjustments apply universally to all pipe groups; only one set of sliders is available."
            values={{
              breakingLine: <br />,
            }}
          />
        </PopUp>
      </HStack>

      <InvestmentSliders
        onSliderChange={handleSubmit(onSubmit)}
        printMode={printMode}
      />
    </Box>
  )
}
