import { Input } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"

type AmountLocale = "da-DK" | "en-US"

interface AmountInputProps {
  locale: AmountLocale
  onChange: (value: number | null) => void
  onBlur: (value: number) => void
  value: number | null
  name: string
  allowDecimals: boolean
  isReadonly: boolean
}
export const AmountInput = React.forwardRef<any, AmountInputProps>(
  (
    { locale, onChange, onBlur, value, name, allowDecimals, isReadonly },
    ref
  ) => {
    const [isEditing, setIsEditing] = useState(false)
    const [inputValue, setInputValue] = useState("")

    // When value changes we format string according to locale before setting inputValue
    useEffect(() => {
      if (value != null) {
        if (!isEditing) {
          setInputValue(formatNumber(value, locale))
        }
      } else {
        setInputValue("")
      }
    }, [value, setInputValue, isEditing, locale])

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = event.target.value

      if (inputValue === "") {
        setInputValue("")
        onChange(null)
        return
      }

      // Only update input value if the format is okay
      const isMatching = isFormatOk(inputValue, locale, allowDecimals)
      if (isMatching) {
        setInputValue(inputValue)
        onChange(parseFormattedText(inputValue, locale))
      }
    }

    // On blur we add thousand separators
    const handleOnBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsEditing(false)
      const value = event.target.value

      // If empty string don't do anything
      if (!value) {
        return
      }
      setInputValue(formatNumber(parseFormattedText(value, locale), locale))
    }

    // On focus we remove thousand separator
    const handleOnFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsEditing(true)
      const value = event.target.value
      setInputValue(removeThousandSeparator(value, locale))
    }

    return (
      <Input
        type="text"
        value={inputValue}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        onFocus={handleOnFocus}
        name={name}
        ref={ref}
        size="xs"
        bg="white"
        borderRadius="4px"
        textAlign={"center"}
        isReadOnly={isReadonly}
      />
    )
  }
)

/**
 * Check if format of string is ok depending on the locale
 * @param value
 * @param locale
 */
const isFormatOk = (
  value: string,
  locale: AmountLocale,
  allowDecimals: boolean
): boolean => {
  if (locale === "da-DK") {
    // Allows digits, one comma and max two numbers after the comma
    if (allowDecimals) return /^[0-9]{1,10}([,][0-9]{0,2})?$/.test(value)
    return /^[0-9]{1,10}$/.test(value)
  } else {
    // Allows digits, one dot and max two numbers after the dot
    return /^[0-9]{1,10}([.][0-9]{0,2})?$/.test(value)
  }
}

const formatNumber = (value: number, locale: AmountLocale): string => {
  const formatter = new Intl.NumberFormat(locale)
  return formatter.format(value)
}

const removeThousandSeparator = (
  formattedText: string,
  locale: AmountLocale
): string => {
  if (locale === "da-DK") {
    return formattedText.replaceAll(".", "")
  } else {
    return formattedText.replaceAll(",", "")
  }
}

/**
 * Parse locale number (as string) and returns typescript number (123.23)
 * @param formattedText
 * @param locale
 */
const parseFormattedText = (
  formattedText: string,
  locale: AmountLocale
): number => {
  if (locale === "da-DK") {
    return Number(formattedText.replaceAll(".", "").replace(",", "."))
  } else {
    return Number(formattedText.replaceAll(",", ""))
  }
}
