import { InsulationSeriesKind } from "../../../../models/calculation/tcoCalculationItem"

export const mapToSeriesAbbreviationName = (
  insulationSeriesKind: InsulationSeriesKind
): string => {
  switch (insulationSeriesKind) {
    case "Series1":
      return "s.1"
    case "Series2":
      return "s.2"
    case "Series3":
      return "s.3"
    case "Series4":
      return "s.4"
  }
}
