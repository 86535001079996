import { useState } from "react"

interface StoredItem {
  value: any
  expiry?: number
}

export const useLocalStorage = (key: string, initialValue: any) => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const itemString = window.localStorage.getItem(key)

      // Parse stored json or if none return initialValue
      let parsedItem
      if (itemString) {
        parsedItem = JSON.parse(itemString)
      } else {
        return initialValue
      }

      const now = new Date()
      if (parsedItem.expiry && now.getTime() > parsedItem.expiry) {
        window.localStorage.removeItem(key)
        return initialValue
      } else {
        return parsedItem.value
      }
    } catch (error) {
      // If error also return initialValue
      console.log(error)
      return initialValue
    }
  })

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: any, expiry?: Date | null) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value

      // Save state
      const item: StoredItem = {
        value: valueToStore,
        expiry: expiry?.getTime(),
      }

      setStoredValue(item)
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(item))
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error)
    }
  }

  return [storedValue, setValue]
}
