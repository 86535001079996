import { EChartsOption } from "echarts"
import { useFindCheapestTco } from "./useFindCheapestTco"
import { TcoCalculationItem } from "../../../../../models/calculation/tcoCalculationItem"
import { useEffect, useState } from "react"
import { ChartColor } from "../../TcoBarChart/barChartColors"
import { toPercentage } from "../../../utils/toPercentage"
import {
  isSmallPipe,
  mapToLocalisedPreInsulatedPipe,
} from "../../../mappers/mapToLocalisedPreInsulatedPipeKind"
import { useIntl } from "react-intl"

export const useTcoPieChartOption = (
  tcoDataItems: TcoCalculationItem[]
): EChartsOption | null => {
  const intl = useIntl()
  const [option, setOption] = useState<EChartsOption | null>(null)
  const cheapestSolution = useFindCheapestTco(tcoDataItems)

  useEffect(() => {
    if (cheapestSolution) {
      let titleText = mapToLocalisedPreInsulatedPipe(
        cheapestSolution.pipeSystemPreInsulatedPipeKind,
        intl,
        cheapestSolution.lambda,
        isSmallPipe(cheapestSolution),
        cheapestSolution.insulationSeriesKind
      ) //E.g. TwinPipe, conti w/diff barrier

      const totalInvestmentCost =
        cheapestSolution.insulationSeriesCost.pipeMaterial +
        cheapestSolution.insulationSeriesCost.trenchExcavation +
        cheapestSolution.insulationSeriesCost.pipeInstallation

      const pipeMaterialPercentage = toPercentage(
        cheapestSolution.insulationSeriesCost.pipeMaterial,
        totalInvestmentCost
      )

      const trenchExcavationPercentage = toPercentage(
        cheapestSolution.insulationSeriesCost.trenchExcavation,
        totalInvestmentCost
      )
      const pipeInstallationPercentage = toPercentage(
        cheapestSolution.insulationSeriesCost.pipeInstallation,
        totalInvestmentCost
      )

      const option: EChartsOption = {
        title: {
          top: "bottom",
          text: titleText,
          right: "center",
          textStyle: {
            fontSize: 16,
          },
        },
        tooltip: {
          trigger: "item",
          formatter: `{b}: <b>{c}%</b>`,
        },
        textStyle: {
          fontFamily: "Lato",
        },
        series: [
          {
            type: "pie",
            label: {
              formatter: "{c}%",
              position: "inside",
            },
            radius: 60,
            data: [
              {
                value: pipeMaterialPercentage,
                name: intl.formatMessage({
                  id: "pipe-material",
                  defaultMessage: "Pipe material",
                }),
                itemStyle: {
                  color: ChartColor.Blue,
                },
                label: {
                  position: "inside",
                },
              },
              {
                value: trenchExcavationPercentage,
                name: intl.formatMessage({
                  id: "sliders.trench-excavation",
                  defaultMessage: "Trench excavation",
                }),
                itemStyle: {
                  color: ChartColor.DarkGrey,
                },
                label: {
                  position: "inside",
                },
              },
              {
                value: pipeInstallationPercentage,
                name: intl.formatMessage({
                  id: "pipe-installation",
                  defaultMessage: "Pipe installation",
                }),
                itemStyle: {
                  color: ChartColor.Yellow,
                },
                label: {
                  position: "inside",
                },
              },
            ],
          },
        ],
      }
      setOption(option)
    }
  }, [cheapestSolution, setOption, intl])

  return option
}
