import groupBy from "lodash/groupBy"
import forEach from "lodash/forEach"
import {
  PipeSystemPreInsulatedPipeKind,
  TcoCalculationItem,
} from "../../../../models/calculation/tcoCalculationItem"
import { ChartColor } from "./barChartColors"
import { EChartsOption } from "echarts"
import { OrdinalRawValue, TextCommonOption } from "echarts/types/src/util/types"
import {
  isSmallPipes,
  mapToLocalisedPreInsulatedPipe,
} from "../../mappers/mapToLocalisedPreInsulatedPipeKind"
import { mapToLocalisedInsulationSeries } from "../../mappers/mapToLocalisedInsulationSeries"
import { numberFormatter, toDecimals } from "../../utils/decimalConverter"
import { SeriesData, xAxisData } from "../../echart.models"
import { getLambda } from "../../utils/getLambda"
import { asTooltipFormat } from "../../TcoResult"
import { IntlShape } from "react-intl"

/**
 * This class assumes the data received from the backend has been grouped by PipeSystemKind beforehand
 */
export class TcoBarChartMapper {
  tcoDataItems: TcoCalculationItem[]
  currency: string
  period?: string
  intl: IntlShape

  cheapestTcoDataItem: TcoCalculationItem | null = null
  yAxisName: string = ""
  xAxisInsulationSeries: xAxisData[] = [] // s1, s2 etc
  xAxisPreInsulatedPipe: xAxisData[] = [] // pair of pipes, twin pipes etc
  graphSeries: Series[] = []
  totalSeries: (number | string)[] = []
  thousandPrefix: boolean = false

  constructor(
    intl: IntlShape,
    tcoDataItems: TcoCalculationItem[],
    currency: string,
    period?: string
  ) {
    this.intl = intl
    this.tcoDataItems = tcoDataItems
    this.findCheapestTcoDataItem()
    this.mapToGraphFormat()
    this.currency = currency
    this.period = period
  }

  private mapToGraphFormat(): void {
    const pipeMaterialSeries: Series = {
      name: this.intl.formatMessage({
        id: "tco.chart.pipe-material",
        defaultMessage: "Pipe material",
      }),
      color: ChartColor.Blue,
      stack: "1",
      barMaxWidth: 40,
      type: "bar",
      data: [],
    }

    const trenchExcavationSeries: Series = {
      name: this.intl.formatMessage({
        id: "tco.chart.trench-excavation",
        defaultMessage: "Trench excavation",
      }),
      color: ChartColor.DarkGrey,
      stack: "1",
      barMaxWidth: 40,
      type: "bar",
      data: [],
    }

    const pipeInstallationSeries: Series = {
      name: this.intl.formatMessage({
        id: "tco.chart.pipe-installation",
        defaultMessage: "Pipe installation",
      }),
      color: ChartColor.Yellow,
      stack: "1",
      barMaxWidth: 40,
      type: "bar",
      data: [],
    }

    const energySeries: Series = {
      name: this.intl.formatMessage({
        id: "tco.chart.energy-loss",
        defaultMessage: "Energy loss",
      }),
      color: ChartColor.Red,
      stack: "1",
      barMaxWidth: 40,
      type: "bar",
      data: [],
    }

    const emissionPresentSeries: Series = {
      name: this.intl.formatMessage({
        id: "tco.chart.co2-quote",
        defaultMessage: "CO₂ Quote fee",
      }),
      color: ChartColor.LightGrey,
      stack: "1",
      barMaxWidth: 40,
      type: "bar",
      data: [],
    }
    // group by PipeSystemPreInsulatedPipeKind
    const groupedByPreInsulatedPipeKind = groupBy(
      this.tcoDataItems,
      (data) => data.pipeSystemPreInsulatedPipeKind
    )

    // Iterate each group
    forEach(groupedByPreInsulatedPipeKind, (preInsulatedPipeGroupList, key) => {
      const pipeSystemPreInsulatedPipe = key as PipeSystemPreInsulatedPipeKind
      this.xAxisPreInsulatedPipe.push(
        this.markAsCheapest(
          mapToLocalisedPreInsulatedPipe(
            pipeSystemPreInsulatedPipe,
            this.intl,
            getLambda(preInsulatedPipeGroupList),
            isSmallPipes(preInsulatedPipeGroupList)
          ),
          pipeSystemPreInsulatedPipe
        )
      )

      forEach(preInsulatedPipeGroupList, (series) => {
        this.xAxisPreInsulatedPipe.push("")
        this.xAxisInsulationSeries.push(
          mapToLocalisedInsulationSeries(series.insulationSeriesKind)
        )

        const opacity = this.opacity(series)

        const createSeriesData = (cost: number): SeriesData => ({
          value: cost,
          itemStyle: {
            opacity: opacity,
          },
        })

        const pipeMaterialData = createSeriesData(
          toDecimals(series.insulationSeriesCost.pipeMaterial, 2)
        )
        const trenchExcavationData = createSeriesData(
          toDecimals(series.insulationSeriesCost.trenchExcavation, 2)
        )
        const pipeInstallationData = createSeriesData(
          toDecimals(series.insulationSeriesCost.pipeInstallation, 2)
        )
        const energyData = createSeriesData(
          toDecimals(series.insulationSeriesCost.energyPresentValue, 2)
        )

        const totalSeriesData =
          toDecimals(series.insulationSeriesCost.pipeMaterial, 2) +
          toDecimals(series.insulationSeriesCost.trenchExcavation, 2) +
          toDecimals(series.insulationSeriesCost.pipeInstallation, 2) +
          toDecimals(series.insulationSeriesCost.energyPresentValue, 2)
        pipeMaterialSeries.data.push(pipeMaterialData)
        trenchExcavationSeries.data.push(trenchExcavationData)
        pipeInstallationSeries.data.push(pipeInstallationData)
        energySeries.data.push(energyData)
        this.totalSeries.push(totalSeriesData)

        // Emission is not always included
        if (series.insulationSeriesCost.emissionPresentValue) {
          const emissionData = createSeriesData(
            toDecimals(series.insulationSeriesCost.emissionPresentValue, 2)
          )
          emissionPresentSeries.data.push(emissionData)
        }
      })

      const invisibleStack = {
        value: 0,
        itemStyle: {
          opacity: 0,
        },
      }

      // Inserting empty/invisible values to mimic grouping which library doesn't support
      pipeMaterialSeries.data.push(invisibleStack)
      trenchExcavationSeries.data.push(invisibleStack)
      pipeInstallationSeries.data.push(invisibleStack)
      energySeries.data.push(invisibleStack)
      this.totalSeries.push("")
      emissionPresentSeries.data.push(invisibleStack)
      this.xAxisInsulationSeries.push("")
    })

    // insert into graphData
    this.graphSeries = [
      pipeMaterialSeries,
      trenchExcavationSeries,
      pipeInstallationSeries,
      energySeries,
      emissionPresentSeries,
    ]

    this.formatTotalSeriesIfValuesAreTooLarge(999999)
  }

  private formatTotalSeriesIfValuesAreTooLarge(valueLimit: number) {
    const valueHigherThanLimit = this.totalSeries.find(
      (value) => value > valueLimit
    )
    if (valueHigherThanLimit) {
      this.thousandPrefix = true
      this.totalSeries = this.totalSeries.map((value) => {
        if (value !== "") {
          return Math.round((value as number) / 1000)
        } else return value
      })
    }
  }

  /**
   * If tcoDataItem is the overall cheapest set opacity to 1 else 0.5
   * @param tcoDataItem
   * @private
   */
  private opacity(tcoDataItem: TcoCalculationItem): number {
    if (this.cheapestTcoDataItem === tcoDataItem) {
      return 1
    }
    return 0.25
  }

  private findCheapestTcoDataItem(): void {
    let cheapestCost: number = 0
    this.tcoDataItems.forEach((data, index) => {
      const cost = Object.values(data.insulationSeriesCost).reduce(
        (accumulator, currentValue) => {
          return accumulator! + currentValue!
        }
      ) as number

      // First iteration set cheapest cost
      if (index === 0) {
        cheapestCost = cost
        this.cheapestTcoDataItem = data
      } else {
        if (cost < cheapestCost) {
          cheapestCost = cost
          this.cheapestTcoDataItem = data
        }
      }
    })
  }

  // Text should be black to indicate cheapest solution
  private markAsCheapest = (
    value: string,
    pipeSystemPreInsulatedPipeKind: PipeSystemPreInsulatedPipeKind
  ):
    | string
    | {
        value: OrdinalRawValue
        textStyle?: TextCommonOption
      } => {
    if (
      this.cheapestTcoDataItem &&
      this.cheapestTcoDataItem?.pipeSystemPreInsulatedPipeKind ===
        pipeSystemPreInsulatedPipeKind
    ) {
      return {
        value: value,
        textStyle: {
          color: "black",
          align: "left",
        },
      }
    } else {
      return value
    }
  }

  getOptions(): EChartsOption {
    const legend: string[] = [
      this.intl.formatMessage({
        id: "tco.chart.pipe-material",
        defaultMessage: "Pipe material",
      }),
      this.intl.formatMessage({
        id: "tco.chart.trench-excavation",
        defaultMessage: "Trench excavation",
      }),
      this.intl.formatMessage({
        id: "tco.chart.pipe-installation",
        defaultMessage: "Pipe installation",
      }),
      this.intl.formatMessage({
        id: "tco.chart.energy-loss",
        defaultMessage: "Energy loss",
      }),
      this.intl.formatMessage({
        id: "tco.chart.co2-quote",
        defaultMessage: "CO₂ Quote fee",
      }),
    ]

    return {
      graphic: {
        type: "text",
        left: "center",
        top: 0,
        z: 100,
        style: {
          fill: "gray",
          text: this.period
            ? this.intl.formatMessage(
                {
                  id: "tco.bar.year-period",
                  defaultMessage: "{period} year period",
                },
                { period: this.period }
              )
            : undefined,
          font: "normal 16px Lato",
        },
        cursor: "auto",
      },
      tooltip: {
        formatter: (p) =>
          asTooltipFormat(p).seriesName +
          ": <b>" +
          numberFormatter.format(asTooltipFormat(p).value as number) +
          ` ${this.currency}</b>`,
      },
      legend: {
        top: "bottom",
        textStyle: {
          fontSize: 14,
        },
        data: legend,
      },
      grid: {
        containLabel: true,
      },
      textStyle: {
        fontFamily: "Lato",
      },
      xAxis: [
        {
          type: "category",
          data: this.xAxisInsulationSeries,
        },
        {
          type: "category",
          name: this.intl.formatMessage(
            {
              id: "TcoBarChartMapper.total.name",
              defaultMessage: "Total ({prefix} {currency})",
            },
            {
              currency: this.currency,
              prefix: this.thousandPrefix ? "1.000" : "",
            }
          ),
          axisLabel: {
            formatter: (value: number | string, index: number) => {
              if (value !== "") {
                return `${numberFormatter.format(value as number)}`
              } else {
                return ""
              }
            },
            interval: 0,
            align: "center",
          },
          nameTextStyle: {
            color: "black",
            verticalAlign: "bottom",
            align: "center",
            padding: [0, 0, 0, -50],
          },
          nameLocation: "middle",
          nameGap: 40,
          offset: 0,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          data: this.totalSeries,
        },
        {
          position: "bottom",
          data: this.xAxisPreInsulatedPipe,
          axisLine: {
            show: false,
          },
          axisLabel: {
            margin: 50,
            align: "left",
            interval: 0,
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          name: this.yAxisName,
          nameLocation: "end",
          nameGap: 20,
          axisLabel: {
            formatter: (value: number) =>
              numberFormatter.format(value as number),
          },
        },
      ],
      series: this.graphSeries,
    }
  }
}

type Series = {
  name: string // legend name
  type: "bar"
  stack: "1"
  barMaxWidth: number
  color: string
  data: SeriesData[]
}
