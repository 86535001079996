import { FavoriteTable } from "../../components/Favorites/FavoriteTable"
import { FooterBox } from "../../components/Footer"
import { LogoHeader } from "../../components/shared/Header/LogoHeader"

export const FavoritesPage = () => {
  return (
    <>
      <LogoHeader />
      <FavoriteTable />
      <FooterBox />
    </>
  )
}
