import { VStack } from "@chakra-ui/react"
import React from "react"
import { useIntl } from "react-intl"
import { TcoCalculationGroup } from "../../../../models/calculation/tcoCalculationGroup"
import { getGraphTitle } from "../../utils/getGraphTitle"
import { CompareItem } from "../CompareSystemGraphs"
import { ManualPricesSystems } from "../SelectSystem/hooks/useManualPrices"
import { CapexOpexGraph } from "./CapexOpexGraph/CapexOpexGraph"

type CapexOpexGraphsProps = {
  tcoCalculationGroups: TcoCalculationGroup[]
  pipes: Array<CompareItem | null>
  manualPrices: ManualPricesSystems[]
}

export const CapexOpexGraphs: React.FC<CapexOpexGraphsProps> = ({
  tcoCalculationGroups,
  pipes,
  manualPrices,
}) => {
  const intl = useIntl()

  return (
    <VStack flexDirection={"column"} spacing={4} pb="10">
      {pipes.map(
        (pipeSystem, index) =>
          pipeSystem && (
            <React.Fragment key={index}>
              <CapexOpexGraph
                title={getGraphTitle(
                  intl,
                  tcoCalculationGroups[index].nominalDiameters,
                  tcoCalculationGroups[index].pipeSystemKind
                )}
                chosenSystemOne={pipeSystem.systemOne}
                chosenSystemTwo={pipeSystem.systemTwo}
                manualPrices={manualPrices[index]}
              />
              <br />
            </React.Fragment>
          )
      )}
    </VStack>
  )
}
