import { PipeSystemKindDto } from "../openapi"
import { PipeSystemKind } from "../../models/pipeSystemKind"

export const mapToPipeSystemKind = (dto: PipeSystemKindDto): PipeSystemKind => {
  switch (dto) {
    case PipeSystemKindDto.AluFlextra:
      return "AluFlextra"
    case PipeSystemKindDto.CuFlex:
      return "CuFlex"
    case PipeSystemKindDto.PexFlextra:
      return "PexFlextra"
    case PipeSystemKindDto.PertFlextra:
      return "PertFlextra"
    case PipeSystemKindDto.BondedSteel:
      return "BondedSteel"
    default:
      throw Error(
        "useAvailablePipes.ts - Received None as value for PipeSystemKindDto"
      )
  }
}
