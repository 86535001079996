import React, { useMemo } from "react"
import ReactECharts from "echarts-for-react"
import { EChartsOption } from "echarts"
import { TcoCalculationItem } from "../../../../models/calculation/tcoCalculationItem"
import { TcoBarChartMapper } from "./TcoBarChartMapper"
import { useFormData } from "../../../../FormDataContext"
import { CurrencyCode } from "../../../../models/currency"
import { useIntl } from "react-intl"

type TcoBarChartProps = {
  tcoDataItems: TcoCalculationItem[]
}
export const TcoBarChart: React.FC<TcoBarChartProps> = ({ tcoDataItems }) => {
  // TODO: make hook for this
  const intl = useIntl()
  const { formData } = useFormData()
  const currency = formData?.tcoCalculation?.currency || CurrencyCode.Eur
  const period = formData?.tcoCalculation.periodTcoCalc
  const echartOption: EChartsOption = useMemo(() => {
    const mapper = new TcoBarChartMapper(intl, tcoDataItems, currency, period)
    return mapper.getOptions()
  }, [tcoDataItems, currency, period, intl])

  return (
    <ReactECharts
      option={echartOption}
      style={{ height: "100%", width: "100%" }}
      opts={{ renderer: "svg" }}
    />
  )
}
