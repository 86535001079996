import {
  Box,
  Flex,
  HStack,
  Slider as ChakraSlider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
} from "@chakra-ui/react"
import { ChartColor } from "../TcoResult/TcoGraphs/TcoBarChart/barChartColors"
import React, { useState } from "react"
import { PopUp } from "../shared/PopUp/PopUp"
import { InfoIcon } from "@chakra-ui/icons"

type SliderProps = {
  defaultValue: number
  min: number
  max: number
  step: number
  onChangeEnd: (value: number) => void
  title: string
  minTitle: string
  maxTitle: string
  valueTitle?: string
  isReadOnly?: boolean
  infoPopupContent?: string | React.ReactNode
}

export const Slider: React.FC<SliderProps> = ({
  defaultValue,
  min,
  max,
  step,
  onChangeEnd,
  title,
  minTitle,
  maxTitle,
  valueTitle,
  infoPopupContent,
  isReadOnly = false,
}) => {
  const [sliderValue, setSliderValue] = useState<number>(defaultValue)

  const handleOnChangeEnd = (val: number): void => {
    onChangeEnd(val)
  }

  return (
    <Flex
      borderWidth="thin"
      borderRadius="md"
      p="4"
      ps="5"
      ms="4"
      flexBasis="100%"
      flexDirection={"column"}
    >
      <Flex flexDirection={"row"} justifyContent={"space-between"}>
        <HStack>
          <Text fontWeight={"bold"} color={"brand.grey"}>
            {title}
          </Text>
          {infoPopupContent && (
            <PopUp triggerElement={<InfoIcon />}>{infoPopupContent}</PopUp>
          )}
        </HStack>

        {valueTitle && (
          <Text fontWeight={"bold"} color={"brand.red"}>
            {valueTitle}
          </Text>
        )}
      </Flex>

      <ChakraSlider
        min={min}
        max={max}
        step={step}
        onChangeEnd={handleOnChangeEnd}
        onChange={(d) => setSliderValue(d)}
        value={sliderValue}
        focusThumbOnChange={false}
        isReadOnly={isReadOnly}
      >
        <SliderTrack bg={ChartColor.LightGrey}>
          <Box position="relative" right={10} />
          <SliderFilledTrack bg={ChartColor.DarkGrey} />
        </SliderTrack>
        <SliderThumb boxSize={6} bg={ChartColor.Red} color={"white"}>
          <Text fontSize={12}>{sliderValue}</Text>
        </SliderThumb>
      </ChakraSlider>
      <Flex flexDirection={"row"} justifyContent={"space-between"}>
        <Text fontSize={14}>
          {minTitle}({min})
        </Text>
        <Text fontSize={14}>
          {maxTitle}({max})
        </Text>
      </Flex>
    </Flex>
  )
}
