import { HStack, Link, SpaceProps, Text } from "@chakra-ui/react"
import { FaArrowLeft } from "react-icons/fa"
import { FormattedMessage } from "react-intl"
import { Link as RouterLink } from "react-router-dom"
import { useFormData } from "../../FormDataContext"

export const BackLink = (props: SpaceProps) => {
  const { setFormData } = useFormData()

  return (
    <Link
      className="print-hidden"
      as={RouterLink}
      to="/"
      onClick={() => setFormData(null)}
      textColor="#838A95"
      fontSize="14px"
      display="inline-block"
      {...props}
    >
      <HStack>
        <FaArrowLeft />
        <Text ms="2">
          <FormattedMessage
            id="favorites.back-link"
            defaultMessage="Back to calculations"
          />
        </Text>
      </HStack>
    </Link>
  )
}
