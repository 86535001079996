import { TcoCalculationItem } from "../../../../../models/calculation/tcoCalculationItem"
import { Input, VStack, Grid, GridItem, Heading } from "@chakra-ui/react"
import React from "react"
import { numberFormatter } from "../../../utils/decimalConverter"
import { ChartColor } from "../../../TcoGraphs/TcoBarChart/barChartColors"
import { PipeSystemTitle } from "../../shared/PipeSystemTitle"
import { useFormData } from "../../../../../FormDataContext"
import { CurrencyCode } from "../../../../../models/currency"
import { useCompareSystemsCalculation } from "../../hooks/useCompareSystemsCalculation"
import { ManualPricesSystems } from "../../SelectSystem/hooks/useManualPrices"
import { FormattedMessage, useIntl } from "react-intl"

type UnitPricesMeterTrenchGraphProps = {
  tcoCalculationItem: TcoCalculationItem
  calculationItems: [TcoCalculationItem, TcoCalculationItem]
  system: "First" | "Second"
  manualPrices?: ManualPricesSystems
}

export const UnitPricesMeterTrenchGraph: React.FC<
  UnitPricesMeterTrenchGraphProps
> = ({ tcoCalculationItem, calculationItems, system, manualPrices = null }) => {
  const { formData } = useFormData()
  const data = useCompareSystemsCalculation({
    calculationItems: calculationItems,
    manualPrices,
  }).data?.data
  const intl = useIntl()
  const currency = formData?.tcoCalculation?.currency || CurrencyCode.Eur
  const systemData =
    system === "First" ? data?.firstSystemData : data?.secondSystemData

  if (systemData == null) {
    return null
  }

  return (
    <VStack flexDirection="column" spacing={6} alignItems="flex-center">
      <PipeSystemTitle tcoCalculationItem={tcoCalculationItem} />
      <Heading fontSize={16} color={"brand.grey"}>
        <FormattedMessage
          id="unit_prices.currency"
          defaultMessage="Unit prices in {currency} pr. m. trench"
          values={{ currency }}
        />
      </Heading>
      <Grid templateRows="repeat(4, 1fr)" gap={4}>
        <GridItem rowSpan={1} colSpan={1} alignContent="center">
          <Input
            width={"100%"}
            isReadOnly
            borderRadius={6}
            size="sm"
            bg={ChartColor.Yellow}
            color="white"
            textAlign="center"
            value={
              systemData.unitPricePerMeterTrench?.pipeInstallation == null
                ? ""
                : numberFormatter.format(
                    systemData.unitPricePerMeterTrench?.pipeInstallation || 0
                  )
            }
          />
        </GridItem>
        <GridItem rowSpan={1} colSpan={1}>
          <Input
            width={"100%"}
            isReadOnly
            borderRadius={6}
            size="sm"
            bg={ChartColor.DarkGrey}
            color="white"
            textAlign="center"
            value={
              systemData.unitPricePerMeterTrench?.trenchExcavation == null
                ? ""
                : numberFormatter.format(
                    systemData.unitPricePerMeterTrench?.trenchExcavation || 0
                  )
            }
          />
        </GridItem>
        <GridItem rowSpan={1} colSpan={1}>
          <Input
            width={"100%"}
            isReadOnly
            borderRadius={6}
            size="sm"
            bg={ChartColor.Blue}
            color="white"
            textAlign="center"
            value={
              systemData.unitPricePerMeterTrench?.pipeMaterial == null
                ? ""
                : numberFormatter.format(
                    systemData.unitPricePerMeterTrench?.pipeMaterial || 0
                  )
            }
          />
        </GridItem>
        <GridItem rowSpan={1} colSpan={1}>
          <Input
            width={"100%"}
            isReadOnly
            borderRadius={6}
            textAlign="center"
            value={
              systemData.unitPricePerMeterTrench?.total == null
                ? ""
                : intl.formatMessage({
                    id: "unit-prices-meter-trench.total",
                    defaultMessage: "Total: ",
                  }) +
                  numberFormatter.format(
                    systemData.unitPricePerMeterTrench?.total || 0
                  )
            }
          />
        </GridItem>
      </Grid>
    </VStack>
  )
}
