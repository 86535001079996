import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Select,
  Switch,
  Text,
} from "@chakra-ui/react"
import { FormattedMessage, useIntl } from "react-intl"
import React, { useEffect, useState } from "react"
import { InfoIcon } from "@chakra-ui/icons"
import { PopUp } from "../../../shared/PopUp/PopUp"
import { NumberControl } from "../../controls/NumberControl"
import { Controller, useFormContext, useWatch } from "react-hook-form"
import { TcoFormData } from "../../TcoForm"
import { getKeyToKeyMap } from "../../../../utils/getKeyToKeyMap"
import { useEmissionSources } from "../../../../rest/useEmissionSources"
import {
  EmissionKind,
  emissionKindMessages,
} from "../../../../models/emissionSource"

export type Co2EmissionControlsData = {
  fuelType: string
  tonCo2: string
  includeCo2: boolean
  co2QuotePrice?: string
  numberOfYearsTCO?: string
}

export const getDefaultCo2EmissionData = (): Co2EmissionControlsData => ({
  fuelType: "",
  tonCo2: "0",
  includeCo2: false,
  co2QuotePrice: "35.0",
  numberOfYearsTCO: "30",
})

const co2EmissionKeys = getKeyToKeyMap(getDefaultCo2EmissionData())

type Co2EmissionProps = { namePrefix: string; printMode?: boolean }

export const Co2Emission: React.FC<Co2EmissionProps> = ({
  namePrefix,
  printMode = false,
}) => {
  const { register, unregister, control, setValue, getValues } =
    useFormContext<TcoFormData>()
  const intl = useIntl()
  const includeCO2 = useWatch({ control, name: "co2Emission.includeCo2" })
  const [tonCO2Disabled, setTonCO2Disabled] = useState(false)
  const emissionKind = useWatch({ control, name: "co2Emission.fuelType" })
  const { data: emissionSources } = useEmissionSources()
  const textColor = printMode ? "black" : "white"

  // Set default fuel type
  useEffect(() => {
    if (emissionSources) {
      let emissionSource = emissionSources.find(
        (emissionSource) =>
          emissionSource.emissionKind === EmissionKind.NaturalGas
      )

      if (emissionSource && !emissionKind) {
        setValue("co2Emission.fuelType", emissionSource.emissionKind)
      }
    }
  }, [setValue, emissionSources, emissionKind])

  // Set Ton CO2 from picked Fuel Type
  useEffect(() => {
    if (emissionSources) {
      const emissionSource = emissionSources.find(
        (emissionSource) => emissionSource.emissionKind === emissionKind
      )

      if (emissionSource) {
        setValue("co2Emission.tonCo2", emissionSource.value, {
          shouldValidate: true,
        })
        setTonCO2Disabled(emissionSource.emissionKind !== EmissionKind.Custom)
      }
    }
  }, [emissionKind, emissionSources, setValue])

  // "Remove values CO2 qoute price" and "Numbers of years" when
  // includeCO2 is removed, otherwise the form still contains the values
  useEffect(() => {
    if (includeCO2) {
      const co2Price = getValues("co2Emission.co2QuotePrice")
      const numberOfYears = getValues("co2Emission.numberOfYearsTCO")

      if (co2Price == null && numberOfYears == null) {
        setValue("co2Emission.co2QuotePrice", "35.0")
        setValue("co2Emission.numberOfYearsTCO", "30")
      }
    } else {
      setValue("co2Emission.co2QuotePrice", undefined)
      setValue("co2Emission.numberOfYearsTCO", undefined)
    }
  }, [includeCO2, unregister, setValue, getValues])

  return (
    <Box>
      <Heading color={textColor} as="h5" size="md">
        <FormattedMessage
          id="app.co2Emission.title"
          defaultMessage="CO{lowerTwo} Emission data"
          description="CO₂ emission title"
          values={{
            lowerTwo: "\u2082",
          }}
        />
      </Heading>

      <Grid
        marginTop={12}
        templateColumns="200px 120px 20px"
        templateRows="repeat(6, minmax(30px, auto))"
        gap={2}
      >
        {/* Fuel type */}
        <GridItem rowSpan={1} colSpan={1}>
          <Text color={textColor}>
            <FormattedMessage
              id="app.co2Emission.fuelType"
              defaultMessage="Fuel type"
              description="Fuel type"
            />
          </Text>
        </GridItem>
        <GridItem rowSpan={1} colSpan={1}>
          <Select
            css={{ textAlignLast: "center" }}
            {...register("co2Emission.fuelType", { required: true })}
            bg="white"
            size="xs"
            isReadOnly={printMode}
            pointerEvents={printMode ? "none" : undefined}
          >
            {emissionSources &&
              emissionSources.map((emissionSource) => (
                <option
                  key={emissionSource.id}
                  value={emissionSource.emissionKind}
                >
                  {intl.formatMessage(
                    emissionKindMessages[emissionSource.emissionKind]
                  )}
                </option>
              ))}
          </Select>
        </GridItem>
        <GridItem rowSpan={1} colSpan={1}>
          <PopUp triggerElement={<InfoIcon color="white" />}>
            <FormattedMessage
              id="app.co2Emission.fuelTypeInfoIcon"
              defaultMessage="Choose the type of fuel used to calculate the CO₂ emission. If the dropdown menu does not contain the fuel used, choose type 'Custom' and enter the actual value in the field below"
              description="Fuel type info icon description"
            />
          </PopUp>
        </GridItem>

        {/* Ton CO2/TJ */}
        <GridItem rowSpan={1} colSpan={1}>
          <Text color={textColor}>
            <FormattedMessage
              id="app.co2Emission.tonCo2Tj"
              defaultMessage="Ton CO{lowerTwo}/TJ"
              description="Ton CO₂ pr. Tera Joule"
              values={{
                lowerTwo: "\u2082",
              }}
            />
          </Text>
        </GridItem>
        <GridItem rowSpan={1} colSpan={1}>
          <NumberControl
            control={control}
            name={namePrefix + co2EmissionKeys.tonCo2}
            isReadOnly={tonCO2Disabled || printMode}
            allowDecimals
            rules={{
              required: {
                value: true,
                message: intl.formatMessage({
                  id: "emission.required",
                  defaultMessage: "Required",
                }),
              },
            }}
          />
        </GridItem>
        <GridItem rowSpan={1} colSpan={1}>
          <PopUp triggerElement={<InfoIcon color="white" />}>
            <FormattedMessage
              id="app.co2Emission.tonCo2TjInfoIcon"
              defaultMessage="To use another value than the proposed, choose 'Custom' and enter the actual value in this field"
              description="Ton CO₂ TJ info icon description"
            />
          </PopUp>
        </GridItem>

        {/*Include CO2*/}
        <GridItem rowSpan={1} colSpan={2}>
          <FormControl display="flex">
            <FormLabel color={textColor} htmlFor="include-co2">
              <FormattedMessage
                id="app.co2Emission.includeCo2"
                defaultMessage="Include CO{lowerTwo} in TCO"
                description="Include CO₂ in TCO"
                values={{
                  lowerTwo: "\u2082",
                }}
              />
            </FormLabel>
            <Controller
              name={namePrefix + co2EmissionKeys.includeCo2}
              render={({ field }) => (
                <Switch
                  isChecked={field.value as boolean}
                  onChange={(e) => field.onChange(e.target.checked)}
                  id="include-co2"
                  colorScheme="teal"
                  isReadOnly={printMode}
                />
              )}
            ></Controller>
          </FormControl>
        </GridItem>
        <GridItem rowSpan={1} colSpan={1}>
          <PopUp triggerElement={<InfoIcon color="white" />}>
            <FormattedMessage
              id="app.co2Emission.includeCo2InfoIcon"
              defaultMessage="Check this field to include the fee for CO{lowerTwo} emission in the TCO calculation"
              description="Include CO₂ (checkbox) info icon description"
              values={{
                lowerTwo: "\u2082",
              }}
            />
          </PopUp>
        </GridItem>

        {includeCO2 ? (
          <>
            {/*CO2 quote price (EUR/TON)*/}
            <GridItem rowSpan={1} colSpan={1}>
              <Text color={textColor}>
                <FormattedMessage
                  id="app.co2Emission.co2QuotePrice"
                  defaultMessage="CO{lowerTwo} pricing (currency/ton)"
                  description="CO₂ quote price"
                  values={{
                    lowerTwo: "\u2082",
                  }}
                />
              </Text>
            </GridItem>
            <GridItem rowSpan={1} colSpan={1}>
              <NumberControl
                control={control}
                name={namePrefix + co2EmissionKeys.co2QuotePrice}
                allowDecimals
                isReadOnly={printMode}
                rules={{
                  required: {
                    value: true,
                    message: intl.formatMessage({
                      id: "emission.required",
                      defaultMessage: "Required",
                    }),
                  },
                }}
              />
            </GridItem>
            <GridItem rowSpan={1} colSpan={1}>
              <PopUp triggerElement={<InfoIcon color="white" />}>
                <FormattedMessage
                  id="app.co2Emission.co2QuotePriceInfoIcon"
                  defaultMessage="The actual emission fee (quote price) to be used in the TCO calculation"
                  description="CO₂ quote price info icon description"
                />
              </PopUp>
            </GridItem>

            {/*Number of years in TCO*/}
            <GridItem rowSpan={1} colSpan={1}>
              <Text color="white">
                <FormattedMessage
                  id="app.co2Emission.numberOfYearsInTCO"
                  defaultMessage="Number of years in TCO"
                  description="Number of years in TCO"
                />
              </Text>
            </GridItem>
            <GridItem rowSpan={1} colSpan={1}>
              <NumberControl
                control={control}
                name={namePrefix + co2EmissionKeys.numberOfYearsTCO}
                allowDecimals={false}
                isReadOnly={printMode}
                rules={{
                  required: {
                    value: true,
                    message: intl.formatMessage({
                      id: "emission.required",
                      defaultMessage: "Required",
                    }),
                  },
                  min: {
                    value: 1,
                    message: intl.formatMessage(
                      {
                        id: "emission.min",
                        defaultMessage: "Min val is {value}",
                      },
                      { value: "1" }
                    ),
                  },
                  validate: () => {
                    const periodTcoCalcYears = getValues(
                      "tcoCalculation.periodTcoCalc"
                    )
                    const numberYearsInTco = getValues(
                      "co2Emission.numberOfYearsTCO"
                    )
                    if (periodTcoCalcYears && numberYearsInTco) {
                      if (
                        Number(numberYearsInTco) > Number(periodTcoCalcYears)
                      ) {
                        return intl.formatMessage({
                          id: "emission.period.error",
                          defaultMessage:
                            "Cannot be larger than 'Period TCO calc'",
                        })
                      }
                    }
                    return undefined
                  },
                }}
              />
            </GridItem>
            <GridItem rowSpan={1} colSpan={1}>
              <PopUp triggerElement={<InfoIcon color="white" />}>
                <FormattedMessage
                  id="app.co2Emission.numberOfYearsInTCOInfoIcon"
                  defaultMessage="Min 0 year - Max 50 years.  It is the actual period where emission fee (quote price) will be paid  and shall be used in the TCO calculation. (Can be < total period for the TCO calculation)"
                  description="Number of years in TCO info icon description"
                />
              </PopUp>
            </GridItem>
          </>
        ) : null}
      </Grid>
    </Box>
  )
}
