import ReactECharts from "echarts-for-react"
import React from "react"
import { TcoCalculationItem } from "../../../../models/calculation/tcoCalculationItem"
import { useTcoPieChartOption } from "./hooks/useTcoPieChartOption"

type TcoPieChartProps = {
  tcoDataItems: TcoCalculationItem[]
}
export const TcoPieChart: React.FC<TcoPieChartProps> = ({ tcoDataItems }) => {
  const echartsOption = useTcoPieChartOption(tcoDataItems)

  return (
    <>
      {echartsOption && (
        <ReactECharts option={echartsOption} opts={{ renderer: "svg" }} />
      )}
    </>
  )
}
