import React, { useContext, useMemo, useState } from "react"
import { TcoFormData } from "./components/TcoForm/TcoForm"

type FormDataState = {
  formData: TcoFormData | null
  setFormData: React.Dispatch<React.SetStateAction<TcoFormData | null>>
}

const FormDataContext = React.createContext<FormDataState | undefined>(
  undefined
)

type FormDataProviderProps = {
  initialFormData?: TcoFormData | null
  children: React.ReactNode
}

export const FormDataProvider = ({
  initialFormData = null,
  children,
}: FormDataProviderProps) => {
  if (useContext(FormDataContext) != null) {
    throw new Error("FormDataProvider cannot be instantiated more than once")
  }

  const [formData, setFormData] = useState<TcoFormData | null>(initialFormData)

  const value: FormDataState = useMemo(
    () => ({ formData, setFormData }),
    [formData, setFormData]
  )

  return (
    <FormDataContext.Provider value={value}>
      {children}
    </FormDataContext.Provider>
  )
}

export const useFormData = () => {
  const context = useContext(FormDataContext)
  if (!context) {
    throw new Error("useFormData must be used within a FormDataProvider")
  }

  const formData = context.formData
  const setFormData = context.setFormData
  return {
    formData,
    setFormData,
  }
}
