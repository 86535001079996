import { TcoCalculationItem } from "../../../../../models/calculation/tcoCalculationItem"
import React from "react"
import ReactECharts from "echarts-for-react"
import { useOpexEchartsOption } from "../hooks/useOpexEchartsOption"
import { ManualPricesSystems } from "../../SelectSystem/hooks/useManualPrices"

type OpexGraphProps = {
  chosenSystemOne: TcoCalculationItem
  chosenSystemTwo: TcoCalculationItem
  manualPrices?: ManualPricesSystems | null
}

export const OpexGraph: React.FC<OpexGraphProps> = ({
  chosenSystemOne,
  chosenSystemTwo,
  manualPrices = null,
}) => {
  const opexOption = useOpexEchartsOption(
    chosenSystemOne,
    chosenSystemTwo,
    manualPrices
  )

  return (
    <>
      {opexOption && (
        <ReactECharts
          option={opexOption}
          style={{ height: "100%", width: "100%" }}
          opts={{ renderer: "svg" }}
        />
      )}
    </>
  )
}
