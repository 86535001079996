import { useQuery } from "react-query"
import { useAuth } from "../keycloak"
import { BaseConfiguration } from "./BaseConfiguration"
import { CalculationApi } from "./openapi"

export const favoriteQueryKey = "useFavoriteQuery"

export const useFavorite = (enabled: boolean = true, id: string | null) => {
  const { token } = useAuth()
  const api = new CalculationApi(new BaseConfiguration(token))
  return useQuery(
    favoriteQueryKey,
    () => api.calculationGetSavedQuery(id ? id : ""),
    {
      enabled,
    }
  )
}
