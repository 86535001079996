import { Flex, Heading, Image, Select, VStack, Divider } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import { FlexPipeControl } from "./FlexPipeControl/FlexPipeControl"
import { FormattedMessage, useIntl } from "react-intl"
import AluFlextra from "../../../../assets/images/AluFlextra.png"
import AluFlextraTwin from "../../../../assets/images/AluFlextraTwin.png"
import RullePaaHoejkant from "../../../../assets/images/RullePaaHoejkant.png"
import { useFieldArray, useFormContext } from "react-hook-form"
import { TcoFormData } from "../../TcoForm"
import { Pipe } from "../../../../models/pipe"
import { PipeSystemKind } from "../../../../models/pipeSystemKind"
import { useSavedQuery } from "../../../../hooks/useSavedQuery"

type PipeType = "AluFlextra" | "CuFlex" | "PexFlextra" | "PertFlextra"

type FlexPipeSelectionProps = {
  aluFlexPipes: Pipe[]
  cuFlexPipes: Pipe[]
  pexFlexPipes: Pipe[]
  pertFlexPipes: Pipe[]
  printMode?: boolean
}

export const FlexPipeSelection: React.FC<FlexPipeSelectionProps> = ({
  aluFlexPipes,
  cuFlexPipes,
  pexFlexPipes,
  pertFlexPipes,
  printMode = false,
}) => {
  const intl = useIntl()
  const savedQuery = useSavedQuery()
  const [pipesLoaded, setPipesLoaded] = useState(false)
  const [availablePipeSystems, setAvailablePipeSystems] = useState<
    PipeSystemKind[]
  >([])
  const [chosenPipeSystemType, setChosenPipeSystemType] = useState<
    PipeType | undefined
  >(undefined)

  const { getValues } = useFormContext<TcoFormData>()
  const fieldArrayMethods = useFieldArray({
    name: "flexPipes",
  })

  // Stringify data to fix referential equality
  const dataAsString = JSON.stringify({
    aluFlexPipes,
    cuFlexPipes,
    pexFlexPipes,
    pertFlexPipes,
  })

  const currentFlexPipesInForm = getValues("flexPipes")

  useEffect(() => {
    if (!pipesLoaded) {
      const currentPipeIds = currentFlexPipesInForm.map((p) => p.pipeId)
      if (aluFlexPipes.find((p) => currentPipeIds.includes(p.pipeId))) {
        setChosenPipeSystemType("AluFlextra")
      } else if (cuFlexPipes.find((p) => currentPipeIds.includes(p.pipeId))) {
        setChosenPipeSystemType("CuFlex")
      } else if (pexFlexPipes.find((p) => currentPipeIds.includes(p.pipeId))) {
        setChosenPipeSystemType("PexFlextra")
      } else if (pertFlexPipes.find((p) => currentPipeIds.includes(p.pipeId))) {
        setChosenPipeSystemType("PertFlextra")
      }
      if (chosenPipeSystemType) setPipesLoaded(true)
    }
  }, [
    currentFlexPipesInForm,
    aluFlexPipes,
    cuFlexPipes,
    pexFlexPipes,
    pertFlexPipes,
    savedQuery,
    pipesLoaded,
    chosenPipeSystemType,
  ])

  useEffect(() => {
    if (pipesLoaded) {
      const allPipes = [
        ...aluFlexPipes,
        ...cuFlexPipes,
        ...pexFlexPipes,
        ...pertFlexPipes,
      ]
      // If user has chosen pipe that is invalid according to new Design Data clear form
      let clearFlexPipesForm = false
      currentFlexPipesInForm.forEach((formPipe) => {
        const result = allPipes.find((p) => p.pipeId === formPipe.pipeId)
        if (!result) {
          clearFlexPipesForm = true
        }
      })

      if (clearFlexPipesForm) {
        setChosenPipeSystemType(undefined)
        fieldArrayMethods.remove()
      }
    }

    // Set available pipe systems
    const pipeSystemList: PipeSystemKind[] = []
    if (aluFlexPipes.length > 0) {
      pipeSystemList.push("AluFlextra")
    }
    if (cuFlexPipes.length > 0) {
      pipeSystemList.push("CuFlex")
    }
    if (pexFlexPipes.length > 0) {
      pipeSystemList.push("PexFlextra")
    }
    if (pertFlexPipes.length > 0) {
      pipeSystemList.push("PertFlextra")
    }
    setAvailablePipeSystems(pipeSystemList)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataAsString])

  const getPipesFromCategory = (): Pipe[] => {
    if (chosenPipeSystemType === "AluFlextra") {
      return aluFlexPipes
    }
    if (chosenPipeSystemType === "CuFlex") {
      return cuFlexPipes
    }
    if (chosenPipeSystemType === "PexFlextra") {
      return pexFlexPipes
    }
    if (chosenPipeSystemType === "PertFlextra") {
      return pertFlexPipes
    }
    return []
  }

  return (
    <VStack alignItems="start" w="40%">
      <Heading size={"md"} color="brand.red">
        <FormattedMessage
          id="app.tcoPipeSelection.flexPipesTitle"
          defaultMessage="FlexPipe system"
          description="Title for the flex pipe system types"
        />
      </Heading>
      <Divider />
      <Flex mt={10} direction="row" alignItems={"center"}>
        <Image
          src={AluFlextra}
          boxSize="75px"
          objectFit="cover"
          alt="AluFlextra pipe"
          transform={"rotate(-25deg)"}
        />
        <Image
          ml={"-55"}
          src={AluFlextra}
          boxSize="75px"
          objectFit="cover"
          alt="AluFlextra pipe"
          transform={"rotate(-25deg)"}
        />
        <Image
          src={AluFlextraTwin}
          boxSize="75px"
          objectFit="contain"
          alt="AluFlextra twin pipe"
          transform={"rotate(-25deg)"}
          marginLeft="-24px"
        />
        <Image src={RullePaaHoejkant} boxSize="75px" objectFit="contain" />
      </Flex>

      <Select
        isReadOnly={printMode}
        pointerEvents={printMode ? "none" : undefined}
        placeholder={intl.formatMessage({
          id: "flex-pipe-selection.select-type",
          defaultMessage: "Select type",
        })}
        width={200}
        onChange={(event) =>
          setChosenPipeSystemType(event.target.value as PipeType)
        }
        value={chosenPipeSystemType}
      >
        {availablePipeSystems.map((pipeSystemType) => {
          return (
            <option key={pipeSystemType} value={pipeSystemType}>
              {pipeSystemType}
            </option>
          )
        })}
      </Select>

      <FlexPipeControl
        pipes={getPipesFromCategory()}
        fieldArrayMethods={fieldArrayMethods}
        printMode={printMode}
      />
    </VStack>
  )
}
