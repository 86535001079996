import { FormControl, FormErrorMessage } from "@chakra-ui/react"
import React from "react"
import { Control, RegisterOptions, useController } from "react-hook-form"
import { AmountInput } from "./AmountInput"

type NumberControlProps = {
  control: Control<any>
  name: string
  rules: RegisterOptions
  allowDecimals: boolean
  isReadOnly?: boolean
}
export const NumberControl: React.FC<NumberControlProps> = ({
  control,
  name,
  rules,
  allowDecimals,
  isReadOnly = false,
}) => {
  const {
    field: { ref, ...inputProps },
    fieldState: { error },
  } = useController({
    name: name,
    control,
    rules: rules,
    shouldUnregister: true,
  })

  return (
    <FormControl isInvalid={error !== undefined}>
      <AmountInput
        locale="da-DK"
        {...inputProps}
        ref={ref}
        allowDecimals={allowDecimals}
        isReadonly={isReadOnly}
      />
      <FormErrorMessage>{error && error.message}</FormErrorMessage>
    </FormControl>
  )
}
