import {
  CalculationResultDto,
  TcoCalculationGroupDto,
  TcoCalculationItemDto,
} from "../openapi"
import { CalculationResult } from "../../models/calculation/calculationResult"
import {
  PipeSystemPreInsulatedPipeKind,
  TcoCalculationItem,
} from "../../models/calculation/tcoCalculationItem"
import { mapToPipeSystemKind } from "./pipeSystemKind.mapper"
import { mapToInsulationSeriesKind } from "./insulationSeriesKind.mapper"
import { mapToPipeSystemPreInsulatedPipeKind } from "./pipeSystemPreInsulatedPipeKind.mapper"
import { TcoCalculationGroup } from "../../models/calculation/tcoCalculationGroup"

export const mapToCalculationResult = (
  calculationResultDto: CalculationResultDto
): CalculationResult => {
  const tcoList = calculationResultDto.tcoCalculationGroups!.map((o) =>
    mapToCalculationGroup(o)
  )

  return {
    tcoCalculationGroups: tcoList,
  }
}

const mapToCalculationGroup = (
  calculationResultDto: TcoCalculationGroupDto
): TcoCalculationGroup => {
  const tcoList = calculationResultDto.systems!.map((o) =>
    mapToTcoCalculationItem(o)
  )

  return {
    systems: tcoList.sort(tcoCompare),
    pipeSystemKind: mapToPipeSystemKind(calculationResultDto.pipeSystemKind!),
    nominalDiameters: calculationResultDto!.nominalDiameters!,
  }
}

const mapToTcoCalculationItem = (
  tcoCalculationItemDto: TcoCalculationItemDto
): TcoCalculationItem => {
  return {
    nominalDiameters: tcoCalculationItemDto.pipeSolutionKeys!.nominalDiameters!,
    pipeSystemKind: mapToPipeSystemKind(
      tcoCalculationItemDto.pipeSolutionKeys!.pipeSystemKind!
    ),
    insulationSeriesKind: mapToInsulationSeriesKind(
      tcoCalculationItemDto.pipeSolutionKeys!.pipeSystemInsulationSeriesKind!
    ),
    pipeSystemPreInsulatedPipeKind: mapToPipeSystemPreInsulatedPipeKind(
      tcoCalculationItemDto.pipeSolutionKeys!.pipeSystemPreInsulatedPipeKind!
    ),
    lambda: tcoCalculationItemDto.pipeSolutionKeys!.lambdaValue!,
    insulationSeriesCost: {
      pipeMaterial: tcoCalculationItemDto.insulationSeriesPrice!.pipeMaterial!,
      trenchExcavation:
        tcoCalculationItemDto.insulationSeriesPrice!.trenchExcavation!,
      pipeInstallation:
        tcoCalculationItemDto.insulationSeriesPrice!.pipeInstallation!,
      energyPresentValue:
        tcoCalculationItemDto.insulationSeriesPrice!.energyPresentValue!,
      emissionPresentValue:
        tcoCalculationItemDto.insulationSeriesPrice?.emissionPresentValue,
    },
    energyLossFirstYear: {
      energyLoss: tcoCalculationItemDto.energyLossFirstYear?.energyLoss!,
      energyLossPercent:
        tcoCalculationItemDto.energyLossFirstYear?.energyLossPercent!,
      energySavings: tcoCalculationItemDto.energyLossFirstYear?.energySavings!,
      energySavingsPercent:
        tcoCalculationItemDto.energyLossFirstYear?.energySavingsPercent!,
    },
    emissionComparison: {
      emission: tcoCalculationItemDto.emissionComparison?.emission!,
      emissionPercent:
        tcoCalculationItemDto.emissionComparison?.emissionPercent!,
      emissionSavings:
        tcoCalculationItemDto.emissionComparison?.emissionSavings!,
      emissionSavingsPercent:
        tcoCalculationItemDto.emissionComparison?.emissionSavingsPercent!,
      averageEmission:
        tcoCalculationItemDto.emissionComparison?.averageEmission!,
    },
  }
}

const tcoCompare = (a: TcoCalculationItem, b: TcoCalculationItem): number => {
  // if positive result, then b is before a
  const aNumber = preInsulatedPipeAsNumber(a.pipeSystemPreInsulatedPipeKind)
  const bNumber = preInsulatedPipeAsNumber(b.pipeSystemPreInsulatedPipeKind)

  // return aNumber - bNumber
  const number = bNumber - aNumber

  if (number === 0) {
    if (a.insulationSeriesKind < b.insulationSeriesKind) {
      return -1
    } else {
      return 1
    }
  }

  return number
}

/**
 * Used for sorting purposes
 * @param key
 */
const preInsulatedPipeAsNumber = (
  key: PipeSystemPreInsulatedPipeKind
): number => {
  switch (key) {
    case "AluFlextraPairOfPipe":
    case "CuFlexPairOfPipe":
    case "PexFlextraPairOfPipe":
    case "PertFlextraPairOfPipe":
      return 2

    case "AluFlextraTwinPipe":
    case "CuFlexTwinPipe":
    case "PexFlextraTwinPipe":
    case "PertFlextraTwinPipe":
      return 1

    case "TraditionalPairOfPipeWithoutDiffusionBarrier":
      return 7
    case "ContiPairOfPipeWithDiffusionBarrier":
      return 5
    case "TraditionalTwinPipeWithoutDiffusionBarrier":
      return 4
    case "ContiTwinPipeWithDiffusionBarrier":
      return 3
    case "ContiPairOfPipeWithAndWithoutDiffusionBarrier":
      return 6
  }
}
