import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  Link,
} from "@chakra-ui/react"
import { useIntl } from "react-intl"
import { ContactForm } from "./ContactForm"

export type ContactFormModalProps = {}

export const ContactFormModal: React.FC<ContactFormModalProps> = () => {
  const intl = useIntl()
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <Link onClick={onOpen} color="white">
        {intl.formatMessage({
          id: "contactFormModalButton",
          defaultMessage: "Contact form",
        })}
      </Link>
      <Modal isOpen={isOpen} size={"xl"} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {intl.formatMessage({
              id: "contactFormModal.header",
              defaultMessage: "Please enter your contact information",
            })}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ContactForm onClose={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
