import { BaseConfiguration } from "./BaseConfiguration"
import { useQuery } from "react-query"
import { PressureTemperature } from "../models/pressureTemperature"

import {
  CalculationApi,
  FilteredPipeSystemDiameterDto,
  PipeSystemKindDto,
} from "./openapi"
import { Pipe } from "../models/pipe"
import { mapToPipeSystemKind } from "./mappers/pipeSystemKind.mapper"
import { useAuth } from "../keycloak"
import { numberFormatter } from "../components/TcoResult/utils/decimalConverter"

export const useAvailablePipesQueryKey = "useAvailablePipesQueryKey"
export const useAvailablePipes = (pressureTemperature: PressureTemperature) => {
  const { token } = useAuth()
  const fetchPipes = async (
    pressureTemperature: PressureTemperature
  ): Promise<Pipe[]> => {
    const calculationApi = new CalculationApi(new BaseConfiguration(token))
    const availablePipes = await calculationApi.calculationPostAll({
      minMediaTemperature: pressureTemperature.minMediaTemperature,
      maxMediaTemperature: pressureTemperature.maxMediaTemperature,
      maxMediaPressure: pressureTemperature.pressureBar,
    })

    return availablePipes.data.map(mapToPipe)
  }

  return useQuery(
    ["useAvailablePipesQueryKey", pressureTemperature],
    () => fetchPipes(pressureTemperature),
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    }
  )
}

const mapToPipe = (pipeDto: FilteredPipeSystemDiameterDto): Pipe => {
  return {
    pipeId: pipeDto.pipeSystemDiameterId!,
    name:
      pipeDto.pipeSystemKind === PipeSystemKindDto.BondedSteel
        ? "DN" +
          pipeDto.nominalDiameter +
          " / ⌀" +
          numberFormatter.format(pipeDto.actualDiameter || 0)
        : "⌀" + numberFormatter.format(pipeDto.actualDiameter || 0),
    pipeSystemKind: mapToPipeSystemKind(pipeDto.pipeSystemKind!),
  }
}
