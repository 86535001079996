import { ChartColor } from "../../TcoGraphs/TcoBarChart/barChartColors"
import {
  Input,
  VStack,
  HStack,
  InputGroup,
  Text,
  Checkbox,
} from "@chakra-ui/react"
import { useState } from "react"
import { useEffect } from "react"
import { useRef } from "react"
import {
  numberFormatter,
  removeSeperatorsFromNumberString,
} from "../../utils/decimalConverter"
import { PopUp } from "../../../shared/PopUp/PopUp"
import { InfoIcon } from "@chakra-ui/icons"
import { FormattedMessage } from "react-intl"
import { ManualPrices } from "../SelectSystem/hooks/useManualPrices"

type PricesInputProps = {
  type: "Budget" | "Manual"
  checkBoxDisabled?: boolean
  printMode?: boolean
  pipeInstallationCost?: number
  trenchCost?: number
  pipeMaterial?: number
  checked?: boolean
  onEnabled?: (checked: boolean) => void
  onChange?: (prices: ManualPrices) => void
}

export const PricesInput = ({
  type,
  checkBoxDisabled = false,
  pipeInstallationCost,
  trenchCost,
  pipeMaterial,
  checked = false,
  onEnabled,
  onChange,
  printMode = false,
}: PricesInputProps) => {
  const isBudget = type === "Budget"
  const [pipeInstallationPrice, setPipeInstallationPrice] =
    useState(pipeInstallationCost)
  const [trenchPrice, setTrenchPrice] = useState(trenchCost)
  const [pipeMaterialPrice, setPipeMaterialPrice] = useState(pipeMaterial)

  const onChangeRef = useRef(onChange)

  useEffect(() => {
    onChangeRef.current = onChange
  }, [onChange])

  useEffect(() => {
    onChangeRef.current?.({
      pipeInstallation: pipeInstallationPrice,
      trench: trenchPrice,
      pipeMaterial: pipeMaterialPrice,
    })
  }, [pipeInstallationPrice, trenchPrice, pipeMaterialPrice])

  useEffect(() => {
    setPipeInstallationPrice(pipeInstallationCost)
  }, [pipeInstallationCost, setPipeInstallationPrice])
  useEffect(() => {
    setTrenchPrice(trenchCost)
  }, [trenchCost, setTrenchPrice])
  useEffect(() => {
    setPipeMaterialPrice(pipeMaterial)
  }, [pipeMaterial, setPipeMaterialPrice])

  return (
    <InputGroup>
      <VStack spacing="4" w={"100%"}>
        <HStack>
          <Checkbox
            isChecked={checked}
            isDisabled={checkBoxDisabled}
            onChange={(e) => {
              if (!isBudget) {
                setPipeInstallationPrice(undefined)
                setTrenchPrice(undefined)
                setPipeMaterialPrice(undefined)
              }

              onEnabled?.(e.target.checked)
            }}
          >
            <HStack>
              <Text>
                {isBudget ? (
                  <FormattedMessage
                    id="budget_price"
                    defaultMessage="Budget price"
                  />
                ) : (
                  <FormattedMessage
                    id="manual_price"
                    defaultMessage="Manual price"
                  />
                )}
              </Text>
            </HStack>
          </Checkbox>

          <PopUp triggerElement={<InfoIcon color="grey" />}>
            {isBudget && (
              <FormattedMessage
                id="app.compare2systems.bugdetPrice"
                defaultMessage="The investment prices above (calibrated with above sliders) derive from the TCO calculation"
                description="Budget price info icon description"
              />
            )}
            {!isBudget && (
              <FormattedMessage
                id="app.compare2systems.manualPrice"
                defaultMessage="Here you can activate manual prices and enter your actual prices for the chosen system"
                description="Manual price info icon description"
              />
            )}
          </PopUp>
        </HStack>
        <Input
          type="text"
          width={150}
          borderRadius={6}
          size="sm"
          bg={ChartColor.Yellow}
          color="white"
          textAlign="center"
          value={
            pipeInstallationPrice == null || !checked
              ? ""
              : numberFormatter.format(pipeInstallationPrice || 0)
          }
          isDisabled={!checked}
          isReadOnly={!checked || isBudget || printMode}
          onChange={(e) =>
            setPipeInstallationPrice(
              removeSeperatorsFromNumberString(e.target.value)
            )
          }
        />
        <Input
          type="text"
          width={150}
          borderRadius={6}
          size="sm"
          bg={ChartColor.DarkGrey}
          color="white"
          textAlign="center"
          value={
            trenchPrice == null || !checked
              ? ""
              : numberFormatter.format(trenchPrice || 0)
          }
          isDisabled={!checked}
          isReadOnly={!checked || isBudget || printMode}
          onChange={(e) =>
            setTrenchPrice(removeSeperatorsFromNumberString(e.target.value))
          }
        />
        <Input
          type="text"
          width={150}
          borderRadius={6}
          size="sm"
          bg={ChartColor.Blue}
          color="white"
          textAlign="center"
          isDisabled={!checked}
          isReadOnly={!checked || isBudget || printMode}
          value={
            pipeMaterialPrice == null || !checked
              ? ""
              : numberFormatter.format(pipeMaterialPrice || 0)
          }
          onChange={(e) =>
            setPipeMaterialPrice(
              removeSeperatorsFromNumberString(e.target.value)
            )
          }
        />
      </VStack>
    </InputGroup>
  )
}
