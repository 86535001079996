import { BaseConfiguration } from "./BaseConfiguration"
import { InfoApi } from "./openapi"
import { useQuery } from "react-query"

export const versionQueryKey = "useVersionQuery"

export const useVersion = () => {
  const api = new InfoApi(new BaseConfiguration())
  return useQuery(versionQueryKey, () => api.infoGetVersion(), {
    staleTime: 86400000,
  })
}

export const GetFullVersion = () => {
  const { data } = useVersion()
  const buildNumber = process.env.REACT_APP_BUILD
    ? `${process.env.REACT_APP_BUILD}`
    : ""
  return `${data?.data}-${buildNumber}`
}
