import { useQuery, useQueryClient } from "react-query"
import { PipeControlData, TcoFormData } from "../components/TcoForm/TcoForm"
import { useAuth } from "../keycloak"
import { EmissionKind, EmissionSource } from "../models/emissionSource"
import { BaseConfiguration } from "./BaseConfiguration"
import {
  CalculationApi,
  EmissionSourceKindDto,
  PipeSystemDiameterByTrenchDistanceInput,
  SavedQueryDto,
} from "./openapi"

export const favoritesQueryKey = "useFavoritesQuery"

export const usePrefetchFavorites = () => {
  const { token } = useAuth()
  const queryClient = useQueryClient()
  const api = new CalculationApi(new BaseConfiguration(token))
  return queryClient.prefetchQuery(favoritesQueryKey, () =>
    api.calculationGetSavedQueries()
  )
}

export const useFavorites = (enabled: boolean = true) => {
  const { token } = useAuth()
  const api = new CalculationApi(new BaseConfiguration(token))
  return useQuery(favoritesQueryKey, () => api.calculationGetSavedQueries(), {
    refetchOnMount: "always",
    refetchOnReconnect: "always",
    refetchOnWindowFocus: "always",
    staleTime: 5000,
    enabled,
  })
}

export const mapSavedQueryDataToTcoFormData = (
  savedQuery: SavedQueryDto,
  emissionsSources: EmissionSource[] | undefined
): TcoFormData | null => {
  const queryData = savedQuery?.queryData
  if (queryData == null) {
    return null
  }

  const designData = queryData.designData
  const calculationData = queryData.calculationData
  const complexityData = queryData.complexityData
  const emissionData = queryData.emissionData

  return {
    designData: {
      daysInOperationSummer:
        designData?.operationPeriodSummer?.toString() || "",
      daysInOperationWinter:
        designData?.operationPeriodWinter?.toString() || "",
      tFlowSummer: designData?.temperatureFlowSummer?.toString() || "",
      tFlowWinter: designData?.temperatureFlowWinter?.toString() || "",
      tReturnSummer: designData?.temperatureReturnSummer?.toString() || "",
      tReturnWinter: designData?.temperatureReturnWinter?.toString() || "",
      lambdaSoil: designData?.lambdaSoil?.toString() || "",
      pressure: designData?.pressure?.toString() || "",
      soilCover: designData?.soilCover?.toString() || "",
      tSoilSummer: designData?.temperatureSoilSummer?.toString() || "",
      tSoilWinter: designData?.temperatureSoilWinter?.toString() || "",
    },
    tcoCalculation: {
      currency: calculationData?.currencyCode as string,
      energyUnit: calculationData?.energyUnitKind as string,
      exchangeRate: calculationData?.currencyExchangeRate?.toString() || "1",
      interest: calculationData?.interestRate?.toString() || "",
      periodTcoCalc: calculationData?.calculationPeriod?.toString() || "",
      priceEnergy: calculationData?.energyPrice?.toString() || "",
      yearlyRiseInEnergyPrice:
        calculationData?.yearlyRiseInEnergyPrice?.toString() || "",
    },
    complexityData: {
      excavationComplexity: complexityData?.excavationComplexity || 0,
      installationComplexity: complexityData?.installationComplexity || 0,
      pipeSystemComplexity: complexityData?.pipeSystemComplexity || 0,
      energyComplexity: complexityData?.energyPriceComplexity || 0,
    },
    co2Emission: {
      fuelType: emissionData?.emissionSourceKind as string,
      co2QuotePrice:
        emissionData?.emissionSourceInCalculation?.emissionQuotePrice?.toString(),
      includeCo2:
        emissionData?.emissionSourceInCalculation?.emissionPeriod != null &&
        emissionData?.emissionSourceInCalculation?.emissionQuotePrice != null,
      numberOfYearsTCO:
        emissionData?.emissionSourceInCalculation?.emissionPeriod?.toString(),
      tonCo2:
        emissionData?.emissionSourceKind === EmissionSourceKindDto.Custom
          ? emissionData.customEmissionValue?.toString() || ""
          : emissionsSources?.find(
              (e) =>
                e.emissionKind ===
                (emissionData?.emissionSourceKind?.toString() as EmissionKind)
            )?.value || "",
    },
    bondedPipes: mapPipesToPipeControlData(
      queryData.bondedPipeSystemsPipeSystemDiameterByTrenchDistance
    ),
    flexPipes: mapPipesToPipeControlData(
      queryData.flexPipeSystemsPipeSystemDiameterByTrenchDistance
    ),
  }
}

const mapPipesToPipeControlData = (
  pipes?: PipeSystemDiameterByTrenchDistanceInput[]
): PipeControlData[] => {
  return (
    pipes?.map((p) => ({
      pipeId: p.pipeSystemDiameterId || "",
      meters: p.trenchDistance?.toString() || "",
    })) || []
  )
}
