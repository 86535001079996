import React from "react"
import { TcoCalculationItem } from "../../../../models/calculation/tcoCalculationItem"
import ReactECharts from "echarts-for-react"
import { useROIEchartsOptions } from "./hooks/useROIEchartsOtions"
import { Box } from "@chakra-ui/react"
import { ManualPricesSystems } from "../SelectSystem/hooks/useManualPrices"

type ROIGraphProps = {
  chosenSystemOne: TcoCalculationItem
  chosenSystemTwo: TcoCalculationItem
  manualPrices?: ManualPricesSystems
}

export const ROIGraph: React.FC<ROIGraphProps> = ({
  chosenSystemOne,
  chosenSystemTwo,
  manualPrices = null,
}) => {
  const options = useROIEchartsOptions(
    chosenSystemOne,
    chosenSystemTwo,
    manualPrices
  )

  return (
    <>
      {options && (
        <Box h="600">
          <ReactECharts
            option={options}
            style={{ height: "100%", width: "100%" }}
            opts={{ renderer: "svg" }}
          />
        </Box>
      )}
    </>
  )
}
