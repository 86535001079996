import { InsulationSeriesKind } from "../../models/calculation/tcoCalculationItem"
import { PipeSystemInsulationSeriesKindDto } from "../openapi"

export const mapToInsulationSeriesKind = (
  dto: PipeSystemInsulationSeriesKindDto
): InsulationSeriesKind => {
  switch (dto) {
    case PipeSystemInsulationSeriesKindDto.Series1:
      return "Series1"
    case PipeSystemInsulationSeriesKindDto.Series2:
      return "Series2"
    case PipeSystemInsulationSeriesKindDto.Series3:
      return "Series3"
    case PipeSystemInsulationSeriesKindDto.Series4:
      return "Series4"
    default:
      throw Error(
        `mapToInsulationSeriesKind received invalid PipeSystemInsulationSeriesKind: ${dto}`
      )
  }
}
