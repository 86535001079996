import { EChartsOption } from "echarts"
import { MarkLineComponentOption } from "echarts/types/dist/echarts"
import { useEffect } from "react"
import { useState } from "react"
import { useIntl } from "react-intl"
import { useFormData } from "../../../../../FormDataContext"
import { TcoCalculationItem } from "../../../../../models/calculation/tcoCalculationItem"
import { CurrencyCode } from "../../../../../models/currency"
import { ChartColor } from "../../../TcoGraphs/TcoBarChart/barChartColors"
import { asMultipleTooltipFormat } from "../../../TcoResult"
import { numberFormatter } from "../../../utils/decimalConverter"
import { useCompareSystemsCalculation } from "../../hooks/useCompareSystemsCalculation"
import { ManualPricesSystems } from "../../SelectSystem/hooks/useManualPrices"
import { mapToLocalisedXAxisName } from "../../SelectSystem/hooks/useSelectSystemEchartOption"

export const useROIEchartsOptions = (
  chosenSystemOne: TcoCalculationItem,
  chosenSystemTwo: TcoCalculationItem,
  manualPrices?: ManualPricesSystems | null
): EChartsOption | null => {
  const intl = useIntl()
  const [option, setOption] = useState<EChartsOption | null>(null)
  const data = useCompareSystemsCalculation({
    calculationItems: [chosenSystemOne, chosenSystemTwo],
    manualPrices,
  }).data?.data
  const { formData } = useFormData()

  useEffect(() => {
    const currency = formData?.tcoCalculation.currency || CurrencyCode.Eur
    const axisNameOne = mapToLocalisedXAxisName(
      chosenSystemOne,
      currency,
      false,
      intl
    )
    const axisNameTwo = mapToLocalisedXAxisName(
      chosenSystemTwo,
      currency,
      false,
      intl
    )

    if (data == null) {
      setOption(null)
      return
    }

    const years =
      data.firstSystemData?.yearlyPrices?.flatMap((y) =>
        y.year != null ? [y.year] : []
      ) || []

    const systemOnePrices =
      data.firstSystemData?.yearlyPrices?.flatMap((y) =>
        y.price != null ? [y.price] : []
      ) || []

    const systemTwoPrices =
      data.secondSystemData?.yearlyPrices?.flatMap((y) =>
        y.price != null ? [y.price] : []
      ) || []

    const roiYears = data.roiYears || 0
    const yearIndex = years.indexOf(roiYears)

    const markLine: MarkLineComponentOption | undefined =
      roiYears > 0
        ? {
            symbol: "none",
            silent: true,
            data: [
              {
                xAxis: yearIndex,
                name: intl.formatMessage({
                  id: "roi.payback",
                  defaultMessage: "Simple payback time",
                }),
                label: {
                  show: true,
                  formatter: intl.formatMessage(
                    {
                      id: "roi.years-value",
                      defaultMessage: "{value} years",
                    },
                    { value: numberFormatter.format(roiYears) }
                  ),
                  fontSize: 16,
                  position: "end",
                  rotate: 0,
                },
                lineStyle: {
                  color: ChartColor.DarkGrey,
                  type: "dashed",
                  width: 2,
                },
              },
            ],
          }
        : undefined

    const option: EChartsOption = {
      legend: {
        top: "bottom",
        textStyle: {
          fontSize: 14,
        },
      },
      grid: {
        containLabel: true,
      },
      tooltip: {
        trigger: "axis",
        formatter: (p) =>
          asMultipleTooltipFormat(p)[0].seriesName +
          ": <b>" +
          numberFormatter.format(
            asMultipleTooltipFormat(p)[0].value as number
          ) +
          ` ${currency}</b><br>` +
          asMultipleTooltipFormat(p)[1].seriesName +
          ": <b>" +
          numberFormatter.format(
            asMultipleTooltipFormat(p)[1].value as number
          ) +
          ` ${currency}</b>`,
      },
      textStyle: {
        fontFamily: "Lato",
      },
      xAxis: {
        name: intl.formatMessage({ id: "roi.years", defaultMessage: "Years" }),
        type: "category",
        data: years,
        axisLabel: {
          interval: 9,
        },
      },
      yAxis: {
        name: `${currency}`,
        type: "value",
        axisLabel: {
          formatter: (value: number) => numberFormatter.format(value as number),
        },
      },
      series: [
        {
          name: axisNameOne,
          type: "line",
          data: systemOnePrices,
          showSymbol: false,
          color: ChartColor.Red,
          markLine: markLine,
        },
        {
          name: axisNameTwo,
          type: "line",
          data: systemTwoPrices,
          showSymbol: false,
          color: ChartColor.Blue,
        },
      ],
    }

    setOption(option)
  }, [chosenSystemOne, chosenSystemTwo, data, formData, intl])

  return option
}
