import { KeycloakInstance } from "keycloak-js"
import { createContext, useContext, useEffect, useMemo, useState } from "react"
import { useQueryClient } from "react-query"

export interface AuthContextState {
  isLoggedIn: boolean
  isLoggingIn: boolean
  keycloak: KeycloakInstance
}

const AuthContext = createContext<AuthContextState | undefined>(undefined)

interface AuthProviderProps {
  children: React.ReactNode
  keycloak: KeycloakInstance
}

export const AuthProvider = ({ children, keycloak }: AuthProviderProps) => {
  const queryClient = useQueryClient()
  if (useContext(AuthContext) != null) {
    throw new Error("AuthProvider cannot be instantiated more than once")
  }

  const [isLoggedIn, setIsLoggedIn] =
    useState<AuthContextState["isLoggedIn"]>(false)

  const [isLoggingIn, setIsLoggingIn] =
    useState<AuthContextState["isLoggingIn"]>(false)

  useEffect(() => {
    setIsLoggingIn(true)
    keycloak
      .init({
        onLoad: "login-required",
      })
      .then((_) => {
        setIsLoggingIn(false)
      })
      .catch((_) => {
        setIsLoggingIn(false)
      })
  }, [keycloak])

  keycloak.onAuthSuccess = () => {
    setIsLoggedIn(true)
    setIsLoggingIn(false)
  }

  keycloak.onAuthLogout = () => {
    setIsLoggedIn(false)
    setIsLoggingIn(false)
    queryClient.clear()
  }

  keycloak.onTokenExpired = () => {
    keycloak.updateToken(30)
  }

  useEffect(() => {
    if (isLoggedIn) {
      keycloak.loadUserInfo()
    }
  }, [keycloak, isLoggedIn])

  const value: AuthContextState = useMemo(
    () => ({ isLoggedIn, isLoggingIn, keycloak }),
    [keycloak, isLoggedIn, isLoggingIn]
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error("useAuth must be used within a AuthProvider")
  }
  const keycloak = context.keycloak
  const login = () => {
    keycloak.login()
  }

  const logout = () => {
    keycloak.logout()
  }

  const token = async (): Promise<string> => {
    await keycloak.updateToken(60)
    if (!keycloak.token) {
      throw new Error("Unable to get keycloak token")
    }

    return keycloak.token
  }

  const tokenParsed = keycloak.tokenParsed as any
  const roles = tokenParsed?.user_roles as string[]
  const username = tokenParsed?.preferred_username
  const email = tokenParsed?.email as string | undefined

  let isInternalUser = false
  if (email != null) {
    const internalDomains = ["@logstor.com", "@kingspan.com"]
    isInternalUser = internalDomains.some((el) => email.includes(el))
  }

  return {
    isLoggedIn: context.isLoggedIn,
    isLoggingIn: context.isLoggingIn,
    login,
    logout,
    token,
    roles,
    username,
    isInternalUser,
  }
}
