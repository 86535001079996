import * as CSS from "csstype"
import {
  Box,
  Flex,
  VStack,
  Divider,
  HStack,
  Tooltip,
  useBreakpointValue,
} from "@chakra-ui/react"
import { TcoPipeSelection } from "./TcoPipeSelection/TcoPipeSelection"
import {
  DesignData,
  DesignDataControlsData,
  getDefaultDesignData,
} from "./TcoDataInput/DesignData/DesignData"
import {
  getDefaultTCOCalculationData,
  TCOCalculation,
  TCOCalculationControlsData,
} from "./TcoDataInput/TCOCalculation/TCOCalculation"
import {
  Co2Emission,
  Co2EmissionControlsData,
  getDefaultCo2EmissionData,
} from "./TcoDataInput/Co2Emission/Co2Emission"
import { getKeyToKeyMap } from "../../utils/getKeyToKeyMap"
import { FormProvider, useForm } from "react-hook-form"
import React, { useCallback, useState } from "react"
import { ContentWidth } from "../shared/ContentWidth/ContentWidth"
import { CalculateButton } from "./CalculateButton/CalculateButton"
import { PressureTemperature } from "../../models/pressureTemperature"
import {
  ComplexityDataControlsData,
  getDefaultComplexityData,
} from "../Sliders/InvestmentSliders"
import { TcoSliders } from "../Sliders/TcoSliders"
import { useFormData } from "../../FormDataContext"
import { FavoriteButton } from "./FavoriteButton/FavoriteButton"
import { mapSavedQueryDataToTcoFormData } from "../../rest/useFavorites"
import { useEffect } from "react"
import { useIntl } from "react-intl"
import { useRef } from "react"
import { useCalculation } from "../../rest/useCalculation"
import { useSavedQuery } from "../../hooks/useSavedQuery"
import { useEmissionSources } from "../../rest/useEmissionSources"
import TagManager from "react-gtm-module"

export type TcoFormData = {
  designData: DesignDataControlsData
  tcoCalculation: TCOCalculationControlsData
  co2Emission: Co2EmissionControlsData
  bondedPipes: PipeControlData[]
  flexPipes: PipeControlData[]
  complexityData?: ComplexityDataControlsData
}

export type PipeControlData = {
  pipeId: string
  meters: string
}

const tempTcoFormData: TcoFormData = {
  designData: getDefaultDesignData(),
  tcoCalculation: getDefaultTCOCalculationData(),
  co2Emission: getDefaultCo2EmissionData(),
  bondedPipes: [],
  flexPipes: [],
  complexityData: getDefaultComplexityData(),
}

export const tcoFormDataKeys = getKeyToKeyMap(tempTcoFormData)

export const TcoForm: React.FC = () => {
  const intl = useIntl()
  const [currentPressureAndTemperature, setCurrentPressureAndTemperature] =
    useState<PressureTemperature | null>(null)
  const { formData, setFormData } = useFormData()
  const { data: emissionSources } = useEmissionSources()
  const slidersRef = useRef<any>()
  const savedQuery = useSavedQuery()
  const [hasScrolledToSliders, setHasScrolledToSliders] = useState(false)
  const position = useBreakpointValue<CSS.Property.Position>({
    base: "unset",
    "2xl": "sticky",
  })

  const scrollToResult = () => {
    if (hasScrolledToSliders === false) {
      setTimeout(() => {
        slidersRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        })
        setHasScrolledToSliders(true)
      }, 500) // Use delay to make sure component is rendered
    }
  }

  useCalculation(formData, scrollToResult)

  const methods = useForm<TcoFormData>({
    defaultValues: {
      designData: getDefaultDesignData(),
      tcoCalculation: getDefaultTCOCalculationData(),
      co2Emission: getDefaultCo2EmissionData(),
      bondedPipes: [],
      flexPipes: [],
      complexityData: getDefaultComplexityData(),
    },
    shouldUnregister: false,
    mode: "onChange",
  })

  const { reset, watch, handleSubmit, getValues } = methods

  useEffect(() => {
    if (savedQuery) {
      setFormData(null)
      const formData = mapSavedQueryDataToTcoFormData(
        savedQuery,
        emissionSources
      )

      if (formData) reset(formData)
    }
  }, [savedQuery, reset, setFormData, emissionSources])

  const bondedPipes = watch("bondedPipes")
  const flexPipes = watch("flexPipes")
  const complex = formData?.complexityData

  useEffect(() => {
    // Clear form data if any changes except for slider values (energy and complexityData)
    const ignoredFields = [
      "complexityData.energyComplexity",
      "complexityData",
      "complexityData.installationComplexity",
      "complexityData.excavationComplexity",
      "complexityData.pipeSystemComplexity",
    ]

    const subscription = watch((_, { name }) => {
      if (name && !ignoredFields.includes(name.toString())) {
        setFormData(null)
      }
    })

    return () => subscription.unsubscribe()
  }, [watch, setFormData, complex, reset, getValues])

  const handleOnSubmit = handleSubmit((data: TcoFormData) => {
    if (
      (data.flexPipes?.length ?? 0) === 0 &&
      (data.bondedPipes?.length ?? 0) === 0
    ) {
      console.error("select at least one pipe")
    } else {
      TagManager.dataLayer({
        dataLayer: {
          gtmId: "GTM-MR49XPF",
          event: "calculate",
          bondedPipes: (data.bondedPipes?.length ?? 0) > 0,
          flexPipes: (data.flexPipes?.length ?? 0) > 0,
        },
      })
      setHasScrolledToSliders(false)
      setFormData(data)
    }
  })

  const onPressureTemperatureChangeCallback = useCallback(
    (pressureTemperature: PressureTemperature) => {
      setCurrentPressureAndTemperature(pressureTemperature)
    },
    [setCurrentPressureAndTemperature]
  )

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleOnSubmit}>
        <Box bg="brand.blue">
          <ContentWidth>
            <Flex marginTop={12} justifyContent={"space-between"}>
              <DesignData
                namePrefix={tcoFormDataKeys.designData + "."}
                onPressureTemperatureChange={
                  onPressureTemperatureChangeCallback
                }
              />
              <TCOCalculation
                namePrefix={tcoFormDataKeys.tcoCalculation + "."}
                savedQueryCurrency={
                  savedQuery?.queryData?.calculationData?.currencyCode ??
                  undefined
                }
              />
              <Co2Emission namePrefix={tcoFormDataKeys.co2Emission + "."} />
            </Flex>
          </ContentWidth>
        </Box>
        <ContentWidth>
          <VStack>
            <Box flexGrow={1} w="100%" mb="20">
              {currentPressureAndTemperature && (
                <TcoPipeSelection
                  pressureTemperature={currentPressureAndTemperature}
                />
              )}
            </Box>
            <Divider />
            <HStack
              w="100%"
              pos={position}
              pointerEvents="none"
              bottom="0"
              justifyContent="flex-end"
            >
              <Box pe="2">
                {/* TODO: these tooltips have ugly placement.
                    Fixing this requires significant changes to FavoriteButton
                 */}
                <Tooltip
                  hasArrow
                  placement="top"
                  label={intl.formatMessage({
                    id: "app.favorite-tooltip",
                    defaultMessage:
                      "Save the project – activated when the calculation is made",
                  })}
                >
                  <span>
                    <FavoriteButton
                      pointerEvents="auto"
                      isDisabled={formData == null}
                    />
                  </span>
                </Tooltip>
              </Box>
              <Box pe="8">
                <Tooltip
                  hasArrow
                  placement="top-start"
                  bg="brand.red"
                  isDisabled={bondedPipes?.length > 0 || flexPipes?.length > 0}
                  label={intl.formatMessage({
                    id: "form.calculate-tooltip-error",
                    defaultMessage:
                      "Please select one or more pipes to perform a calculation",
                  })}
                >
                  <span>
                    <CalculateButton
                      pointerEvents="auto"
                      isDisabled={
                        bondedPipes?.length === 0 && flexPipes?.length === 0
                      }
                    />
                  </span>
                </Tooltip>
              </Box>
            </HStack>
            <Divider />
          </VStack>
          {/*show sliders if there's data*/}
          {formData && (
            <>
              <Flex ref={slidersRef} mt={10} mb={10} justifyContent={"center"}>
                <TcoSliders onSubmit={(formData) => setFormData(formData)} />
              </Flex>
              <Divider />
            </>
          )}
        </ContentWidth>
      </form>
    </FormProvider>
  )
}
