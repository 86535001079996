import { Select } from "@chakra-ui/react"
import { useState } from "react"
import { useIntl } from "react-intl"
import { Pipe } from "../../../models/pipe"

type ControlledSelectProps = {
  options: Pipe[]
  selectedOptionIds: string[]
  onChange: (pipeId: string) => void
  value: string
  isReadOnly?: boolean
}

export const ControlledSelect: React.FC<ControlledSelectProps> = ({
  options,
  selectedOptionIds,
  onChange,
  value,
  isReadOnly = false,
}) => {
  const intl = useIntl()
  const [pipeId, setPipeId] = useState<string>(value)

  const availableOptions = options.filter((pipe) => {
    const idAlreadySelected = selectedOptionIds.find((selectedId) => {
      //Check if Select contains the selected id. If so, dont filter it out
      if (selectedId === pipe.pipeId) {
        return selectedId !== pipeId
      } else {
        return false
      }
    })
    return !idAlreadySelected
  })

  return (
    <Select
      placeholder={intl.formatMessage({
        id: "select-dimension",
        defaultMessage: "Select dimension",
      })}
      onChange={(event) => {
        const pipeId = event.target.value
        setPipeId(pipeId)
        onChange(pipeId)
      }}
      value={pipeId}
      isReadOnly={isReadOnly}
      pointerEvents={isReadOnly ? "none" : undefined}
    >
      {availableOptions.map((option) => {
        return (
          <option key={option.pipeId} value={option.pipeId}>
            {option.name}
          </option>
        )
      })}
    </Select>
  )
}
