import { Flex, Text } from "@chakra-ui/react"
import React, { useEffect } from "react"
import { TcoCalculationGroup } from "../../../models/calculation/tcoCalculationGroup"
import { EmissionGraph } from "./EmissionGraph/EmissionGraph"
import { FormattedMessage, useIntl } from "react-intl"
import TagManager, { DataLayerArgs } from "react-gtm-module"
import { getGraphTitle } from "../utils/getGraphTitle"

type EmissionGraphsProps = {
  tcoCalculationGroups: TcoCalculationGroup[]
}
export const EmissionGraphs: React.FC<EmissionGraphsProps> = ({
  tcoCalculationGroups,
}) => {
  const intl = useIntl()

  useEffect(() => {
    const dataLayerArgs: DataLayerArgs = {
      dataLayer: {
        gtmId: "GTM-MR49XPF",
        event: "showCo2Emission",
      },
    }

    TagManager.dataLayer(dataLayerArgs)
  }, [])

  return (
    <Flex direction={"column"}>
      <Flex paddingX={0} paddingY={4} direction={"row"}>
        <Text>
          <FormattedMessage
            id="app.emissionGraph.explanation"
            defaultMessage="Values are summarized across all years and compared to the pipe solution with the highest CO{lowerTwo} emission"
            description="Explanation for what emission graph is showing"
            values={{
              lowerTwo: "\u2082",
            }}
          />
        </Text>
      </Flex>

      {tcoCalculationGroups.map((group, index) => (
        <EmissionGraph
          key={index}
          title={getGraphTitle(
            intl,
            group.nominalDiameters,
            group.pipeSystemKind
          )}
          tcoCalculationItems={group.systems}
        />
      ))}
    </Flex>
  )
}
