import { Box, Heading, HStack, Text } from "@chakra-ui/react"
import { useIntl } from "react-intl"
import "../../theme/LogstorLoading.css"

export const LogstorLoading = () => {
  const intl = useIntl()
  return (
    <HStack spacing={"30px"}>
      <Heading pb={"30px"} color={"white"}>
        {intl.formatMessage({
          id: "LogstorLoading.loading",
          defaultMessage: "Loading",
        })}
      </Heading>
      <Text className="dot-pulse"></Text>
    </HStack>
  )
}

export const LogstorLoadingSmall = () => {
  return (
    <HStack paddingStart={"30px"}>
      <Text className="dot-pulse"></Text>
    </HStack>
  )
}

export interface LogstorLoadingOverlayProps {
  showOverlay: boolean
  children: React.ReactNode
}

export const LogstorLoadingOverlay: React.FC<LogstorLoadingOverlayProps> = ({
  children,
  showOverlay,
}) => {
  return (
    <Box className={showOverlay ? "overlay" : ""}>
      {showOverlay && (
        <Box className="overlayInner" width={"100%"}>
          <Box ps={"22.5%"}>
            <LogstorLoadingSmall />
          </Box>
        </Box>
      )}
      {children}
    </Box>
  )
}
