import { Box, Heading } from "@chakra-ui/react"
import { FormattedMessage } from "react-intl"

const ConfigureReportHeader = () => {
  return (
    <Box w="100%" h="200px" bg="brand.blue" overflow="hidden">
      <Heading mt="10" ms="10" textColor="white" size="lg">
        <FormattedMessage id="print-pdf" defaultMessage="Print/PDF" />
      </Heading>
    </Box>
  )
}

export default ConfigureReportHeader
