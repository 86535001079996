import { TcoCalculationItem } from "../../../../../models/calculation/tcoCalculationItem"
import { EChartsOption } from "echarts"
import { useEffect, useState } from "react"
import { ChartColor } from "../../../TcoGraphs/TcoBarChart/barChartColors"
import { useCompareSystemsCalculation } from "../../hooks/useCompareSystemsCalculation"
import { numberFormatter, toDecimals } from "../../../utils/decimalConverter"
import { asTooltipFormat } from "../../../TcoResult"
import { ManualPricesSystems } from "../../SelectSystem/hooks/useManualPrices"
import { useIntl } from "react-intl"

export const useCapexEchartsOption = (
  chosenSystemOne: TcoCalculationItem,
  chosenSystemTwo: TcoCalculationItem,
  manualPrices?: ManualPricesSystems | null
): EChartsOption | null => {
  const intl = useIntl()
  const [option, setOption] = useState<EChartsOption | null>(null)
  const data = useCompareSystemsCalculation({
    calculationItems: [chosenSystemOne, chosenSystemTwo],
    manualPrices,
  }).data?.data

  const systemOneCapex = data?.firstSystemData?.capex
  const systemTwoCapex = data?.secondSystemData?.capex

  useEffect(() => {
    const option: EChartsOption = {
      legend: {
        top: "bottom",
      },
      tooltip: {
        trigger: "item",
        formatter: (p) =>
          asTooltipFormat(p).name +
          ": <b>" +
          numberFormatter.format(asTooltipFormat(p).value as number) +
          " %</b>",
      },
      textStyle: {
        fontFamily: "Lato",
      },
      series: [
        {
          type: "pie",
          label: {
            formatter: "{c} %",
            position: "inside",
          },
          center: ["30%", "50%"],
          radius: 60,
          data: [
            {
              value: toDecimals(systemOneCapex?.materialPercent || 0, 2),
              name: intl.formatMessage({
                id: "pipe-material",
                defaultMessage: "Pipe material",
              }),
              itemStyle: {
                color: ChartColor.Blue,
              },
              label: {
                position: "inside",
              },
            },
            {
              value: toDecimals(systemOneCapex?.excavationPercent || 0, 2),
              name: intl.formatMessage({
                id: "trench-excavation",
                defaultMessage: "Trench excavation",
              }),
              itemStyle: {
                color: ChartColor.DarkGrey,
              },
              label: {
                position: "inside",
              },
            },
            {
              value: toDecimals(systemOneCapex?.installationPercent || 0, 2),
              name: intl.formatMessage({
                id: "pipe-installation",
                defaultMessage: "Pipe installation",
              }),
              itemStyle: {
                color: ChartColor.Yellow,
              },
              label: {
                position: "inside",
              },
            },
          ],
        },
        {
          type: "pie",
          label: {
            formatter: "{c}%",
            position: "inside",
          },
          center: ["70%", "50%"],
          radius: 60,
          data: [
            {
              value: toDecimals(systemTwoCapex?.materialPercent || 0, 2),
              name: intl.formatMessage({
                id: "pipe-material",
                defaultMessage: "Pipe material",
              }),
              itemStyle: {
                color: ChartColor.Blue,
              },
              label: {
                position: "inside",
              },
            },
            {
              value: toDecimals(systemTwoCapex?.excavationPercent || 0, 2),
              name: intl.formatMessage({
                id: "trench-excavation",
                defaultMessage: "Trench excavation",
              }),
              itemStyle: {
                color: ChartColor.DarkGrey,
              },
              label: {
                position: "inside",
              },
            },
            {
              value: toDecimals(systemTwoCapex?.installationPercent || 0, 2),
              name: intl.formatMessage({
                id: "pipe-installation",
                defaultMessage: "Pipe installation",
              }),
              itemStyle: {
                color: ChartColor.Yellow,
              },
              label: {
                position: "inside",
              },
            },
          ],
        },
      ],
    }

    setOption(option)
  }, [systemOneCapex, systemTwoCapex, intl])
  return option
}
