import { CalculationResult } from "./../models/calculation/calculationResult"
import { PipeControlData, TcoFormData } from "../components/TcoForm/TcoForm"
import {
  CalculationApi,
  CalculationData,
  ComplexityData,
  CurrencyCodeKindDto,
  DesignData,
  EmissionData,
  EmissionSourceKindDto,
  PerformCalculationQuery,
  PipeSystemDiameterByTrenchDistanceInput,
  QueryData,
  UnitKindDto,
} from "./openapi"
import { QueryObserverResult, useQuery } from "react-query"
import { DesignDataControlsData } from "../components/TcoForm/TcoDataInput/DesignData/DesignData"
import { TCOCalculationControlsData } from "../components/TcoForm/TcoDataInput/TCOCalculation/TCOCalculation"
import { Co2EmissionControlsData } from "../components/TcoForm/TcoDataInput/Co2Emission/Co2Emission"
import { EmissionKind } from "../models/emissionSource"
import { mapToCalculationResult } from "./mappers/calculationResult.mapper"
import { ComplexityDataControlsData } from "../components/Sliders/InvestmentSliders"
import { useAuth } from "../keycloak"
import { BaseConfiguration } from "./BaseConfiguration"

export const useCalculation = (
  tcoFormData: TcoFormData | null,
  onSuccess?: () => void
): QueryObserverResult<CalculationResult, Response> => {
  const { token } = useAuth()
  const fetchCalculationData = async (): Promise<CalculationResult> => {
    if (tcoFormData == null || tcoFormData.designData.tFlowSummer == null) {
      return { tcoCalculationGroups: [] }
    }

    const requestDto = mapToRequestDto(tcoFormData)
    const calculationApi = new CalculationApi(new BaseConfiguration(token))
    const response = await calculationApi.calculationPost(requestDto)
    return mapToCalculationResult(response.data)
  }

  return useQuery(["calculationQueryKey", tcoFormData], fetchCalculationData, {
    keepPreviousData: true,
    onSuccess: onSuccess,
  })
}

const mapToRequestDto = (tcoFormData: TcoFormData): PerformCalculationQuery => {
  return mapToCalculationQueryDto(tcoFormData)
}

export const mapToCalculationQueryDto = (
  tcoFormData: TcoFormData
): PerformCalculationQuery => {
  return {
    designData: mapToDesignDataDto(tcoFormData.designData),
    calculationData: mapToCalculationDataDto(tcoFormData.tcoCalculation),
    emissionData: mapToEmissionDataDto(tcoFormData.co2Emission),
    pipeSystemDiameterByTrenchDistance: mapToPipeSystemDiameterByTrenchDistance(
      [...(tcoFormData.bondedPipes || []), ...(tcoFormData.flexPipes || [])]
    ),
    complexityData: mapToComplexityData(tcoFormData.complexityData),
  }
}

export const mapToSavedQueryDto = (tcoFormData: TcoFormData): QueryData => {
  return {
    designData: mapToDesignDataDto(tcoFormData.designData),
    calculationData: {
      ...mapToCalculationDataDto(tcoFormData.tcoCalculation),
      energyPrice: convertToNumber(tcoFormData.tcoCalculation.priceEnergy),
    },
    emissionData: mapToEmissionDataDto(tcoFormData.co2Emission),
    bondedPipeSystemsPipeSystemDiameterByTrenchDistance:
      mapToPipeSystemDiameterByTrenchDistance([
        ...(tcoFormData.bondedPipes || []),
      ]),
    flexPipeSystemsPipeSystemDiameterByTrenchDistance:
      mapToPipeSystemDiameterByTrenchDistance([
        ...(tcoFormData.flexPipes || []),
      ]),
    complexityData: mapToComplexityData(tcoFormData.complexityData),
  }
}

export const mapToPipeSystemDiameterByTrenchDistance = (
  pipes: PipeControlData[]
): PipeSystemDiameterByTrenchDistanceInput[] => {
  return pipes.map((p) => {
    return {
      pipeSystemDiameterId: p.pipeId,
      trenchDistance: convertToNumber(p.meters),
    }
  })
}

export const mapToEmissionDataDto = (
  emission: Co2EmissionControlsData
): EmissionData => {
  let emissionSourceInCalculation = undefined
  if (emission.numberOfYearsTCO && emission.co2QuotePrice) {
    emissionSourceInCalculation = {
      emissionPeriod: convertToNumber(emission.numberOfYearsTCO),
      emissionQuotePrice: convertToNumber(emission.co2QuotePrice),
    }
  }

  const emissionSourceKindDto = mapToEmissionSourceKindDto(emission.fuelType!)

  return {
    emissionSourceKind: emissionSourceKindDto,
    customEmissionValue:
      emissionSourceKindDto === EmissionSourceKindDto.Custom
        ? convertToNumber(emission.tonCo2)
        : null,
    emissionSourceInCalculation: emissionSourceInCalculation,
  }
}

export const mapToCalculationDataDto = (
  calc: TCOCalculationControlsData
): CalculationData => {
  return {
    currencyCode: mapToCurrencyCodeDto(calc.currency),
    currencyExchangeRate: convertToNumber(calc.exchangeRate),
    interestRate: convertToNumber(calc.interest),
    calculationPeriod: convertToNumber(calc.periodTcoCalc),
    energyUnitKind: mapToEnergyUnitDto(calc.energyUnit),
    energyPrice: convertToNumber(calc.priceEnergy),
    yearlyRiseInEnergyPrice: convertToNumber(calc.yearlyRiseInEnergyPrice),
  }
}

const mapToEmissionSourceKindDto = (
  fuelType: string
): EmissionSourceKindDto => {
  const emissionKind: EmissionKind =
    EmissionKind[fuelType as unknown as keyof typeof EmissionKind]
  switch (emissionKind) {
    case EmissionKind.NaturalGas:
      return EmissionSourceKindDto.NaturalGas
    case EmissionKind.GasOil:
      return EmissionSourceKindDto.GasOil
    case EmissionKind.FuelOil:
      return EmissionSourceKindDto.FuelOil
    case EmissionKind.Coal:
      return EmissionSourceKindDto.Coal
    case EmissionKind.Waste:
      return EmissionSourceKindDto.Waste
    case EmissionKind.CO2Neutral:
      return EmissionSourceKindDto.Co2Neutral
    case EmissionKind.Custom:
      return EmissionSourceKindDto.Custom
    default:
      throw Error(
        `mapToEmissionSourceKindDto received invalid fuelType: ${fuelType}`
      )
  }
}

const mapToCurrencyCodeDto = (currencyCode: string): CurrencyCodeKindDto => {
  const code =
    currencyCode[0]?.toUpperCase() + currencyCode.toLowerCase().slice(1) // Make same casing as enum cases
  return CurrencyCodeKindDto[code as keyof typeof CurrencyCodeKindDto]
}

const mapToEnergyUnitDto = (energyUnit: string): UnitKindDto => {
  switch (energyUnit) {
    case "MWh":
      return UnitKindDto.Mwh
    case "GJ":
      return UnitKindDto.Gj
    default:
      throw Error(`mapToEnergyUnitDto received wrong energyUnit: ${energyUnit}`)
  }
}

export const mapToDesignDataDto = (
  designData: DesignDataControlsData
): DesignData => {
  return {
    temperatureFlowWinter: convertToNumber(designData.tFlowWinter),
    temperatureFlowSummer: convertToNumber(designData.tFlowSummer),
    temperatureReturnWinter: convertToNumber(designData.tReturnWinter),
    temperatureReturnSummer: convertToNumber(designData.tReturnSummer),
    temperatureSoilWinter: convertToNumber(designData.tSoilWinter),
    temperatureSoilSummer: convertToNumber(designData.tSoilSummer),
    operationPeriodWinter: convertToNumber(designData.daysInOperationWinter),
    operationPeriodSummer: convertToNumber(designData.daysInOperationSummer),
    pressure: convertToNumber(designData.pressure),
    soilCover: convertToNumber(designData.soilCover),
    lambdaSoil: convertToNumber(designData.lambdaSoil),
  }
}

const convertToNumber = (value: string): number => {
  return Number(value)
}

export const mapToComplexityData = (
  complexityData: ComplexityDataControlsData | undefined
): ComplexityData => {
  return {
    installationComplexity: complexityData?.installationComplexity || 1,
    excavationComplexity: complexityData?.excavationComplexity || 1,
    pipeSystemComplexity: complexityData?.pipeSystemComplexity || 1,
    energyPriceComplexity: complexityData?.energyComplexity || 1,
  }
}
