import { Heading, HStack, Text } from "@chakra-ui/react"
import { ReactComponent as Logo } from "../../assets/logo.svg"

export const ReportFooter = () => {
  return (
    <HStack
      ps="48px"
      spacing="16px"
      w="100%"
      bg="brand.blue"
      h="34px"
      pos="fixed"
      bottom="0"
    >
      <Logo />
      <Text color="white" opacity="30%">
        |
      </Text>
      <Heading
        as="h1"
        fontStyle="normal"
        fontWeight="300"
        fontSize="20px"
        color="white"
      >
        TCO – Total Cost of Ownership
      </Heading>
    </HStack>
  )
}
