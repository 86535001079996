import { useLocation } from "react-router-dom"
import { useFavorite } from "../rest/useFavorite"

export const useSavedQuery = () => {
  const { search } = useLocation()
  const urlParams = new URLSearchParams(search)
  const id = urlParams.get("savedQueryId")

  const { data: favorite } = useFavorite(id != null, id)

  return favorite?.data
}
