import { Box, Text, Center, HStack, Spacer } from "@chakra-ui/react"
import { ReactComponent as Logo } from "../../src/assets/kingspan_logo.svg"

export const FooterBox = () => {
  return (
    <Box mt="auto">
      <Footer />
    </Box>
  )
}

export const Footer = () => {
  return (
    <Box as="footer" w="100%" backgroundColor="#004289" h="125px">
      <Center h="100%">
        <Logo width="110px" height="100%" />
      </Center>
      <HStack mt="-12" me="20">
        <Spacer />
        <Text as="b" textColor="white">
          We are a Kingspan company
        </Text>
      </HStack>
    </Box>
  )
}
