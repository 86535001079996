import Keycloak from "keycloak-js"

const keycloakBaseUrl =
  process.env.REACT_APP_KEYCLOAK_BASE_URL || "https://auth.logstor.com"
const keycloak = Keycloak({
  url: keycloakBaseUrl + "/auth/",
  clientId: "tco",
  realm: "TCO",
})

export { keycloak }
