import {
  Box,
  Heading,
  HStack,
  IconButton,
  Link,
  Progress,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react"
import { FaRegStar } from "react-icons/fa"
import { FormattedDate, FormattedMessage, useIntl } from "react-intl"
import { SavedQueryDto } from "../../rest/openapi"
import { useFavorites } from "../../rest/useFavorites"
import { Link as RouterLink } from "react-router-dom"
import { BackLink } from "../shared/BackLink"
import { DeleteIcon } from "@chakra-ui/icons"
import { useDeleteFavorite } from "../../rest/useDeleteFavorite"
import { useQueryClient } from "react-query"

const Header = () => {
  return (
    <HStack mb="8" mt="8">
      <FaRegStar size="16px" />
      <Heading as="h2" fontSize="16px">
        <FormattedMessage
          id="favorites.header"
          defaultMessage="Favourite calculations"
        />
      </Heading>
    </HStack>
  )
}

export const FavoriteTable = () => {
  const { data, isLoading } = useFavorites()

  if (isLoading) {
    return <Progress w="100%" size="xs" isIndeterminate />
  }

  return (
    <Box ms="8" me="8" mt="2">
      <BackLink />
      <Header />
      <Table>
        <Thead>
          <Tr>
            <Th textTransform="capitalize" textColor="#838A95">
              <FormattedMessage id="favorites.title" defaultMessage="Title" />
            </Th>
            <Th textTransform="capitalize" textColor="#838A95">
              <FormattedMessage id="favorites.date" defaultMessage="Date" />
            </Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {data?.data.map((d) => (
            <FavoriteRow key={d.id} favorite={d} />
          ))}
        </Tbody>
      </Table>
    </Box>
  )
}

interface FavoriteRowProps {
  favorite: SavedQueryDto
}

const FavoriteRow = ({ favorite }: FavoriteRowProps) => {
  const queryClient = useQueryClient()
  const { mutate, isLoading } = useDeleteFavorite()
  const intl = useIntl()

  const deleteQuery = () => {
    if (favorite.id == null) return
    mutate(favorite.id, {
      onSuccess: () => queryClient.invalidateQueries("useFavoritesQuery"),
    })
  }

  return (
    <Tr>
      <Td>
        <Link
          as={RouterLink}
          to={{ pathname: "/", search: `?savedQueryId=${favorite.id}` }}
        >
          {favorite.name}
        </Link>
      </Td>
      <Td>
        <FormattedDate
          value={favorite.timestamp}
          day="numeric"
          month="long"
          year="numeric"
        />
      </Td>
      <Td textAlign="end">
        <Tooltip
          label={intl.formatMessage({
            id: "favorites.delete",
            defaultMessage: "Delete",
          })}
        >
          <IconButton
            size="sm"
            aria-label={intl.formatMessage({
              id: "favorites.delete",
              defaultMessage: "Delete",
            })}
            icon={<DeleteIcon />}
            onClick={deleteQuery}
            isLoading={isLoading}
          />
        </Tooltip>
      </Td>
    </Tr>
  )
}
