import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Checkbox,
  CheckboxGroup,
  Divider,
  Heading,
  Input,
  InputGroup,
  Progress,
  SimpleGrid,
  Textarea,
  VStack,
} from "@chakra-ui/react"
import { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useNavigate, useLocation } from "react-router"
import ConfigureReportHeader from "../../components/Report/ConfigureReportHeader"
import { useUploadLogo } from "../../components/Report/hooks/useUploadLogo"
import { BrandedButton } from "../../components/shared/BrandedButton"
import { ReportSelectedPipes } from "../../components/TcoResult/CompareSystemGraphs/CompareSystemGraphs"

export type ReportConfig = {
  companyLogo: boolean
  headerSubtitle: boolean
  subtitleValue: string | undefined
}

export type ReportPipesAndConfig = {
  config: ReportConfig
  pipes: ReportSelectedPipes
}

const ConfigureReport = () => {
  const intl = useIntl()
  const { state } = useLocation()
  const navigate = useNavigate()
  const { mutate: mutateLogo, isLoading } = useUploadLogo()
  const [config, setConfig] = useState<ReportConfig>({
    companyLogo: false,
    headerSubtitle: false,
    subtitleValue: undefined,
  })

  const pipes = state as ReportSelectedPipes | undefined

  if (pipes == null) {
    navigate("/")
    return null
  }

  const uploadLogo = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (files == null) {
      return
    }

    const logo = files[0]
    mutateLogo(logo)
  }

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfig({ ...config, [e.target.name]: e.target.checked })
  }

  return (
    <>
      <ConfigureReportHeader />
      <Center as="main" mt="10" mb="10">
        <VStack spacing="50">
          <SimpleGrid columns={1} spacing="150">
            <Box>
              <Heading mb="5" as="h2" size="lg">
                <FormattedMessage
                  id="report.page-header-setup"
                  defaultMessage="Page &amp; Header set-up"
                />
              </Heading>
              <Divider mb={5} />
              <CheckboxGroup>
                <VStack alignItems="start">
                  <Checkbox
                    hidden
                    isDisabled
                    name="companyLogo"
                    onChange={(e) =>
                      setConfig((prevValue) => ({
                        ...prevValue,
                        companyLogo: e.target.checked,
                      }))
                    }
                    isChecked={config.companyLogo}
                  >
                    <FormattedMessage
                      id="report.company-logo"
                      defaultMessage="Company logo"
                    />
                  </Checkbox>
                  <InputGroup hidden>
                    {isLoading ? (
                      <Progress w="100%" size="xs" isIndeterminate />
                    ) : (
                      <Input
                        isDisabled={!config.companyLogo}
                        type="file"
                        name="logo"
                        accept="image/*"
                        multiple={false}
                        onChange={uploadLogo}
                      />
                    )}
                  </InputGroup>
                  <Checkbox
                    name="headerSubtitle"
                    onChange={changeHandler}
                    isChecked={config.headerSubtitle}
                  >
                    <FormattedMessage
                      id="report.subtitle"
                      defaultMessage="Subtitle"
                    />
                  </Checkbox>
                  <InputGroup>
                    <Textarea
                      name="subtitleValue"
                      placeholder={intl.formatMessage({
                        id: "report.subtitle.placeholder",
                        defaultMessage: "Subtitle",
                      })}
                      value={config.subtitleValue}
                      onChange={(e) =>
                        setConfig({
                          ...config,
                          [e.target.name]: e.target.value,
                        })
                      }
                      isDisabled={!config.headerSubtitle}
                    />
                  </InputGroup>
                </VStack>
              </CheckboxGroup>
            </Box>
          </SimpleGrid>
          <ButtonGroup>
            <BrandedButton
              primary
              onClick={() =>
                navigate(
                  {
                    pathname: "/report/preview",
                  },
                  {
                    state: { config, pipes },
                  }
                )
              }
            >
              <FormattedMessage
                id="report-generate-report"
                defaultMessage="Generate report"
              />
            </BrandedButton>
            <Button onClick={() => navigate(-1)}>
              <FormattedMessage id="cancel" defaultMessage="Cancel" />
            </Button>
          </ButtonGroup>
        </VStack>
      </Center>
    </>
  )
}

export default ConfigureReport
