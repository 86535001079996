import { useMutation } from "react-query"
import { TcoFormData } from "../components/TcoForm/TcoForm"
import { useAuth } from "../keycloak"
import { BaseConfiguration } from "./BaseConfiguration"
import { CalculationApi } from "./openapi"
import { mapToSavedQueryDto } from "./useCalculation"

interface Props {
  name: string
  formData: TcoFormData
  id?: string
}

export const useSaveFavorite = (update: boolean) => {
  const { token } = useAuth()

  return useMutation(
    ({ name, formData, id }: Props) => {
      const api = new CalculationApi(new BaseConfiguration(token))
      if (update) {
        return api.calculationUpdateSavedQuery({
          queryData: mapToSavedQueryDto(formData),
          savedQueryId: id,
        })
      } else {
        return api.calculationSaveQuery({
          name,
          queryData: mapToSavedQueryDto(formData),
        })
      }
    },
    { retry: 3 }
  )
}
