import { useState } from "react"
import { TcoCalculationGroup } from "../../../../../models/calculation/tcoCalculationGroup"

export const ValidManualPriceOrNull = (
  prices: ManualPrices | null | undefined
) => {
  return prices?.pipeInstallation != null &&
    prices?.trench != null &&
    prices?.pipeMaterial != null
    ? prices
    : null
}

export type ManualPrices = {
  pipeInstallation: number | undefined
  trench: number | undefined
  pipeMaterial: number | undefined
}

export type ManualPricesSystems = {
  systemOne: ManualPrices | null
  systemTwo: ManualPrices | null
}

export const useManualPrices = (groups: TcoCalculationGroup[]) => {
  return useState<ManualPricesSystems[]>(
    groups.map((g) => ({ systemOne: null, systemTwo: null }))
  )
}
