import React from "react"
import { Box, Flex, HStack } from "@chakra-ui/react"
import { UnitPricesMeterTrenchGraph } from "./UnitPricesMeterTrenchGraph/UnitPricesMeterTrenchGraph"
import ReactECharts from "echarts-for-react"
import { EChartsOption } from "echarts"
import { ChartColor } from "../../TcoGraphs/TcoBarChart/barChartColors"
import { useIntl } from "react-intl"
import { GraphHeading } from "../../TcoResult"
import { CompareItem } from "../CompareSystemGraphs"
import { getGraphTitle } from "../../utils/getGraphTitle"
import { TcoCalculationGroup } from "../../../../models/calculation/tcoCalculationGroup"
import { ManualPricesSystems } from "../SelectSystem/hooks/useManualPrices"

type UnitPricesMeterTrenchGraphsProps = {
  tcoCalculationGroups: TcoCalculationGroup[]
  pipes: Array<CompareItem | null>
  manualPrices: ManualPricesSystems[]
  chartWidth?: string
}

export const UnitPricesMeterTrenchGraphs: React.FC<
  UnitPricesMeterTrenchGraphsProps
> = ({ tcoCalculationGroups, pipes, manualPrices, chartWidth }) => {
  const intl = useIntl()

  // Only need to show legends for unit prices/meter trench graph
  const option: EChartsOption = {
    tooltip: {},
    legend: {
      selectedMode: false,
      data: [
        intl.formatMessage({
          id: "pipe-material",
          defaultMessage: "Pipe material",
        }),
        intl.formatMessage({
          id: "trench-excavation",
          defaultMessage: "Trench excavation",
        }),
        intl.formatMessage({
          id: "pipe-installation",
          defaultMessage: "Pipe installation",
        }),
      ],
    },
    xAxis: [
      {
        type: "category",
        data: [],
        axisLine: {
          show: false,
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        axisLabel: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        position: "right",
      },
    ],
    series: [
      {
        name: intl.formatMessage({
          id: "pipe-material",
          defaultMessage: "Pipe material",
        }),
        type: "bar",
        stack: "1",
        color: ChartColor.Blue,
        data: [],
      },
      {
        name: intl.formatMessage({
          id: "trench-excavation",
          defaultMessage: "Trench excavation",
        }),
        stack: "1",
        type: "bar",
        color: ChartColor.DarkGrey,
        data: [],
      },
      {
        name: intl.formatMessage({
          id: "pipe-installation",
          defaultMessage: "Pipe installation",
        }),
        stack: "1",
        type: "bar",
        color: ChartColor.Yellow,
        data: [],
      },
    ],
  }

  return (
    <Flex flexDirection="column" alignItems="center">
      {pipes.map(
        (pipeSystem, index) =>
          pipeSystem && (
            <React.Fragment key={index}>
              <GraphHeading>
                {getGraphTitle(
                  intl,
                  tcoCalculationGroups[index].nominalDiameters,
                  tcoCalculationGroups[index].pipeSystemKind
                )}
              </GraphHeading>
              <Box>
                <HStack flexDirection="row" spacing={10}>
                  <UnitPricesMeterTrenchGraph
                    tcoCalculationItem={pipeSystem.systemOne}
                    calculationItems={[
                      pipeSystem.systemOne,
                      pipeSystem.systemTwo,
                    ]}
                    system="First"
                    manualPrices={manualPrices[index]}
                  />
                  <UnitPricesMeterTrenchGraph
                    tcoCalculationItem={pipeSystem.systemTwo}
                    calculationItems={[
                      pipeSystem.systemOne,
                      pipeSystem.systemTwo,
                    ]}
                    system="Second"
                    manualPrices={manualPrices[index]}
                  />
                </HStack>
                <Box w={"100%"} mt={8}>
                  <ReactECharts
                    option={option}
                    style={{ height: "100%", width: chartWidth || "100%" }}
                    opts={{ renderer: "svg" }}
                  />
                </Box>
              </Box>
            </React.Fragment>
          )
      )}
    </Flex>
  )
}
