import {
  Controller,
  FieldValues,
  UseFieldArrayReturn,
  useFormContext,
  useFormState,
  useWatch,
} from "react-hook-form"
import React from "react"
import { TcoFormData } from "../../../TcoForm"
import {
  Box,
  Button,
  Flex,
  FormControl,
  IconButton,
  Input,
} from "@chakra-ui/react"
import { ControlledSelect } from "../../ControlledSelect"
import { AddIcon, DeleteIcon } from "@chakra-ui/icons"
import { FormattedMessage, useIntl } from "react-intl"
import { Pipe } from "../../../../../models/pipe"

export type FlexPipeControlProps = {
  pipes: Pipe[]
  fieldArrayMethods: UseFieldArrayReturn<FieldValues, "flexPipes", string>
  printMode?: boolean
}

export const FlexPipeControl: React.FC<FlexPipeControlProps> = ({
  pipes,
  fieldArrayMethods,
  printMode = false,
}) => {
  const intl = useIntl()
  const { register, control } = useFormContext<TcoFormData>()
  const { fields, append, remove } = fieldArrayMethods
  const { errors } = useFormState<TcoFormData>()
  const values = useWatch({ control, name: "flexPipes" })

  return (
    <Flex flexDirection="column" width={425}>
      {fields.map((item, index) => {
        return (
          <Flex
            mt={2}
            flexDirection="row"
            justifyContent="space-between"
            key={item.id}
          >
            <FormControl
              width="150px"
              isInvalid={errors?.flexPipes?.[index]?.meters != null}
              isReadOnly={printMode}
            >
              <Input
                textAlign="center"
                placeholder={intl.formatMessage({
                  id: "flex-pipes.metre-trench",
                  defaultMessage: "Metre trench",
                })}
                defaultValue={item.meters}
                type="number"
                {...register(`flexPipes.${index}.meters` as any, {
                  required: true,
                  min: 1,
                })}
                isReadOnly={printMode}
              />
            </FormControl>

            <Box width="200px">
              <Controller
                name={`flexPipes.${index}.pipeId` as any}
                control={control}
                defaultValue={item.pipeId}
                rules={{ required: true }}
                render={({ field }) => (
                  <FormControl
                    isInvalid={errors?.flexPipes?.[index]?.pipeId != null}
                    isReadOnly={printMode}
                  >
                    <ControlledSelect
                      options={pipes}
                      selectedOptionIds={values.map((p) => p.pipeId)}
                      onChange={(selectedPipeId: string) =>
                        field.onChange(selectedPipeId)
                      }
                      value={field.value}
                      isReadOnly={printMode}
                    />
                  </FormControl>
                )}
              />
            </Box>

            {!printMode && (
              <IconButton
                type="button"
                borderRadius="md"
                onClick={() => remove(index)}
                aria-label="delete pipe"
                icon={<DeleteIcon />}
              />
            )}
          </Flex>
        )
      })}

      {!printMode && (
        <Button
          mt={2}
          width={150}
          type="button"
          leftIcon={<AddIcon />}
          onClick={() => append({ meters: "" })}
        >
          <FormattedMessage
            id="app.addPipeRow"
            defaultMessage="Add"
            description="Add pipe to include in TCO calculation"
          />
        </Button>
      )}
    </Flex>
  )
}
