import {
  CalculationApi,
  EmissionSourceDto,
  EmissionSourceKindDto,
} from "./openapi"
import { useQuery } from "react-query"
import { EmissionKind, EmissionSource } from "../models/emissionSource"
import { useAuth } from "../keycloak"
import { BaseConfiguration } from "./BaseConfiguration"

export const useEmissionSources = () => {
  const { token } = useAuth()
  const fetchEmissionSources = (): Promise<EmissionSource[]> => {
    const calculationApi = new CalculationApi(new BaseConfiguration(token))
    return calculationApi.calculationGetEmissionSources().then((dtos) => {
      const domainModels = dtos.data.map(mapFromDto)
      domainModels.push({
        id: "Custom",
        emissionKind: EmissionKind.Custom,
        value: "0",
      })
      return domainModels
    })
  }

  return useQuery("useEmissionSourcesQueryKey", fetchEmissionSources)
}

const mapToEmission = (
  emissionSourceKindDto: EmissionSourceKindDto
): EmissionKind => {
  switch (emissionSourceKindDto) {
    case EmissionSourceKindDto.NaturalGas:
      return EmissionKind.NaturalGas
    case EmissionSourceKindDto.GasOil:
      return EmissionKind.GasOil
    case EmissionSourceKindDto.FuelOil:
      return EmissionKind.FuelOil
    case EmissionSourceKindDto.Coal:
      return EmissionKind.Coal
    case EmissionSourceKindDto.Waste:
      return EmissionKind.Waste
    case EmissionSourceKindDto.Co2Neutral:
      return EmissionKind.CO2Neutral
    case EmissionSourceKindDto.Custom:
      return EmissionKind.Custom
    default:
      console.error("Emission kind defaulted to None")
      return EmissionKind.None
  }
}

const mapFromDto = (dto: EmissionSourceDto): EmissionSource => ({
  id: dto.id!, // TODO: fix ! when DTO's are not nullable
  emissionKind: mapToEmission(dto.kind!), // TODO: fix ! when DTO's are not nullable
  value: dto.value!.toString(), // TODO: fix ! when DTO's are not nullable
})
