import { defineMessages } from "react-intl"

export enum EmissionKind {
  None = "None",
  NaturalGas = "NaturalGas",
  GasOil = "GasOil",
  FuelOil = "FuelOil",
  Coal = "Coal",
  Waste = "Waste",
  CO2Neutral = "CO2Neutral",
  Custom = "Custom",
}

export type EmissionSource = {
  id: string
  emissionKind: EmissionKind
  value: string
}

export const emissionKindMessages = defineMessages({
  None: {
    id: "emission_kind.none",
    defaultMessage: "None",
  },
  NaturalGas: {
    id: "emission_kind.natural_gas",
    defaultMessage: "Natural Gas",
  },
  GasOil: {
    id: "emission_kind.gas_oil",
    defaultMessage: "Gas Oil",
  },
  FuelOil: {
    id: "emission_kind.fuel_oil",
    defaultMessage: "Fuel Oil",
  },
  Coal: {
    id: "emission_kind.coal",
    defaultMessage: "Coal",
  },
  Waste: {
    id: "emission_kind.waste",
    defaultMessage: "Waste",
  },
  CO2Neutral: {
    id: "emission_kind.co2_neutral",
    defaultMessage: "CO2 Neutral",
  },
  Custom: {
    id: "emission_kind.custom",
    defaultMessage: "Custom",
  },
})
