import { Flex } from "@chakra-ui/react"
import { Slider } from "./Slider"
import React from "react"
import { Controller, useFormContext, useWatch } from "react-hook-form"
import { TcoFormData } from "../TcoForm/TcoForm"
import {
  numberFormatter,
  toDecimals,
} from "../TcoResult/utils/decimalConverter"
import { useIntl } from "react-intl"

export type ComplexityDataControlsData = {
  installationComplexity: number
  excavationComplexity: number
  pipeSystemComplexity: number
  energyComplexity: number
}

export const getDefaultComplexityData = (): ComplexityDataControlsData => ({
  installationComplexity: 1,
  excavationComplexity: 1,
  pipeSystemComplexity: 1,
  energyComplexity: 1,
})

type InvestmentSlidersProps = {
  onSliderChange: () => void
  printMode?: boolean
}

export const InvestmentSliders: React.FC<InvestmentSlidersProps> = ({
  onSliderChange,
  printMode = false,
}) => {
  const { control } = useFormContext<TcoFormData>()
  const intl = useIntl()

  const energyPrice = useWatch({ control, name: "tcoCalculation.priceEnergy" })
  const currency = useWatch({ control, name: "tcoCalculation.currency" })

  return (
    <Flex
      justifyContent="space-between"
      paddingY={4}
      paddingX={8}
      borderRadius={2}
    >
      <Controller
        control={control}
        name="complexityData.pipeSystemComplexity"
        render={({ field }) => (
          <Slider
            defaultValue={field.value}
            onChangeEnd={(val) => {
              field.onChange(val) // Update form instance
              onSliderChange() // trigger submit
            }}
            min={0.5}
            max={1.5}
            step={0.05}
            infoPopupContent={
              !printMode &&
              intl.formatMessage({
                id: "sliders.pipe-complexity.info",
                defaultMessage:
                  "This slider includes the costs for pipes, fittings and joints, which are updated based on the LOGSTOR global price list. The slider is typically placed lower for transmission lines and higher for distribution lines, as more fittings and joints are included in distribution lines.",
              })
            }
            title={intl.formatMessage({
              id: "sliders.pipe-complexity",
              defaultMessage: "Pipe material (complexity)",
            })}
            minTitle={intl.formatMessage({
              id: "sliders.easy",
              defaultMessage: "Easy",
            })}
            maxTitle={intl.formatMessage({
              id: "sliders.complicated",
              defaultMessage: "Complicated",
            })}
            isReadOnly={printMode}
          />
        )}
      />

      <Controller
        control={control}
        name="complexityData.excavationComplexity"
        render={({ field }) => (
          <Slider
            defaultValue={field.value}
            onChangeEnd={(val) => {
              field.onChange(val) // Update form instance
              onSliderChange() // trigger submit
            }}
            min={0.4}
            max={5}
            step={0.2}
            infoPopupContent={
              !printMode &&
              intl.formatMessage({
                id: "sliders.trench-excavation.info",
                defaultMessage:
                  "This slider considers factors such as obstacles in relation to other lines in the ground, the number of branch lines, etc. Costs are typically lower in greenfield projects and higher in cities.",
              })
            }
            title={intl.formatMessage({
              id: "sliders.trench-excavation",
              defaultMessage: "Trench excavation",
            })}
            minTitle={intl.formatMessage({
              id: "sliders.easy",
              defaultMessage: "Easy",
            })}
            maxTitle={intl.formatMessage({
              id: "sliders.complicated",
              defaultMessage: "Complicated",
            })}
            isReadOnly={printMode}
          />
        )}
      />

      <Controller
        control={control}
        name="complexityData.installationComplexity"
        render={({ field }) => (
          <Slider
            defaultValue={field.value}
            onChangeEnd={(val) => {
              field.onChange(val) // Update form instance
              onSliderChange() // trigger submit
            }}
            min={0.4}
            max={5}
            step={0.2}
            infoPopupContent={
              !printMode &&
              intl.formatMessage({
                id: "sliders.pipe-installation.info",
                defaultMessage:
                  "This slider includes factors such as pipe handling, welding and joint work. Costs tend to be lower for transmission lines and higher for distribution lines since more fittings and joints are included in distribution lines.",
              })
            }
            title={intl.formatMessage({
              id: "tco.chart.pipe-installation",
              defaultMessage: "Pipe installation",
            })}
            minTitle={intl.formatMessage({
              id: "sliders.easy",
              defaultMessage: "Easy",
            })}
            maxTitle={intl.formatMessage({
              id: "sliders.complicated",
              defaultMessage: "Complicated",
            })}
            isReadOnly={printMode}
          />
        )}
      />

      <Controller
        control={control}
        name="complexityData.energyComplexity"
        render={({ field }) => (
          <Slider
            defaultValue={field.value}
            onChangeEnd={(val) => {
              field.onChange(val) // Update form instance
              onSliderChange() // trigger submit
            }}
            min={0.8}
            max={3}
            step={0.1}
            infoPopupContent={
              !printMode &&
              intl.formatMessage({
                id: "sliders.energy-price.info",
                defaultMessage:
                  "The price in the selected currency for the energy unit. The slider illustrates the energy price required for upgrading the proposed pipe system to a more energy-efficient one, considering Total Cost of Ownership (TCO).",
              })
            }
            title={intl.formatMessage({
              id: "sliders.energy-price",
              defaultMessage: "Energy Price",
            })}
            minTitle={intl.formatMessage({
              id: "sliders.low",
              defaultMessage: "Low",
            })}
            maxTitle={intl.formatMessage({
              id: "sliders.high",
              defaultMessage: "High",
            })}
            valueTitle={`${numberFormatter.format(
              toDecimals(Number(energyPrice) * Number(field.value), 1)
            )} ${currency}`}
            isReadOnly={printMode}
          />
        )}
      />
    </Flex>
  )
}
