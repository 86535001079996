import React from "react"
import { Button, ButtonProps } from "@chakra-ui/react"
import { FormattedMessage } from "react-intl"

export const CalculateButton: React.FC<ButtonProps> = (props) => {
  return (
    <Button
      {...props}
      background={"brand.red"}
      color={"brand.white"}
      size="lg"
      w="200px"
      mt="10"
      mb="10"
      type="submit"
    >
      <FormattedMessage
        id="app.calculateTcoTitlePartOne"
        defaultMessage="Calculate TCO"
        description="Calculate TCO"
      />
    </Button>
  )
}
