import { TcoCalculationGroup } from "../../../models/calculation/tcoCalculationGroup"
import { TcoCalculationItem } from "../../../models/calculation/tcoCalculationItem"
import { Box, Divider, Flex, VStack } from "@chakra-ui/react"
import React, { useCallback, useEffect, useState } from "react"
import { CompareSystemGraph } from "./CompareSystemGraph/CompareSystemGraph"
import { UnitPricesMeterTrenchGraphs } from "./UnitPricesMeterTrenchGraphs/UnitPricesMeterTrenchGraphs"
import { CapexOpexGraphs } from "./CapexOpexGraphs/CapexOpexGraphs"
import { Accordion } from "../Accordion/Accordion"
import { ROIGraphs } from "./ROIGraph/ROIGraphs"
import { FormattedMessage, useIntl } from "react-intl"
import { BrandedButton } from "../../shared/BrandedButton"
import { useNavigate } from "react-router-dom"
import { ManualPricesSystems } from "./SelectSystem/hooks/useManualPrices"
import TagManager, { DataLayerArgs } from "react-gtm-module"
import { getGraphTitle } from "../utils/getGraphTitle"

type CompareSystemGraphsProps = {
  tcoCalculationGroups: TcoCalculationGroup[]
  printMode?: boolean
  selectedPipes?: ReportSelectedPipes | null
}

export type CompareItem = {
  systemOne: TcoCalculationItem
  systemTwo: TcoCalculationItem
}

export const CompareSystemGraphs: React.FC<CompareSystemGraphsProps> = ({
  tcoCalculationGroups,
  printMode = false,
  selectedPipes = null,
}) => {
  const intl = useIntl()

  const [chosenPipes, setChosenPipes] = useState<Array<CompareItem | null>>(
    tcoCalculationGroups.map((g) => null)
  )

  const setSystems = useCallback((index: number, compareItem: CompareItem) => {
    setChosenPipes((current) => {
      const newCurrent = [...current]
      newCurrent[index] = compareItem
      return newCurrent
    })
  }, [])

  const [manualPrices, setManualPrices] = useState<ManualPricesSystems[]>(
    () => {
      if (
        selectedPipes !== undefined &&
        selectedPipes?.manualPrices !== undefined
      ) {
        return selectedPipes.manualPrices
      }

      return tcoCalculationGroups.map((g) => ({
        systemOne: null,
        systemTwo: null,
      }))
    }
  )

  const setPrices = useCallback(
    (index: number, manualPricesSystem: ManualPricesSystems) => {
      setManualPrices((current) => {
        const newCurrent = [...current]
        newCurrent[index] = manualPricesSystem
        return newCurrent
      })
    },
    []
  )

  const [unitPricesMeterTrenchGraphWidth, setUnitPricesMeterTrenchGraphWidth] =
    useState("100%")

  useEffect(() => {
    if (printMode) {
      return
    }

    const dataLayerArgs: DataLayerArgs = {
      dataLayer: {
        gtmId: "GTM-MR49XPF",
        event: "showCompareSystems",
      },
    }

    TagManager.dataLayer(dataLayerArgs)
  }, [printMode])

  const enableReport =
    !printMode && chosenPipes.filter((p) => p === null).length === 0

  return (
    <VStack direction={"column"} w={"100%"} spacing={8}>
      {tcoCalculationGroups.map((group, index) => (
        <CompareSystemGraph
          key={index}
          id={index}
          title={getGraphTitle(
            intl,
            group.nominalDiameters,
            group.pipeSystemKind
          )}
          tcoCalculationItems={group.systems}
          setChosenSystems={setSystems}
          chosenSystems={
            printMode && selectedPipes ? selectedPipes.pipes[index] : null
          }
          manualPrices={manualPrices[index]}
          setManualPrices={setPrices}
          printMode={printMode}
        />
      ))}
      <Box w={"100%"}>
        {printMode ? (
          <ROIGraphs
            tcoCalculationGroups={tcoCalculationGroups}
            pipes={chosenPipes}
            manualPrices={manualPrices}
          />
        ) : (
          <Accordion title={"ROI"} isExpandedInitially={false}>
            <ROIGraphs
              tcoCalculationGroups={tcoCalculationGroups}
              pipes={chosenPipes}
              manualPrices={manualPrices}
            />
          </Accordion>
        )}
      </Box>
      <Box w={"100%"}>
        {printMode ? (
          <UnitPricesMeterTrenchGraphs
            tcoCalculationGroups={tcoCalculationGroups}
            pipes={chosenPipes}
            manualPrices={manualPrices}
          />
        ) : (
          <Accordion
            title={intl.formatMessage({
              id: "unit-prices-metre-trench",
              defaultMessage: "Unit prices/metre trench",
            })}
            isExpandedInitially={false}
            onExpansion={() =>
              // We use this timeout to fix a bug,
              // where ReactECharts is confused by the 100% width on accordion expansion,
              // and therefore it sets the width of the legend of the chart as way too small
              setTimeout(() => {
                // The XXXpx value is arbitrary, but we need to rerender and therefore change the state
                setUnitPricesMeterTrenchGraphWidth("100px")
                // This is the value which we want
                setUnitPricesMeterTrenchGraphWidth("100%")
              }, 100)
            }
          >
            <UnitPricesMeterTrenchGraphs
              tcoCalculationGroups={tcoCalculationGroups}
              pipes={chosenPipes}
              manualPrices={manualPrices}
              chartWidth={unitPricesMeterTrenchGraphWidth}
            />
          </Accordion>
        )}
      </Box>
      <Box w={"100%"}>
        {printMode ? (
          <CapexOpexGraphs
            tcoCalculationGroups={tcoCalculationGroups}
            pipes={chosenPipes}
            manualPrices={manualPrices}
          />
        ) : (
          <Accordion title={"CAPEX/OPEX"} isExpandedInitially={false}>
            <CapexOpexGraphs
              tcoCalculationGroups={tcoCalculationGroups}
              pipes={chosenPipes}
              manualPrices={manualPrices}
            />
          </Accordion>
        )}
      </Box>
      {enableReport && (
        <ReportRow pipes={chosenPipes} manualPrices={manualPrices} />
      )}
    </VStack>
  )
}

export type ReportSelectedPipes = {
  pipes: Array<CompareItem | null>
  manualPrices?: ManualPricesSystems[]
}

const ReportRow = (pipesAndPrices: ReportSelectedPipes) => {
  const navigate = useNavigate()

  return (
    <Box mt="5" w="100%">
      <Divider />
      <Flex mt="5" mb="5" pe="8" flexDirection="row-reverse">
        <BrandedButton
          size="lg"
          alignSelf="center"
          onClick={() =>
            navigate({ pathname: "/report" }, { state: pipesAndPrices })
          } //TODO: Should pass manual prices too (Story 1881)
        >
          <FormattedMessage
            id="create-report.button"
            defaultMessage="Create report"
          />
        </BrandedButton>
      </Flex>
      <Divider />
    </Box>
  )
}
