import { Flex, Heading, Text, VStack } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import { TcoCalculationItem } from "../../../../models/calculation/tcoCalculationItem"
import { SelectSystem } from "../SelectSystem/SelectSystem"
import { useSelectSystemEchartOption } from "../SelectSystem/hooks/useSelectSystemEchartOption"
import ReactECharts from "echarts-for-react"
import { FormattedMessage } from "react-intl"
import { PricesInput } from "./PricesInput"
import {
  ManualPrices,
  ManualPricesSystems,
  ValidManualPriceOrNull,
} from "../SelectSystem/hooks/useManualPrices"
import { CompareItem } from "../CompareSystemGraphs"

type CompareSystemGraphProps = {
  id: number
  title: string
  tcoCalculationItems: TcoCalculationItem[]
  setChosenSystems: (index: number, compareItem: CompareItem) => void
  chosenSystems?: CompareItem | null
  manualPrices: ManualPricesSystems
  setManualPrices: (index: number, prices: ManualPricesSystems) => void
  printMode: boolean
}

const keyForTcoCalculationItem = (item: TcoCalculationItem) => {
  const key = {
    insulationSeriesKind: item.insulationSeriesKind,
    pipeSystemPreInsulatedPipeKind: item.pipeSystemPreInsulatedPipeKind,
    pipeSystemKind: item.pipeSystemKind,
    nominalDiameters: item.nominalDiameters,
  }

  return JSON.stringify(key)
}

const tcoCalculationItemForKey = (
  rawKey: string | null,
  items: TcoCalculationItem[]
) => {
  if (rawKey == null) {
    return null
  }

  const key = JSON.parse(rawKey)
  return (
    items.find(
      (i) =>
        i.insulationSeriesKind === key.insulationSeriesKind &&
        i.pipeSystemPreInsulatedPipeKind ===
          key.pipeSystemPreInsulatedPipeKind &&
        i.pipeSystemKind === key.pipeSystemKind &&
        JSON.stringify(i.nominalDiameters) ===
          JSON.stringify(key.nominalDiameters)
    ) || null
  )
}

const isManualPricesEmpty = (prices: ManualPrices | null) => {
  if (prices === null) return true
  return (
    prices.pipeInstallation === undefined ||
    prices.pipeMaterial === undefined ||
    prices.trench === undefined
  )
}

export const CompareSystemGraph: React.FC<CompareSystemGraphProps> = ({
  id,
  title,
  tcoCalculationItems,
  setChosenSystems,
  chosenSystems = null,
  manualPrices,
  setManualPrices,
  printMode,
}) => {
  const [systemOneKey, setSystemOne] = useState<string | null>(
    chosenSystems ? keyForTcoCalculationItem(chosenSystems.systemOne) : null
  )
  const [systemTwoKey, setSystemTwo] = useState<string | null>(
    chosenSystems ? keyForTcoCalculationItem(chosenSystems.systemTwo) : null
  )
  const [systemOneManualMode, setSystemOneManualMode] = useState(() => {
    if (
      manualPrices !== undefined &&
      !isManualPricesEmpty(manualPrices.systemOne) &&
      printMode
    ) {
      return true
    }
    return false
  })
  const [systemTwoManualMode, setSystemTwoManualMode] = useState(() => {
    if (
      manualPrices !== undefined &&
      !isManualPricesEmpty(manualPrices.systemTwo) &&
      printMode
    ) {
      return true
    }
    return false
  })

  const systemOne = tcoCalculationItemForKey(systemOneKey, tcoCalculationItems)
  const systemTwo = tcoCalculationItemForKey(systemTwoKey, tcoCalculationItems)

  const echartsOption = useSelectSystemEchartOption(systemOne, systemTwo, {
    systemOne: ValidManualPriceOrNull(manualPrices.systemOne),
    systemTwo: ValidManualPriceOrNull(manualPrices.systemTwo),
  })

  useEffect(() => {
    if (systemOne && systemTwo) {
      setChosenSystems(id, { systemOne, systemTwo })
    }
  }, [systemOne, systemTwo, setChosenSystems, id])

  useEffect(() => {
    if (!systemOneManualMode) {
      setManualPrices(id, {
        systemTwo: manualPrices.systemTwo,
        systemOne: null,
      })
    }
  }, [systemOneManualMode, manualPrices.systemTwo, setManualPrices, id])

  useEffect(() => {
    if (!systemTwoManualMode) {
      setManualPrices(id, {
        systemOne: manualPrices.systemOne,
        systemTwo: null,
      })
    }
  }, [systemTwoManualMode, manualPrices.systemOne, setManualPrices, id])

  return (
    <Flex flexDirection={"column"} w={"100%"}>
      <Heading
        alignSelf={"start"}
        textAlign={"center"}
        color={"brand.grey"}
        fontSize={20}
      >
        {title}
      </Heading>
      <Text color={"brand.grey"} mt={4}>
        <FormattedMessage
          id={"app.compareSystemGraph.description"}
          defaultMessage={
            "To make a comparison between two solutions, select the systems you want to compare:"
          }
        />
      </Text>

      <Flex direction={"column"} flex={1} mt={4}>
        {!chosenSystems && (
          <Flex direction={"row"} justifyContent={"space-between"}>
            <SelectSystem
              tcoCalculationItems={tcoCalculationItems}
              onSystemChange={(tco) => {
                setSystemOneManualMode(false)
                setSystemOne(keyForTcoCalculationItem(tco))
              }}
            />
            <SelectSystem
              tcoCalculationItems={tcoCalculationItems}
              onSystemChange={(tco) => {
                setSystemTwoManualMode(false)
                setSystemTwo(keyForTcoCalculationItem(tco))
              }}
            />
          </Flex>
        )}

        <Flex
          direction={"row"}
          alignItems={"center"}
          key={tcoCalculationItems[0].insulationSeriesCost.pipeInstallation}
        >
          <VStack width={200} spacing="8">
            <PricesInput
              key={
                systemOne?.pipeSystemPreInsulatedPipeKind &&
                systemOne?.insulationSeriesKind + "budget"
              }
              type="Budget"
              checkBoxDisabled={systemOneKey == null || printMode}
              printMode={printMode}
              checked={!systemOneManualMode}
              pipeInstallationCost={
                systemOne?.insulationSeriesCost.pipeInstallation
              }
              onEnabled={(checked) => {
                setSystemOneManualMode(!checked)
              }}
              trenchCost={systemOne?.insulationSeriesCost.trenchExcavation}
              pipeMaterial={systemOne?.insulationSeriesCost.pipeMaterial}
            />
            <PricesInput
              key={
                systemOne?.pipeSystemPreInsulatedPipeKind &&
                systemOne?.insulationSeriesKind + "manual"
              }
              type="Manual"
              checkBoxDisabled={systemOneKey == null || printMode}
              printMode={printMode}
              checked={systemOneManualMode}
              onEnabled={(checked) => {
                setSystemOneManualMode(checked)
              }}
              onChange={(prices) =>
                setManualPrices(id, { ...manualPrices, systemOne: prices })
              }
              pipeInstallationCost={manualPrices?.systemOne?.pipeInstallation}
              trenchCost={manualPrices?.systemOne?.trench}
              pipeMaterial={manualPrices?.systemOne?.pipeMaterial}
            />
          </VStack>

          <Flex flexDirection={"row"} flex={1}>
            {echartsOption && (
              <ReactECharts
                option={echartsOption}
                style={{ height: "500px", width: "100%" }}
                opts={{ renderer: "svg" }}
              />
            )}
          </Flex>
          <VStack width={200} spacing="8">
            <PricesInput
              key={
                systemTwo?.pipeSystemPreInsulatedPipeKind &&
                systemTwo?.insulationSeriesKind + "budget"
              }
              type="Budget"
              checkBoxDisabled={systemTwoKey == null || printMode}
              printMode={printMode}
              checked={!systemTwoManualMode}
              onEnabled={(checked) => {
                setSystemTwoManualMode(!checked)
              }}
              pipeInstallationCost={
                systemTwo?.insulationSeriesCost.pipeInstallation
              }
              trenchCost={systemTwo?.insulationSeriesCost.trenchExcavation}
              pipeMaterial={systemTwo?.insulationSeriesCost.pipeMaterial}
            />
            <PricesInput
              key={
                systemTwo?.pipeSystemPreInsulatedPipeKind &&
                systemTwo?.insulationSeriesKind + "manual"
              }
              type="Manual"
              checkBoxDisabled={systemTwo == null || printMode}
              printMode={printMode}
              checked={systemTwoManualMode}
              onEnabled={(checked) => {
                setSystemTwoManualMode(checked)
              }}
              onChange={(prices) =>
                setManualPrices(id, { ...manualPrices, systemTwo: prices })
              }
              pipeInstallationCost={manualPrices?.systemTwo?.pipeInstallation}
              trenchCost={manualPrices?.systemTwo?.trench}
              pipeMaterial={manualPrices?.systemTwo?.pipeMaterial}
            />
          </VStack>
        </Flex>
      </Flex>
    </Flex>
  )
}
