import { extendTheme } from "@chakra-ui/react"

const focusColor = "#3182CE"

const chakraTheme = extendTheme({
  styles: {
    global: {
      body: {
        bg: "white.400",
        height: "100vh",
      },
    },
  },
  components: {
    Input: {
      defaultProps: {
        focusBorderColor: focusColor,
      },
    },
    Select: {
      defaultProps: {
        focusBorderColor: focusColor,
      },
    },
  },
  colors: {
    brand: {
      blue: "#004289",
      green: "#56765c",
      red: "#e4002b",
      grey: "#616161",
      white: "#ffffff",
    },
  },
  fonts: {
    heading: "Lato",
    body: "Lato",
  },
})
export default chakraTheme
