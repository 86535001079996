import { useMutation } from "react-query"
import { BaseConfiguration } from "./BaseConfiguration"
import { ContactApi, CreateLeadCommand } from "./openapi"

export function useContact() {
  return useMutation(
    (data: {
      createLeadCommand: CreateLeadCommand
      token: () => Promise<string>
    }) => {
      const api = new ContactApi(new BaseConfiguration(data.token))
      return api.contactCreateLead(data.createLeadCommand)
    }
  )
}
