import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react"
import React from "react"

type PopUpProps = {
  triggerElement: JSX.Element
  children: React.ReactNode
}
export const PopUp: React.FC<PopUpProps> = ({ triggerElement, children }) => {
  return (
    <Popover>
      <PopoverTrigger>{triggerElement}</PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverBody>{children}</PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
