import {
  Accordion as ChakraAccordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Text,
  HStack,
} from "@chakra-ui/react"
import { AddIcon, MinusIcon } from "@chakra-ui/icons"

interface AccordionProps {
  title: string
  isExpandedInitially: boolean
  onExpansion?: () => void
  children?: React.ReactNode
}
export const Accordion: React.FC<AccordionProps> = ({
  title,
  isExpandedInitially,
  onExpansion,
  children,
}) => {
  const onClick = () => {
    if (onExpansion) {
      onExpansion()
    }
  }

  return (
    <ChakraAccordion
      allowToggle
      onClick={onClick}
      defaultIndex={isExpandedInitially ? 0 : undefined}
    >
      <AccordionItem border="none">
        {({ isExpanded }) => {
          return (
            <>
              <AccordionButton w={250}>
                <HStack
                  p="8px"
                  borderTop={"solid"}
                  borderBottom={"solid"}
                  borderTopWidth={"1px"}
                  borderBottomWidth={"1px"}
                  flex="1"
                  textAlign="left"
                  borderColor={"red"}
                >
                  {isExpanded ? (
                    <MinusIcon color="brand.red" fontSize="12px" />
                  ) : (
                    <AddIcon color="brand.red" fontSize="12px" />
                  )}

                  <Text color="brand.red">{title}</Text>
                </HStack>
              </AccordionButton>
              <AccordionPanel pb={8} pt={4}>
                {isExpanded && children}
              </AccordionPanel>
            </>
          )
        }}
      </AccordionItem>
    </ChakraAccordion>
  )
}
