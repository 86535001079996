import { TcoCalculationItem } from "../../../../../models/calculation/tcoCalculationItem"
import { useEffect, useState } from "react"

export const useFindCheapestTco = (
  tcoDataItems: TcoCalculationItem[]
): TcoCalculationItem | null => {
  const [tcoDataItem, setTcoDateItem] = useState<TcoCalculationItem | null>(
    null
  )

  useEffect(() => {
    if (tcoDataItems.length > 0) {
      let cheapestTcoDataItem: TcoCalculationItem | null = null
      let cheapestCost: number = 0
      tcoDataItems.forEach((data, index) => {
        const cost = Object.values(data.insulationSeriesCost).reduce(
          (accumulator, currentValue) => {
            return accumulator! + currentValue!
          }
        ) as number

        // First iteration set cheapest cost
        if (index === 0) {
          cheapestCost = cost
          cheapestTcoDataItem = data
        } else {
          if (cost < cheapestCost) {
            cheapestCost = cost
            cheapestTcoDataItem = data
          }
        }
      })
      setTcoDateItem(cheapestTcoDataItem)
    }
  }, [tcoDataItems, setTcoDateItem])

  return tcoDataItem
}
