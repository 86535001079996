import { IntlShape } from "react-intl"
import { PipeSystemKind } from "../../../models/pipeSystemKind"

export const getGraphTitle = (
  intl: IntlShape,
  nominalDiameters: number[],
  pipeSystemKind: PipeSystemKind
) => {
  var kind = pipeSystemKind.toString()
  var prefix = "⌀"
  if (pipeSystemKind === "BondedSteel") {
    kind = intl.formatMessage({
      id: "header.bonded-steel",
      defaultMessage: "Bonded Pipe",
    })
    prefix = "DN"
  }

  const minDN = Math.min(...nominalDiameters)
  const maxDN = Math.max(...nominalDiameters)
  let dimensions = `${prefix}${minDN}-${prefix}${maxDN}`
  if (minDN === maxDN) {
    dimensions = `${prefix}${minDN}`
  }
  return `${kind} ${dimensions} `
}
