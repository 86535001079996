/* tslint:disable */
/* eslint-disable */
/**
 * Logstor Total Cost of Ownership API
 * Calculates total cost of ownership for pre-insulated pipes
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AddMaterialPropertyDto
 */
export interface AddMaterialPropertyDto {
    /**
     * 
     * @type {MaterialPropertyKindDto}
     * @memberof AddMaterialPropertyDto
     */
    materialPropertyKind?: MaterialPropertyKindDto;
    /**
     * 
     * @type {number}
     * @memberof AddMaterialPropertyDto
     */
    materialPropertyValue?: number;
    /**
     * 
     * @type {string}
     * @memberof AddMaterialPropertyDto
     */
    comment?: string | null;
}
/**
 * 
 * @export
 * @interface AddPipeSystemDiameterDimensionDto
 */
export interface AddPipeSystemDiameterDimensionDto {
    /**
     * 
     * @type {number}
     * @memberof AddPipeSystemDiameterDimensionDto
     */
    singleMediaPairPreInsulatedPipeFittingFactor?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AddPipeSystemDiameterDimensionDto
     */
    twinMediaPreInsulatedPipeFittingFactor?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AddPipeSystemDiameterDimensionDto
     */
    comment?: string | null;
}
/**
 * 
 * @export
 * @interface AddPipeSystemInsulationSeriesDimensionDto
 */
export interface AddPipeSystemInsulationSeriesDimensionDto {
    /**
     * 
     * @type {PipeSystemInsulationSeriesKindDto}
     * @memberof AddPipeSystemInsulationSeriesDimensionDto
     */
    pipeSystemInsulationSeriesKind?: PipeSystemInsulationSeriesKindDto;
    /**
     * 
     * @type {number}
     * @memberof AddPipeSystemInsulationSeriesDimensionDto
     */
    installationFittingFactor?: number;
    /**
     * 
     * @type {number}
     * @memberof AddPipeSystemInsulationSeriesDimensionDto
     */
    excavationFittingFactor?: number;
    /**
     * 
     * @type {string}
     * @memberof AddPipeSystemInsulationSeriesDimensionDto
     */
    comment?: string | null;
}
/**
 * 
 * @export
 * @interface AddPipeSystemPreInsulatedPipeDto
 */
export interface AddPipeSystemPreInsulatedPipeDto {
    /**
     * 
     * @type {string}
     * @memberof AddPipeSystemPreInsulatedPipeDto
     */
    singlePreInsulatedPipeId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddPipeSystemPreInsulatedPipeDto
     */
    twinPreInsulatedPipeId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddPipeSystemPreInsulatedPipeDto
     */
    comment?: string | null;
}
/**
 * 
 * @export
 * @interface AddPipeSystemPreInsulatedPipeKindDimensionDto
 */
export interface AddPipeSystemPreInsulatedPipeKindDimensionDto {
    /**
     * 
     * @type {PipeSystemPreInsulatedPipeKindDto}
     * @memberof AddPipeSystemPreInsulatedPipeKindDimensionDto
     */
    pipeSystemPreInsulatedPipeKind?: PipeSystemPreInsulatedPipeKindDto;
    /**
     * 
     * @type {PipeSystemOperatingLimitsInputDto}
     * @memberof AddPipeSystemPreInsulatedPipeKindDimensionDto
     */
    pipeSystemOperatingLimits?: PipeSystemOperatingLimitsInputDto;
    /**
     * 
     * @type {string}
     * @memberof AddPipeSystemPreInsulatedPipeKindDimensionDto
     */
    comment?: string | null;
}
/**
 * 
 * @export
 * @interface CalculationData
 */
export interface CalculationData {
    /**
     * 
     * @type {CurrencyCodeKindDto}
     * @memberof CalculationData
     */
    currencyCode?: CurrencyCodeKindDto;
    /**
     * 
     * @type {number}
     * @memberof CalculationData
     */
    currencyExchangeRate?: number;
    /**
     * 
     * @type {number}
     * @memberof CalculationData
     */
    interestRate?: number;
    /**
     * 
     * @type {number}
     * @memberof CalculationData
     */
    calculationPeriod?: number;
    /**
     * 
     * @type {UnitKindDto}
     * @memberof CalculationData
     */
    energyUnitKind?: UnitKindDto;
    /**
     * 
     * @type {number}
     * @memberof CalculationData
     */
    energyPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof CalculationData
     */
    yearlyRiseInEnergyPrice?: number;
}
/**
 * 
 * @export
 * @interface CalculationResultDto
 */
export interface CalculationResultDto {
    /**
     * 
     * @type {Array<TcoCalculationGroupDto>}
     * @memberof CalculationResultDto
     */
    tcoCalculationGroups?: Array<TcoCalculationGroupDto>;
}
/**
 * 
 * @export
 * @interface CapexDto
 */
export interface CapexDto {
    /**
     * 
     * @type {number}
     * @memberof CapexDto
     */
    materialPercent?: number;
    /**
     * 
     * @type {number}
     * @memberof CapexDto
     */
    installationPercent?: number;
    /**
     * 
     * @type {number}
     * @memberof CapexDto
     */
    excavationPercent?: number;
    /**
     * 
     * @type {number}
     * @memberof CapexDto
     */
    totalPrice?: number;
}
/**
 * 
 * @export
 * @interface CasingPipeDto
 */
export interface CasingPipeDto {
    /**
     * 
     * @type {string}
     * @memberof CasingPipeDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CasingPipeDto
     */
    materialId?: string;
    /**
     * 
     * @type {string}
     * @memberof CasingPipeDto
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof CasingPipeDto
     */
    nominalDiameter?: number;
    /**
     * 
     * @type {number}
     * @memberof CasingPipeDto
     */
    actualDiameter?: number;
    /**
     * 
     * @type {number}
     * @memberof CasingPipeDto
     */
    wallThickness?: number;
    /**
     * 
     * @type {string}
     * @memberof CasingPipeDto
     */
    comment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CasingPipeDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof CasingPipeDto
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof CasingPipeDto
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof CasingPipeDto
     */
    updatedBy?: string;
}
/**
 * 
 * @export
 * @interface CompareSystemsItemDto
 */
export interface CompareSystemsItemDto {
    /**
     * 
     * @type {PipeSolutionKeysDto}
     * @memberof CompareSystemsItemDto
     */
    pipeSolutionKeys?: PipeSolutionKeysDto;
    /**
     * 
     * @type {Array<YearlyPriceDto>}
     * @memberof CompareSystemsItemDto
     */
    yearlyPrices?: Array<YearlyPriceDto>;
    /**
     * 
     * @type {UnitPricePerMeterTrenchDto}
     * @memberof CompareSystemsItemDto
     */
    unitPricePerMeterTrench?: UnitPricePerMeterTrenchDto;
    /**
     * 
     * @type {CapexDto}
     * @memberof CompareSystemsItemDto
     */
    capex?: CapexDto;
    /**
     * 
     * @type {OpexDto}
     * @memberof CompareSystemsItemDto
     */
    opex?: OpexDto;
}
/**
 * 
 * @export
 * @interface CompareSystemsQuery
 */
export interface CompareSystemsQuery {
    /**
     * 
     * @type {DesignData}
     * @memberof CompareSystemsQuery
     */
    designData?: DesignData;
    /**
     * 
     * @type {CalculationData}
     * @memberof CompareSystemsQuery
     */
    calculationData?: CalculationData;
    /**
     * 
     * @type {EmissionData}
     * @memberof CompareSystemsQuery
     */
    emissionData?: EmissionData;
    /**
     * 
     * @type {Array<PipeSystemDiameterByTrenchDistanceInput>}
     * @memberof CompareSystemsQuery
     */
    pipeSystemDiameterByTrenchDistance?: Array<PipeSystemDiameterByTrenchDistanceInput>;
    /**
     * 
     * @type {ComplexityData}
     * @memberof CompareSystemsQuery
     */
    complexityData?: ComplexityData;
    /**
     * 
     * @type {PipeSolutionInput}
     * @memberof CompareSystemsQuery
     */
    firstPipeSolution?: PipeSolutionInput;
    /**
     * 
     * @type {PipeSolutionInput}
     * @memberof CompareSystemsQuery
     */
    secondPipeSolution?: PipeSolutionInput;
}
/**
 * 
 * @export
 * @interface CompareSystemsResultDto
 */
export interface CompareSystemsResultDto {
    /**
     * 
     * @type {CompareSystemsItemDto}
     * @memberof CompareSystemsResultDto
     */
    firstSystemData?: CompareSystemsItemDto;
    /**
     * 
     * @type {CompareSystemsItemDto}
     * @memberof CompareSystemsResultDto
     */
    secondSystemData?: CompareSystemsItemDto;
    /**
     * 
     * @type {number}
     * @memberof CompareSystemsResultDto
     */
    roiYears?: number;
}
/**
 * 
 * @export
 * @interface ComplexityData
 */
export interface ComplexityData {
    /**
     * 
     * @type {number}
     * @memberof ComplexityData
     */
    installationComplexity?: number;
    /**
     * 
     * @type {number}
     * @memberof ComplexityData
     */
    excavationComplexity?: number;
    /**
     * 
     * @type {number}
     * @memberof ComplexityData
     */
    pipeSystemComplexity?: number;
    /**
     * 
     * @type {number}
     * @memberof ComplexityData
     */
    energyPriceComplexity?: number;
}
/**
 * 
 * @export
 * @interface CreateCasingPipeDto
 */
export interface CreateCasingPipeDto {
    /**
     * 
     * @type {string}
     * @memberof CreateCasingPipeDto
     */
    materialId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCasingPipeDto
     */
    pipeName?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateCasingPipeDto
     */
    nominalDiameter?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateCasingPipeDto
     */
    actualDiameter?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateCasingPipeDto
     */
    wallThickness?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateCasingPipeDto
     */
    comment?: string | null;
}
/**
 * 
 * @export
 * @interface CreateCurrencyDto
 */
export interface CreateCurrencyDto {
    /**
     * 
     * @type {CurrencyCodeKindDto}
     * @memberof CreateCurrencyDto
     */
    code?: CurrencyCodeKindDto;
    /**
     * 
     * @type {number}
     * @memberof CreateCurrencyDto
     */
    rate?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateCurrencyDto
     */
    comment?: string | null;
}
/**
 * 
 * @export
 * @interface CreateEmissionSourceDto
 */
export interface CreateEmissionSourceDto {
    /**
     * 
     * @type {EmissionSourceKindDto}
     * @memberof CreateEmissionSourceDto
     */
    kind?: EmissionSourceKindDto;
    /**
     * 
     * @type {number}
     * @memberof CreateEmissionSourceDto
     */
    value?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateEmissionSourceDto
     */
    comment?: string | null;
}
/**
 * 
 * @export
 * @interface CreateLeadCommand
 */
export interface CreateLeadCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateLeadCommand
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLeadCommand
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLeadCommand
     */
    business?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLeadCommand
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLeadCommand
     */
    comment?: string;
    /**
     * 
     * @type {QueryData}
     * @memberof CreateLeadCommand
     */
    calculationQuery?: QueryData | null;
    /**
     * 
     * @type {string}
     * @memberof CreateLeadCommand
     */
    saveQueryName?: string | null;
}
/**
 * 
 * @export
 * @interface CreateMaterialDto
 */
export interface CreateMaterialDto {
    /**
     * 
     * @type {string}
     * @memberof CreateMaterialDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMaterialDto
     */
    comment?: string | null;
}
/**
 * 
 * @export
 * @interface CreateMediaPipeDto
 */
export interface CreateMediaPipeDto {
    /**
     * 
     * @type {string}
     * @memberof CreateMediaPipeDto
     */
    materialId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMediaPipeDto
     */
    pipeName?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateMediaPipeDto
     */
    nominalDiameter?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateMediaPipeDto
     */
    actualDiameter?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateMediaPipeDto
     */
    wallThickness?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateMediaPipeDto
     */
    comment?: string | null;
}
/**
 * 
 * @export
 * @interface CreatePipeSystemDto
 */
export interface CreatePipeSystemDto {
    /**
     * 
     * @type {PipeSystemKindDto}
     * @memberof CreatePipeSystemDto
     */
    pipeSystemKind?: PipeSystemKindDto;
    /**
     * 
     * @type {PipeSystemPriceFactorsInputDto}
     * @memberof CreatePipeSystemDto
     */
    pipeSystemPriceFactors?: PipeSystemPriceFactorsInputDto;
    /**
     * 
     * @type {string}
     * @memberof CreatePipeSystemDto
     */
    comment?: string | null;
}
/**
 * 
 * @export
 * @interface CreateSavedQueryCommand
 */
export interface CreateSavedQueryCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateSavedQueryCommand
     */
    name?: string;
    /**
     * 
     * @type {QueryData}
     * @memberof CreateSavedQueryCommand
     */
    queryData?: QueryData;
}
/**
 * 
 * @export
 * @interface CreateSingleMediaPairPreInsulatedPipeDto
 */
export interface CreateSingleMediaPairPreInsulatedPipeDto {
    /**
     * 
     * @type {string}
     * @memberof CreateSingleMediaPairPreInsulatedPipeDto
     */
    mediaPipeId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSingleMediaPairPreInsulatedPipeDto
     */
    casingPipeId?: string;
    /**
     * 
     * @type {DiffusionBarrierKindDto}
     * @memberof CreateSingleMediaPairPreInsulatedPipeDto
     */
    diffusionBarrierKind?: DiffusionBarrierKindDto;
    /**
     * 
     * @type {string}
     * @memberof CreateSingleMediaPairPreInsulatedPipeDto
     */
    sapId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateSingleMediaPairPreInsulatedPipeDto
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateSingleMediaPairPreInsulatedPipeDto
     */
    unitLength?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateSingleMediaPairPreInsulatedPipeDto
     */
    unitPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateSingleMediaPairPreInsulatedPipeDto
     */
    comment?: string | null;
    /**
     * 
     * @type {Array<LambdaYearValueInputDto>}
     * @memberof CreateSingleMediaPairPreInsulatedPipeDto
     */
    lambdaYearValues?: Array<LambdaYearValueInputDto>;
}
/**
 * 
 * @export
 * @interface CreateTwinMediaPreInsulatedPipeDto
 */
export interface CreateTwinMediaPreInsulatedPipeDto {
    /**
     * 
     * @type {string}
     * @memberof CreateTwinMediaPreInsulatedPipeDto
     */
    mediaPipe1Id?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTwinMediaPreInsulatedPipeDto
     */
    mediaPipe2Id?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTwinMediaPreInsulatedPipeDto
     */
    casingPipeId?: string;
    /**
     * 
     * @type {DiffusionBarrierKindDto}
     * @memberof CreateTwinMediaPreInsulatedPipeDto
     */
    diffusionBarrierKind?: DiffusionBarrierKindDto;
    /**
     * 
     * @type {number}
     * @memberof CreateTwinMediaPreInsulatedPipeDto
     */
    nominalDiameter?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateTwinMediaPreInsulatedPipeDto
     */
    distanceBetweenMediaPipes?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateTwinMediaPreInsulatedPipeDto
     */
    sapId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateTwinMediaPreInsulatedPipeDto
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateTwinMediaPreInsulatedPipeDto
     */
    unitLength?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateTwinMediaPreInsulatedPipeDto
     */
    unitPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateTwinMediaPreInsulatedPipeDto
     */
    comment?: string | null;
    /**
     * 
     * @type {Array<LambdaYearValueInputDto>}
     * @memberof CreateTwinMediaPreInsulatedPipeDto
     */
    lambdaYearValues?: Array<LambdaYearValueInputDto>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum CurrencyCodeKindDto {
    None = 'None',
    Cad = 'CAD',
    Chf = 'CHF',
    Czk = 'CZK',
    Dkk = 'DKK',
    Eur = 'EUR',
    Gbp = 'GBP',
    Isk = 'ISK',
    Ltl = 'LTL',
    Nok = 'NOK',
    Pln = 'PLN',
    Ron = 'RON',
    Sek = 'SEK',
    Usd = 'USD'
}

/**
 * 
 * @export
 * @interface CurrencyDto
 */
export interface CurrencyDto {
    /**
     * 
     * @type {string}
     * @memberof CurrencyDto
     */
    id?: string;
    /**
     * 
     * @type {CurrencyCodeKindDto}
     * @memberof CurrencyDto
     */
    code?: CurrencyCodeKindDto;
    /**
     * 
     * @type {number}
     * @memberof CurrencyDto
     */
    rate?: number;
}
/**
 * 
 * @export
 * @interface CurrencyDto2
 */
export interface CurrencyDto2 {
    /**
     * 
     * @type {string}
     * @memberof CurrencyDto2
     */
    id?: string;
    /**
     * 
     * @type {CurrencyCodeKindDto}
     * @memberof CurrencyDto2
     */
    code?: CurrencyCodeKindDto;
    /**
     * 
     * @type {number}
     * @memberof CurrencyDto2
     */
    rate?: number;
    /**
     * 
     * @type {string}
     * @memberof CurrencyDto2
     */
    comment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CurrencyDto2
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof CurrencyDto2
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof CurrencyDto2
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof CurrencyDto2
     */
    updatedBy?: string;
}
/**
 * 
 * @export
 * @interface DeleteSavedQueryCommand
 */
export interface DeleteSavedQueryCommand {
    /**
     * 
     * @type {string}
     * @memberof DeleteSavedQueryCommand
     */
    savedQueryId?: string;
}
/**
 * 
 * @export
 * @interface DesignData
 */
export interface DesignData {
    /**
     * 
     * @type {number}
     * @memberof DesignData
     */
    temperatureFlowWinter?: number;
    /**
     * 
     * @type {number}
     * @memberof DesignData
     */
    temperatureFlowSummer?: number;
    /**
     * 
     * @type {number}
     * @memberof DesignData
     */
    temperatureReturnWinter?: number;
    /**
     * 
     * @type {number}
     * @memberof DesignData
     */
    temperatureReturnSummer?: number;
    /**
     * 
     * @type {number}
     * @memberof DesignData
     */
    temperatureSoilWinter?: number;
    /**
     * 
     * @type {number}
     * @memberof DesignData
     */
    temperatureSoilSummer?: number;
    /**
     * 
     * @type {number}
     * @memberof DesignData
     */
    operationPeriodWinter?: number;
    /**
     * 
     * @type {number}
     * @memberof DesignData
     */
    operationPeriodSummer?: number;
    /**
     * 
     * @type {number}
     * @memberof DesignData
     */
    pressure?: number;
    /**
     * 
     * @type {number}
     * @memberof DesignData
     */
    soilCover?: number;
    /**
     * 
     * @type {number}
     * @memberof DesignData
     */
    lambdaSoil?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum DiffusionBarrierKindDto {
    None = 'None',
    Present = 'Present',
    Absent = 'Absent'
}

/**
 * 
 * @export
 * @interface EmissionComparisonDto
 */
export interface EmissionComparisonDto {
    /**
     * 
     * @type {number}
     * @memberof EmissionComparisonDto
     */
    emission?: number;
    /**
     * 
     * @type {number}
     * @memberof EmissionComparisonDto
     */
    emissionPercent?: number;
    /**
     * 
     * @type {number}
     * @memberof EmissionComparisonDto
     */
    emissionSavings?: number;
    /**
     * 
     * @type {number}
     * @memberof EmissionComparisonDto
     */
    emissionSavingsPercent?: number;
    /**
     * 
     * @type {number}
     * @memberof EmissionComparisonDto
     */
    averageEmission?: number;
}
/**
 * 
 * @export
 * @interface EmissionData
 */
export interface EmissionData {
    /**
     * 
     * @type {EmissionSourceKindDto}
     * @memberof EmissionData
     */
    emissionSourceKind?: EmissionSourceKindDto;
    /**
     * 
     * @type {number}
     * @memberof EmissionData
     */
    customEmissionValue?: number | null;
    /**
     * 
     * @type {EmissionSourceInCalculationData}
     * @memberof EmissionData
     */
    emissionSourceInCalculation?: EmissionSourceInCalculationData | null;
}
/**
 * 
 * @export
 * @interface EmissionSourceDto
 */
export interface EmissionSourceDto {
    /**
     * 
     * @type {string}
     * @memberof EmissionSourceDto
     */
    id?: string;
    /**
     * 
     * @type {EmissionSourceKindDto}
     * @memberof EmissionSourceDto
     */
    kind?: EmissionSourceKindDto;
    /**
     * 
     * @type {number}
     * @memberof EmissionSourceDto
     */
    value?: number;
}
/**
 * 
 * @export
 * @interface EmissionSourceDto2
 */
export interface EmissionSourceDto2 {
    /**
     * 
     * @type {string}
     * @memberof EmissionSourceDto2
     */
    id?: string;
    /**
     * 
     * @type {EmissionSourceKindDto}
     * @memberof EmissionSourceDto2
     */
    kind?: EmissionSourceKindDto;
    /**
     * 
     * @type {number}
     * @memberof EmissionSourceDto2
     */
    value?: number;
    /**
     * 
     * @type {string}
     * @memberof EmissionSourceDto2
     */
    comment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmissionSourceDto2
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof EmissionSourceDto2
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof EmissionSourceDto2
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof EmissionSourceDto2
     */
    updatedBy?: string;
}
/**
 * 
 * @export
 * @interface EmissionSourceInCalculationData
 */
export interface EmissionSourceInCalculationData {
    /**
     * 
     * @type {number}
     * @memberof EmissionSourceInCalculationData
     */
    emissionQuotePrice?: number;
    /**
     * 
     * @type {number}
     * @memberof EmissionSourceInCalculationData
     */
    emissionPeriod?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum EmissionSourceKindDto {
    None = 'None',
    NaturalGas = 'NaturalGas',
    GasOil = 'GasOil',
    FuelOil = 'FuelOil',
    Coal = 'Coal',
    Waste = 'Waste',
    Co2Neutral = 'CO2Neutral',
    Custom = 'Custom'
}

/**
 * 
 * @export
 * @interface EnergyLossFirstYearDto
 */
export interface EnergyLossFirstYearDto {
    /**
     * 
     * @type {number}
     * @memberof EnergyLossFirstYearDto
     */
    energyLoss?: number;
    /**
     * 
     * @type {number}
     * @memberof EnergyLossFirstYearDto
     */
    energyLossPercent?: number;
    /**
     * 
     * @type {number}
     * @memberof EnergyLossFirstYearDto
     */
    energySavings?: number;
    /**
     * 
     * @type {number}
     * @memberof EnergyLossFirstYearDto
     */
    energySavingsPercent?: number;
}
/**
 * 
 * @export
 * @interface FilteredPipeSystemDiameterDto
 */
export interface FilteredPipeSystemDiameterDto {
    /**
     * 
     * @type {string}
     * @memberof FilteredPipeSystemDiameterDto
     */
    pipeSystemDiameterId?: string;
    /**
     * 
     * @type {PipeSystemKindDto}
     * @memberof FilteredPipeSystemDiameterDto
     */
    pipeSystemKind?: PipeSystemKindDto;
    /**
     * 
     * @type {number}
     * @memberof FilteredPipeSystemDiameterDto
     */
    nominalDiameter?: number;
    /**
     * 
     * @type {number}
     * @memberof FilteredPipeSystemDiameterDto
     */
    actualDiameter?: number;
}
/**
 * 
 * @export
 * @interface GetFilteredPipeSystemDiametersQuery
 */
export interface GetFilteredPipeSystemDiametersQuery {
    /**
     * 
     * @type {number}
     * @memberof GetFilteredPipeSystemDiametersQuery
     */
    minMediaTemperature?: number;
    /**
     * 
     * @type {number}
     * @memberof GetFilteredPipeSystemDiametersQuery
     */
    maxMediaTemperature?: number;
    /**
     * 
     * @type {number}
     * @memberof GetFilteredPipeSystemDiametersQuery
     */
    maxMediaPressure?: number;
}
/**
 * 
 * @export
 * @interface InsulationSeriesPriceDto
 */
export interface InsulationSeriesPriceDto {
    /**
     * 
     * @type {number}
     * @memberof InsulationSeriesPriceDto
     */
    pipeMaterial?: number;
    /**
     * 
     * @type {number}
     * @memberof InsulationSeriesPriceDto
     */
    trenchExcavation?: number;
    /**
     * 
     * @type {number}
     * @memberof InsulationSeriesPriceDto
     */
    pipeInstallation?: number;
    /**
     * 
     * @type {number}
     * @memberof InsulationSeriesPriceDto
     */
    energyPresentValue?: number;
    /**
     * 
     * @type {number}
     * @memberof InsulationSeriesPriceDto
     */
    emissionPresentValue?: number;
}
/**
 * 
 * @export
 * @interface InvestmentPricesInput
 */
export interface InvestmentPricesInput {
    /**
     * 
     * @type {number}
     * @memberof InvestmentPricesInput
     */
    materialPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof InvestmentPricesInput
     */
    installationPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof InvestmentPricesInput
     */
    excavationPrice?: number;
}
/**
 * 
 * @export
 * @interface LambdaYearValueInputDto
 */
export interface LambdaYearValueInputDto {
    /**
     * 
     * @type {number}
     * @memberof LambdaYearValueInputDto
     */
    year?: number;
    /**
     * 
     * @type {number}
     * @memberof LambdaYearValueInputDto
     */
    value?: number;
    /**
     * 
     * @type {string}
     * @memberof LambdaYearValueInputDto
     */
    comment?: string | null;
}
/**
 * 
 * @export
 * @interface LambdaYearValueOutputDto
 */
export interface LambdaYearValueOutputDto {
    /**
     * 
     * @type {string}
     * @memberof LambdaYearValueOutputDto
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof LambdaYearValueOutputDto
     */
    year?: number;
    /**
     * 
     * @type {number}
     * @memberof LambdaYearValueOutputDto
     */
    value?: number;
    /**
     * 
     * @type {string}
     * @memberof LambdaYearValueOutputDto
     */
    comment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LambdaYearValueOutputDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof LambdaYearValueOutputDto
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof LambdaYearValueOutputDto
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof LambdaYearValueOutputDto
     */
    updatedBy?: string;
}
/**
 * 
 * @export
 * @interface MaterialDto
 */
export interface MaterialDto {
    /**
     * 
     * @type {string}
     * @memberof MaterialDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof MaterialDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof MaterialDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof MaterialDto
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof MaterialDto
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof MaterialDto
     */
    updatedBy?: string;
    /**
     * 
     * @type {Array<MaterialPropertyDto>}
     * @memberof MaterialDto
     */
    properties?: Array<MaterialPropertyDto>;
}
/**
 * 
 * @export
 * @interface MaterialPropertyDto
 */
export interface MaterialPropertyDto {
    /**
     * 
     * @type {string}
     * @memberof MaterialPropertyDto
     */
    id?: string;
    /**
     * 
     * @type {MaterialPropertyKindDto}
     * @memberof MaterialPropertyDto
     */
    kind?: MaterialPropertyKindDto;
    /**
     * 
     * @type {number}
     * @memberof MaterialPropertyDto
     */
    value?: number;
    /**
     * 
     * @type {string}
     * @memberof MaterialPropertyDto
     */
    comment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MaterialPropertyDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof MaterialPropertyDto
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof MaterialPropertyDto
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof MaterialPropertyDto
     */
    updatedBy?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum MaterialPropertyKindDto {
    None = 'None',
    ThermalConductivity = 'ThermalConductivity'
}

/**
 * 
 * @export
 * @interface MediaPipeDto
 */
export interface MediaPipeDto {
    /**
     * 
     * @type {string}
     * @memberof MediaPipeDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaPipeDto
     */
    materialId?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaPipeDto
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof MediaPipeDto
     */
    nominalDiameter?: number;
    /**
     * 
     * @type {number}
     * @memberof MediaPipeDto
     */
    actualDiameter?: number;
    /**
     * 
     * @type {number}
     * @memberof MediaPipeDto
     */
    wallThickness?: number;
    /**
     * 
     * @type {string}
     * @memberof MediaPipeDto
     */
    comment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MediaPipeDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaPipeDto
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaPipeDto
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaPipeDto
     */
    updatedBy?: string;
}
/**
 * 
 * @export
 * @interface OpexDto
 */
export interface OpexDto {
    /**
     * 
     * @type {number}
     * @memberof OpexDto
     */
    investmentPercent?: number;
    /**
     * 
     * @type {number}
     * @memberof OpexDto
     */
    energyLossPercent?: number;
    /**
     * 
     * @type {number}
     * @memberof OpexDto
     */
    emissionPercent?: number;
    /**
     * 
     * @type {number}
     * @memberof OpexDto
     */
    totalPrice?: number;
}
/**
 * 
 * @export
 * @interface PerformCalculationQuery
 */
export interface PerformCalculationQuery {
    /**
     * 
     * @type {DesignData}
     * @memberof PerformCalculationQuery
     */
    designData?: DesignData;
    /**
     * 
     * @type {CalculationData}
     * @memberof PerformCalculationQuery
     */
    calculationData?: CalculationData;
    /**
     * 
     * @type {EmissionData}
     * @memberof PerformCalculationQuery
     */
    emissionData?: EmissionData;
    /**
     * 
     * @type {Array<PipeSystemDiameterByTrenchDistanceInput>}
     * @memberof PerformCalculationQuery
     */
    pipeSystemDiameterByTrenchDistance?: Array<PipeSystemDiameterByTrenchDistanceInput>;
    /**
     * 
     * @type {ComplexityData}
     * @memberof PerformCalculationQuery
     */
    complexityData?: ComplexityData;
}
/**
 * 
 * @export
 * @interface PipeSolutionInput
 */
export interface PipeSolutionInput {
    /**
     * 
     * @type {Array<number>}
     * @memberof PipeSolutionInput
     */
    nominalDiameters?: Array<number>;
    /**
     * 
     * @type {PipeSystemKindDto}
     * @memberof PipeSolutionInput
     */
    pipeSystemKind?: PipeSystemKindDto;
    /**
     * 
     * @type {PipeSystemPreInsulatedPipeKindDto}
     * @memberof PipeSolutionInput
     */
    pipeSystemPreInsulatedPipeKind?: PipeSystemPreInsulatedPipeKindDto;
    /**
     * 
     * @type {PipeSystemInsulationSeriesKindDto}
     * @memberof PipeSolutionInput
     */
    pipeSystemInsulationSeriesKind?: PipeSystemInsulationSeriesKindDto;
    /**
     * 
     * @type {InvestmentPricesInput}
     * @memberof PipeSolutionInput
     */
    investmentPrices?: InvestmentPricesInput;
}
/**
 * 
 * @export
 * @interface PipeSolutionKeysDto
 */
export interface PipeSolutionKeysDto {
    /**
     * 
     * @type {Array<number>}
     * @memberof PipeSolutionKeysDto
     */
    nominalDiameters?: Array<number>;
    /**
     * 
     * @type {PipeSystemKindDto}
     * @memberof PipeSolutionKeysDto
     */
    pipeSystemKind?: PipeSystemKindDto;
    /**
     * 
     * @type {PipeSystemPreInsulatedPipeKindDto}
     * @memberof PipeSolutionKeysDto
     */
    pipeSystemPreInsulatedPipeKind?: PipeSystemPreInsulatedPipeKindDto;
    /**
     * 
     * @type {PipeSystemInsulationSeriesKindDto}
     * @memberof PipeSolutionKeysDto
     */
    pipeSystemInsulationSeriesKind?: PipeSystemInsulationSeriesKindDto;
    /**
     * 
     * @type {number}
     * @memberof PipeSolutionKeysDto
     */
    lambdaValue?: number;
}
/**
 * 
 * @export
 * @interface PipeSystemDiameterByTrenchDistanceInput
 */
export interface PipeSystemDiameterByTrenchDistanceInput {
    /**
     * 
     * @type {string}
     * @memberof PipeSystemDiameterByTrenchDistanceInput
     */
    pipeSystemDiameterId?: string;
    /**
     * 
     * @type {number}
     * @memberof PipeSystemDiameterByTrenchDistanceInput
     */
    trenchDistance?: number;
}
/**
 * 
 * @export
 * @interface PipeSystemDiameterDimensionDto
 */
export interface PipeSystemDiameterDimensionDto {
    /**
     * 
     * @type {string}
     * @memberof PipeSystemDiameterDimensionDto
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof PipeSystemDiameterDimensionDto
     */
    singleMediaPairPreInsulatedPipeFittingFactor?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PipeSystemDiameterDimensionDto
     */
    twinMediaPreInsulatedPipeFittingFactor?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PipeSystemDiameterDimensionDto
     */
    comment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PipeSystemDiameterDimensionDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof PipeSystemDiameterDimensionDto
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof PipeSystemDiameterDimensionDto
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof PipeSystemDiameterDimensionDto
     */
    updatedBy?: string;
    /**
     * 
     * @type {Array<PipeSystemPreInsulatedPipeKindDimensionDto>}
     * @memberof PipeSystemDiameterDimensionDto
     */
    pipeSystemPreInsulatedPipeKindDimensions?: Array<PipeSystemPreInsulatedPipeKindDimensionDto>;
}
/**
 * 
 * @export
 * @interface PipeSystemDto
 */
export interface PipeSystemDto {
    /**
     * 
     * @type {string}
     * @memberof PipeSystemDto
     */
    id?: string;
    /**
     * 
     * @type {PipeSystemKindDto}
     * @memberof PipeSystemDto
     */
    pipeSystemKind?: PipeSystemKindDto;
    /**
     * 
     * @type {PipeSystemPriceFactorsDto}
     * @memberof PipeSystemDto
     */
    pipeSystemPriceFactors?: PipeSystemPriceFactorsDto;
    /**
     * 
     * @type {string}
     * @memberof PipeSystemDto
     */
    comment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PipeSystemDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof PipeSystemDto
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof PipeSystemDto
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof PipeSystemDto
     */
    updatedBy?: string;
    /**
     * 
     * @type {Array<PipeSystemDiameterDimensionDto>}
     * @memberof PipeSystemDto
     */
    pipeSystemDiameterDimensions?: Array<PipeSystemDiameterDimensionDto>;
}
/**
 * 
 * @export
 * @interface PipeSystemInsulationSeriesDimensionDto
 */
export interface PipeSystemInsulationSeriesDimensionDto {
    /**
     * 
     * @type {string}
     * @memberof PipeSystemInsulationSeriesDimensionDto
     */
    id?: string;
    /**
     * 
     * @type {PipeSystemInsulationSeriesKindDto}
     * @memberof PipeSystemInsulationSeriesDimensionDto
     */
    pipeSystemInsulationSeriesKind?: PipeSystemInsulationSeriesKindDto;
    /**
     * 
     * @type {string}
     * @memberof PipeSystemInsulationSeriesDimensionDto
     */
    comment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PipeSystemInsulationSeriesDimensionDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof PipeSystemInsulationSeriesDimensionDto
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof PipeSystemInsulationSeriesDimensionDto
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof PipeSystemInsulationSeriesDimensionDto
     */
    updatedBy?: string;
    /**
     * 
     * @type {Array<PipeSystemPreInsulatedPipeDimensionDto>}
     * @memberof PipeSystemInsulationSeriesDimensionDto
     */
    prePipes?: Array<PipeSystemPreInsulatedPipeDimensionDto>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum PipeSystemInsulationSeriesKindDto {
    None = 'None',
    Series1 = 'Series1',
    Series2 = 'Series2',
    Series3 = 'Series3',
    Series4 = 'Series4'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum PipeSystemKindDto {
    None = 'None',
    BondedSteel = 'BondedSteel',
    PexFlextra = 'PexFlextra',
    AluFlextra = 'AluFlextra',
    CuFlex = 'CuFlex',
    PertFlextra = 'PertFlextra',
}

/**
 * 
 * @export
 * @interface PipeSystemOperatingLimitsInputDto
 */
export interface PipeSystemOperatingLimitsInputDto {
    /**
     * 
     * @type {number}
     * @memberof PipeSystemOperatingLimitsInputDto
     */
    maxMediaPressure?: number;
    /**
     * 
     * @type {number}
     * @memberof PipeSystemOperatingLimitsInputDto
     */
    minMediaTemperature?: number;
    /**
     * 
     * @type {number}
     * @memberof PipeSystemOperatingLimitsInputDto
     */
    maxMediaTemperature?: number;
}
/**
 * 
 * @export
 * @interface PipeSystemPreInsulatedPipeDimensionDto
 */
export interface PipeSystemPreInsulatedPipeDimensionDto {
    /**
     * 
     * @type {string}
     * @memberof PipeSystemPreInsulatedPipeDimensionDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PipeSystemPreInsulatedPipeDimensionDto
     */
    singleMediaPairPreInsulatedPipeId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PipeSystemPreInsulatedPipeDimensionDto
     */
    twinMediaPreInsulatedPipeId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PipeSystemPreInsulatedPipeDimensionDto
     */
    comment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PipeSystemPreInsulatedPipeDimensionDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof PipeSystemPreInsulatedPipeDimensionDto
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof PipeSystemPreInsulatedPipeDimensionDto
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof PipeSystemPreInsulatedPipeDimensionDto
     */
    updatedBy?: string;
}
/**
 * 
 * @export
 * @interface PipeSystemPreInsulatedPipeKindDimensionDto
 */
export interface PipeSystemPreInsulatedPipeKindDimensionDto {
    /**
     * 
     * @type {string}
     * @memberof PipeSystemPreInsulatedPipeKindDimensionDto
     */
    id?: string;
    /**
     * 
     * @type {PipeSystemPreInsulatedPipeKindDto}
     * @memberof PipeSystemPreInsulatedPipeKindDimensionDto
     */
    pipeSystemPreInsulatedPipeKind?: PipeSystemPreInsulatedPipeKindDto;
    /**
     * 
     * @type {PipeSystemPreInsulatedPipeKindOperatingLimitsDto}
     * @memberof PipeSystemPreInsulatedPipeKindDimensionDto
     */
    pipeSystemPreInsulatedPipeKindOperatingLimits?: PipeSystemPreInsulatedPipeKindOperatingLimitsDto;
    /**
     * 
     * @type {string}
     * @memberof PipeSystemPreInsulatedPipeKindDimensionDto
     */
    comment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PipeSystemPreInsulatedPipeKindDimensionDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof PipeSystemPreInsulatedPipeKindDimensionDto
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof PipeSystemPreInsulatedPipeKindDimensionDto
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof PipeSystemPreInsulatedPipeKindDimensionDto
     */
    updatedBy?: string;
    /**
     * 
     * @type {Array<PipeSystemInsulationSeriesDimensionDto>}
     * @memberof PipeSystemPreInsulatedPipeKindDimensionDto
     */
    pipeSystemInsulationSeriesDimensions?: Array<PipeSystemInsulationSeriesDimensionDto>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum PipeSystemPreInsulatedPipeKindDto {
    None = 'None',
    TraditionalPairOfPipeWithoutDiffusionBarrier = 'TraditionalPairOfPipeWithoutDiffusionBarrier',
    ContiPairOfPipeWithAndWithoutDiffusionBarrier = 'ContiPairOfPipeWithAndWithoutDiffusionBarrier',
    ContiPairOfPipeWithDiffusionBarrier = 'ContiPairOfPipeWithDiffusionBarrier',
    TraditionalTwinPipeWithoutDiffusionBarrier = 'TraditionalTwinPipeWithoutDiffusionBarrier',
    ContiTwinPipeWithDiffusionBarrier = 'ContiTwinPipeWithDiffusionBarrier',
    PexFlextraPairOfPipe = 'PexFlextraPairOfPipe',
    PexFlextraTwinPipe = 'PexFlextraTwinPipe',
    AluFlextraPairOfPipe = 'AluFlextraPairOfPipe',
    AluFlextraTwinPipe = 'AluFlextraTwinPipe',
    CuFlexPairOfPipe = 'CuFlexPairOfPipe',
    CuFlexTwinPipe = 'CuFlexTwinPipe',
    PertFlextraPairOfPipe = 'PertFlextraPairOfPipe',
    PertFlextraTwinPipe = 'PertFlextraTwinPipe',
}

/**
 * 
 * @export
 * @interface PipeSystemPreInsulatedPipeKindOperatingLimitsDto
 */
export interface PipeSystemPreInsulatedPipeKindOperatingLimitsDto {
    /**
     * 
     * @type {number}
     * @memberof PipeSystemPreInsulatedPipeKindOperatingLimitsDto
     */
    maxMediaPressure?: number;
    /**
     * 
     * @type {number}
     * @memberof PipeSystemPreInsulatedPipeKindOperatingLimitsDto
     */
    minMediaTemperature?: number;
    /**
     * 
     * @type {number}
     * @memberof PipeSystemPreInsulatedPipeKindOperatingLimitsDto
     */
    maxMediaTemperature?: number;
}
/**
 * 
 * @export
 * @interface PipeSystemPriceFactorsDto
 */
export interface PipeSystemPriceFactorsDto {
    /**
     * 
     * @type {number}
     * @memberof PipeSystemPriceFactorsDto
     */
    grossPriceNetPriceFactor?: number;
    /**
     * 
     * @type {number}
     * @memberof PipeSystemPriceFactorsDto
     */
    jointsPriceFactor?: number;
    /**
     * 
     * @type {number}
     * @memberof PipeSystemPriceFactorsDto
     */
    materialPriceFactor?: number;
    /**
     * 
     * @type {number}
     * @memberof PipeSystemPriceFactorsDto
     */
    excavationPriceFactor?: number;
    /**
     * 
     * @type {number}
     * @memberof PipeSystemPriceFactorsDto
     */
    installationPriceFactor?: number;
    /**
     * 
     * @type {PipeSystemPreInsulatedPipeKindDto}
     * @memberof PipeSystemPriceFactorsDto
     */
    pipeSystemPreInsulatedPipeKind?: PipeSystemPreInsulatedPipeKindDto;
    /**
     * 
     * @type {PipeSystemInsulationSeriesKindDto}
     * @memberof PipeSystemPriceFactorsDto
     */
    pipeSystemInsulationSeriesKind?: PipeSystemInsulationSeriesKindDto;
}
/**
 * 
 * @export
 * @interface PipeSystemPriceFactorsInputDto
 */
export interface PipeSystemPriceFactorsInputDto {
    /**
     * 
     * @type {number}
     * @memberof PipeSystemPriceFactorsInputDto
     */
    grossPriceNet?: number;
    /**
     * 
     * @type {number}
     * @memberof PipeSystemPriceFactorsInputDto
     */
    joints?: number;
    /**
     * 
     * @type {number}
     * @memberof PipeSystemPriceFactorsInputDto
     */
    material?: number;
    /**
     * 
     * @type {number}
     * @memberof PipeSystemPriceFactorsInputDto
     */
    installation?: number;
    /**
     * 
     * @type {number}
     * @memberof PipeSystemPriceFactorsInputDto
     */
    excavation?: number;
    /**
     * 
     * @type {PipeSystemPreInsulatedPipeKindDto}
     * @memberof PipeSystemPriceFactorsInputDto
     */
    pipeSystemPreInsulatedPipeKind?: PipeSystemPreInsulatedPipeKindDto;
    /**
     * 
     * @type {PipeSystemInsulationSeriesKindDto}
     * @memberof PipeSystemPriceFactorsInputDto
     */
    pipeSystemPipeSystemInsulationSeriesKind?: PipeSystemInsulationSeriesKindDto;
}
/**
 * 
 * @export
 * @interface QueryData
 */
export interface QueryData {
    /**
     * 
     * @type {DesignData}
     * @memberof QueryData
     */
    designData?: DesignData;
    /**
     * 
     * @type {CalculationData}
     * @memberof QueryData
     */
    calculationData?: CalculationData;
    /**
     * 
     * @type {EmissionData}
     * @memberof QueryData
     */
    emissionData?: EmissionData;
    /**
     * 
     * @type {Array<PipeSystemDiameterByTrenchDistanceInput>}
     * @memberof QueryData
     */
    bondedPipeSystemsPipeSystemDiameterByTrenchDistance?: Array<PipeSystemDiameterByTrenchDistanceInput>;
    /**
     * 
     * @type {Array<PipeSystemDiameterByTrenchDistanceInput>}
     * @memberof QueryData
     */
    flexPipeSystemsPipeSystemDiameterByTrenchDistance?: Array<PipeSystemDiameterByTrenchDistanceInput>;
    /**
     * 
     * @type {ComplexityData}
     * @memberof QueryData
     */
    complexityData?: ComplexityData;
}
/**
 * 
 * @export
 * @interface RenameSavedQueryCommand
 */
export interface RenameSavedQueryCommand {
    /**
     * 
     * @type {string}
     * @memberof RenameSavedQueryCommand
     */
    savedQueryId?: string;
    /**
     * 
     * @type {string}
     * @memberof RenameSavedQueryCommand
     */
    newName?: string;
}
/**
 * 
 * @export
 * @interface SavedQueryDto
 */
export interface SavedQueryDto {
    /**
     * 
     * @type {string}
     * @memberof SavedQueryDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SavedQueryDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof SavedQueryDto
     */
    timestamp?: string;
    /**
     * 
     * @type {QueryData}
     * @memberof SavedQueryDto
     */
    queryData?: QueryData;
}
/**
 * 
 * @export
 * @interface SingleMediaPairPreInsulatedPipeDto
 */
export interface SingleMediaPairPreInsulatedPipeDto {
    /**
     * 
     * @type {string}
     * @memberof SingleMediaPairPreInsulatedPipeDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SingleMediaPairPreInsulatedPipeDto
     */
    mediaPipeId?: string;
    /**
     * 
     * @type {string}
     * @memberof SingleMediaPairPreInsulatedPipeDto
     */
    casingPipeId?: string;
    /**
     * 
     * @type {DiffusionBarrierKindDto}
     * @memberof SingleMediaPairPreInsulatedPipeDto
     */
    diffusionBarrierKind?: DiffusionBarrierKindDto;
    /**
     * 
     * @type {string}
     * @memberof SingleMediaPairPreInsulatedPipeDto
     */
    sapId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SingleMediaPairPreInsulatedPipeDto
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof SingleMediaPairPreInsulatedPipeDto
     */
    unitLength?: number;
    /**
     * 
     * @type {number}
     * @memberof SingleMediaPairPreInsulatedPipeDto
     */
    unitPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof SingleMediaPairPreInsulatedPipeDto
     */
    comment?: string | null;
    /**
     * 
     * @type {Array<LambdaYearValueOutputDto>}
     * @memberof SingleMediaPairPreInsulatedPipeDto
     */
    lambdaYearValues?: Array<LambdaYearValueOutputDto>;
    /**
     * 
     * @type {string}
     * @memberof SingleMediaPairPreInsulatedPipeDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof SingleMediaPairPreInsulatedPipeDto
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof SingleMediaPairPreInsulatedPipeDto
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof SingleMediaPairPreInsulatedPipeDto
     */
    updatedBy?: string;
}
/**
 * 
 * @export
 * @interface TcoCalculationGroupDto
 */
export interface TcoCalculationGroupDto {
    /**
     * 
     * @type {PipeSystemKindDto}
     * @memberof TcoCalculationGroupDto
     */
    pipeSystemKind?: PipeSystemKindDto;
    /**
     * 
     * @type {Array<number>}
     * @memberof TcoCalculationGroupDto
     */
    nominalDiameters?: Array<number>;
    /**
     * 
     * @type {Array<TcoCalculationItemDto>}
     * @memberof TcoCalculationGroupDto
     */
    systems?: Array<TcoCalculationItemDto>;
}
/**
 * 
 * @export
 * @interface TcoCalculationItemDto
 */
export interface TcoCalculationItemDto {
    /**
     * 
     * @type {PipeSolutionKeysDto}
     * @memberof TcoCalculationItemDto
     */
    pipeSolutionKeys?: PipeSolutionKeysDto;
    /**
     * 
     * @type {InsulationSeriesPriceDto}
     * @memberof TcoCalculationItemDto
     */
    insulationSeriesPrice?: InsulationSeriesPriceDto;
    /**
     * 
     * @type {EnergyLossFirstYearDto}
     * @memberof TcoCalculationItemDto
     */
    energyLossFirstYear?: EnergyLossFirstYearDto;
    /**
     * 
     * @type {EmissionComparisonDto}
     * @memberof TcoCalculationItemDto
     */
    emissionComparison?: EmissionComparisonDto;
}
/**
 * 
 * @export
 * @interface TwinMediaPreInsulatedPipeDto
 */
export interface TwinMediaPreInsulatedPipeDto {
    /**
     * 
     * @type {string}
     * @memberof TwinMediaPreInsulatedPipeDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof TwinMediaPreInsulatedPipeDto
     */
    mediaPipe1Id?: string;
    /**
     * 
     * @type {string}
     * @memberof TwinMediaPreInsulatedPipeDto
     */
    mediaPipe2Id?: string;
    /**
     * 
     * @type {string}
     * @memberof TwinMediaPreInsulatedPipeDto
     */
    casingPipeId?: string;
    /**
     * 
     * @type {DiffusionBarrierKindDto}
     * @memberof TwinMediaPreInsulatedPipeDto
     */
    diffusionBarrierKind?: DiffusionBarrierKindDto;
    /**
     * 
     * @type {number}
     * @memberof TwinMediaPreInsulatedPipeDto
     */
    distanceBetweenMediaPipes?: number;
    /**
     * 
     * @type {string}
     * @memberof TwinMediaPreInsulatedPipeDto
     */
    sapId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TwinMediaPreInsulatedPipeDto
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof TwinMediaPreInsulatedPipeDto
     */
    unitLength?: number;
    /**
     * 
     * @type {number}
     * @memberof TwinMediaPreInsulatedPipeDto
     */
    unitPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof TwinMediaPreInsulatedPipeDto
     */
    comment?: string | null;
    /**
     * 
     * @type {Array<LambdaYearValueOutputDto>}
     * @memberof TwinMediaPreInsulatedPipeDto
     */
    lambdaYearValues?: Array<LambdaYearValueOutputDto>;
    /**
     * 
     * @type {string}
     * @memberof TwinMediaPreInsulatedPipeDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof TwinMediaPreInsulatedPipeDto
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof TwinMediaPreInsulatedPipeDto
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof TwinMediaPreInsulatedPipeDto
     */
    updatedBy?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum UnitKindDto {
    None = 'None',
    Mwh = 'MWh',
    Gj = 'GJ'
}

/**
 * 
 * @export
 * @interface UnitPricePerMeterTrenchDto
 */
export interface UnitPricePerMeterTrenchDto {
    /**
     * 
     * @type {number}
     * @memberof UnitPricePerMeterTrenchDto
     */
    pipeMaterial?: number;
    /**
     * 
     * @type {number}
     * @memberof UnitPricePerMeterTrenchDto
     */
    trenchExcavation?: number;
    /**
     * 
     * @type {number}
     * @memberof UnitPricePerMeterTrenchDto
     */
    pipeInstallation?: number;
    /**
     * 
     * @type {number}
     * @memberof UnitPricePerMeterTrenchDto
     */
    total?: number;
}
/**
 * 
 * @export
 * @interface UpdateCurrencyDto
 */
export interface UpdateCurrencyDto {
    /**
     * 
     * @type {CurrencyCodeKindDto}
     * @memberof UpdateCurrencyDto
     */
    code?: CurrencyCodeKindDto;
    /**
     * 
     * @type {number}
     * @memberof UpdateCurrencyDto
     */
    rate?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateCurrencyDto
     */
    comment?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateSavedQueryCommand
 */
export interface UpdateSavedQueryCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateSavedQueryCommand
     */
    savedQueryId?: string;
    /**
     * 
     * @type {QueryData}
     * @memberof UpdateSavedQueryCommand
     */
    queryData?: QueryData;
}
/**
 * 
 * @export
 * @interface YearlyPriceDto
 */
export interface YearlyPriceDto {
    /**
     * 
     * @type {number}
     * @memberof YearlyPriceDto
     */
    year?: number;
    /**
     * 
     * @type {number}
     * @memberof YearlyPriceDto
     */
    price?: number;
}

/**
 * CalculationApi - axios parameter creator
 * @export
 */
export const CalculationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CompareSystemsQuery} compareSystemsQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculationCompareSystems: async (compareSystemsQuery: CompareSystemsQuery, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'compareSystemsQuery' is not null or undefined
            assertParamExists('calculationCompareSystems', 'compareSystemsQuery', compareSystemsQuery)
            const localVarPath = `/api/Calculation/CompareSystems`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(compareSystemsQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteSavedQueryCommand} deleteSavedQueryCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculationDeleteSavedQuery: async (deleteSavedQueryCommand: DeleteSavedQueryCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteSavedQueryCommand' is not null or undefined
            assertParamExists('calculationDeleteSavedQuery', 'deleteSavedQueryCommand', deleteSavedQueryCommand)
            const localVarPath = `/api/Calculation/DeleteSavedQuery`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteSavedQueryCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculationGetCurrencies: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Calculation/currencies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculationGetEmissionSources: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Calculation/emissionSources`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculationGetSavedQueries: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Calculation/GetSavedQueries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculationGetSavedQuery: async (id?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Calculation/GetSavedQuery`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PerformCalculationQuery} performCalculationQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculationPost: async (performCalculationQuery: PerformCalculationQuery, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'performCalculationQuery' is not null or undefined
            assertParamExists('calculationPost', 'performCalculationQuery', performCalculationQuery)
            const localVarPath = `/api/Calculation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(performCalculationQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetFilteredPipeSystemDiametersQuery} getFilteredPipeSystemDiametersQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculationPostAll: async (getFilteredPipeSystemDiametersQuery: GetFilteredPipeSystemDiametersQuery, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'getFilteredPipeSystemDiametersQuery' is not null or undefined
            assertParamExists('calculationPostAll', 'getFilteredPipeSystemDiametersQuery', getFilteredPipeSystemDiametersQuery)
            const localVarPath = `/api/Calculation/filterPipeSystemDiameters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getFilteredPipeSystemDiametersQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RenameSavedQueryCommand} renameSavedQueryCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculationRenameSavedQuery: async (renameSavedQueryCommand: RenameSavedQueryCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'renameSavedQueryCommand' is not null or undefined
            assertParamExists('calculationRenameSavedQuery', 'renameSavedQueryCommand', renameSavedQueryCommand)
            const localVarPath = `/api/Calculation/RenameSavedQuery`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(renameSavedQueryCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateSavedQueryCommand} createSavedQueryCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculationSaveQuery: async (createSavedQueryCommand: CreateSavedQueryCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSavedQueryCommand' is not null or undefined
            assertParamExists('calculationSaveQuery', 'createSavedQueryCommand', createSavedQueryCommand)
            const localVarPath = `/api/Calculation/SaveQuery`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSavedQueryCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateSavedQueryCommand} updateSavedQueryCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculationUpdateSavedQuery: async (updateSavedQueryCommand: UpdateSavedQueryCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateSavedQueryCommand' is not null or undefined
            assertParamExists('calculationUpdateSavedQuery', 'updateSavedQueryCommand', updateSavedQueryCommand)
            const localVarPath = `/api/Calculation/UpdateSavedQuery`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSavedQueryCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CalculationApi - functional programming interface
 * @export
 */
export const CalculationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CalculationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CompareSystemsQuery} compareSystemsQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculationCompareSystems(compareSystemsQuery: CompareSystemsQuery, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompareSystemsResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculationCompareSystems(compareSystemsQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeleteSavedQueryCommand} deleteSavedQueryCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculationDeleteSavedQuery(deleteSavedQueryCommand: DeleteSavedQueryCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculationDeleteSavedQuery(deleteSavedQueryCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculationGetCurrencies(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CurrencyDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculationGetCurrencies(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculationGetEmissionSources(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmissionSourceDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculationGetEmissionSources(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculationGetSavedQueries(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SavedQueryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculationGetSavedQueries(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculationGetSavedQuery(id?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SavedQueryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculationGetSavedQuery(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PerformCalculationQuery} performCalculationQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculationPost(performCalculationQuery: PerformCalculationQuery, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalculationResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculationPost(performCalculationQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GetFilteredPipeSystemDiametersQuery} getFilteredPipeSystemDiametersQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculationPostAll(getFilteredPipeSystemDiametersQuery: GetFilteredPipeSystemDiametersQuery, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FilteredPipeSystemDiameterDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculationPostAll(getFilteredPipeSystemDiametersQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RenameSavedQueryCommand} renameSavedQueryCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculationRenameSavedQuery(renameSavedQueryCommand: RenameSavedQueryCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculationRenameSavedQuery(renameSavedQueryCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateSavedQueryCommand} createSavedQueryCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculationSaveQuery(createSavedQueryCommand: CreateSavedQueryCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculationSaveQuery(createSavedQueryCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateSavedQueryCommand} updateSavedQueryCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculationUpdateSavedQuery(updateSavedQueryCommand: UpdateSavedQueryCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculationUpdateSavedQuery(updateSavedQueryCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CalculationApi - factory interface
 * @export
 */
export const CalculationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CalculationApiFp(configuration)
    return {
        /**
         * 
         * @param {CompareSystemsQuery} compareSystemsQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculationCompareSystems(compareSystemsQuery: CompareSystemsQuery, options?: any): AxiosPromise<CompareSystemsResultDto> {
            return localVarFp.calculationCompareSystems(compareSystemsQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteSavedQueryCommand} deleteSavedQueryCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculationDeleteSavedQuery(deleteSavedQueryCommand: DeleteSavedQueryCommand, options?: any): AxiosPromise<string> {
            return localVarFp.calculationDeleteSavedQuery(deleteSavedQueryCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculationGetCurrencies(options?: any): AxiosPromise<Array<CurrencyDto>> {
            return localVarFp.calculationGetCurrencies(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculationGetEmissionSources(options?: any): AxiosPromise<Array<EmissionSourceDto>> {
            return localVarFp.calculationGetEmissionSources(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculationGetSavedQueries(options?: any): AxiosPromise<Array<SavedQueryDto>> {
            return localVarFp.calculationGetSavedQueries(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculationGetSavedQuery(id?: string, options?: any): AxiosPromise<SavedQueryDto> {
            return localVarFp.calculationGetSavedQuery(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PerformCalculationQuery} performCalculationQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculationPost(performCalculationQuery: PerformCalculationQuery, options?: any): AxiosPromise<CalculationResultDto> {
            return localVarFp.calculationPost(performCalculationQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetFilteredPipeSystemDiametersQuery} getFilteredPipeSystemDiametersQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculationPostAll(getFilteredPipeSystemDiametersQuery: GetFilteredPipeSystemDiametersQuery, options?: any): AxiosPromise<Array<FilteredPipeSystemDiameterDto>> {
            return localVarFp.calculationPostAll(getFilteredPipeSystemDiametersQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RenameSavedQueryCommand} renameSavedQueryCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculationRenameSavedQuery(renameSavedQueryCommand: RenameSavedQueryCommand, options?: any): AxiosPromise<string> {
            return localVarFp.calculationRenameSavedQuery(renameSavedQueryCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateSavedQueryCommand} createSavedQueryCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculationSaveQuery(createSavedQueryCommand: CreateSavedQueryCommand, options?: any): AxiosPromise<string> {
            return localVarFp.calculationSaveQuery(createSavedQueryCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateSavedQueryCommand} updateSavedQueryCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculationUpdateSavedQuery(updateSavedQueryCommand: UpdateSavedQueryCommand, options?: any): AxiosPromise<string> {
            return localVarFp.calculationUpdateSavedQuery(updateSavedQueryCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CalculationApi - object-oriented interface
 * @export
 * @class CalculationApi
 * @extends {BaseAPI}
 */
export class CalculationApi extends BaseAPI {
    /**
     * 
     * @param {CompareSystemsQuery} compareSystemsQuery 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalculationApi
     */
    public calculationCompareSystems(compareSystemsQuery: CompareSystemsQuery, options?: any) {
        return CalculationApiFp(this.configuration).calculationCompareSystems(compareSystemsQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteSavedQueryCommand} deleteSavedQueryCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalculationApi
     */
    public calculationDeleteSavedQuery(deleteSavedQueryCommand: DeleteSavedQueryCommand, options?: any) {
        return CalculationApiFp(this.configuration).calculationDeleteSavedQuery(deleteSavedQueryCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalculationApi
     */
    public calculationGetCurrencies(options?: any) {
        return CalculationApiFp(this.configuration).calculationGetCurrencies(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalculationApi
     */
    public calculationGetEmissionSources(options?: any) {
        return CalculationApiFp(this.configuration).calculationGetEmissionSources(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalculationApi
     */
    public calculationGetSavedQueries(options?: any) {
        return CalculationApiFp(this.configuration).calculationGetSavedQueries(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalculationApi
     */
    public calculationGetSavedQuery(id?: string, options?: any) {
        return CalculationApiFp(this.configuration).calculationGetSavedQuery(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PerformCalculationQuery} performCalculationQuery 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalculationApi
     */
    public calculationPost(performCalculationQuery: PerformCalculationQuery, options?: any) {
        return CalculationApiFp(this.configuration).calculationPost(performCalculationQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetFilteredPipeSystemDiametersQuery} getFilteredPipeSystemDiametersQuery 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalculationApi
     */
    public calculationPostAll(getFilteredPipeSystemDiametersQuery: GetFilteredPipeSystemDiametersQuery, options?: any) {
        return CalculationApiFp(this.configuration).calculationPostAll(getFilteredPipeSystemDiametersQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RenameSavedQueryCommand} renameSavedQueryCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalculationApi
     */
    public calculationRenameSavedQuery(renameSavedQueryCommand: RenameSavedQueryCommand, options?: any) {
        return CalculationApiFp(this.configuration).calculationRenameSavedQuery(renameSavedQueryCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateSavedQueryCommand} createSavedQueryCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalculationApi
     */
    public calculationSaveQuery(createSavedQueryCommand: CreateSavedQueryCommand, options?: any) {
        return CalculationApiFp(this.configuration).calculationSaveQuery(createSavedQueryCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateSavedQueryCommand} updateSavedQueryCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalculationApi
     */
    public calculationUpdateSavedQuery(updateSavedQueryCommand: UpdateSavedQueryCommand, options?: any) {
        return CalculationApiFp(this.configuration).calculationUpdateSavedQuery(updateSavedQueryCommand, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CasingPipesApi - axios parameter creator
 * @export
 */
export const CasingPipesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        casingPipesGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('casingPipesGet', 'id', id)
            const localVarPath = `/api/CasingPipes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        casingPipesGetAll: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CasingPipes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateCasingPipeDto} createCasingPipeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        casingPipesPost: async (createCasingPipeDto: CreateCasingPipeDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCasingPipeDto' is not null or undefined
            assertParamExists('casingPipesPost', 'createCasingPipeDto', createCasingPipeDto)
            const localVarPath = `/api/CasingPipes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCasingPipeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CasingPipesApi - functional programming interface
 * @export
 */
export const CasingPipesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CasingPipesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async casingPipesGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CasingPipeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.casingPipesGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async casingPipesGetAll(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CasingPipeDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.casingPipesGetAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateCasingPipeDto} createCasingPipeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async casingPipesPost(createCasingPipeDto: CreateCasingPipeDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.casingPipesPost(createCasingPipeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CasingPipesApi - factory interface
 * @export
 */
export const CasingPipesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CasingPipesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        casingPipesGet(id: string, options?: any): AxiosPromise<CasingPipeDto> {
            return localVarFp.casingPipesGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        casingPipesGetAll(options?: any): AxiosPromise<Array<CasingPipeDto>> {
            return localVarFp.casingPipesGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateCasingPipeDto} createCasingPipeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        casingPipesPost(createCasingPipeDto: CreateCasingPipeDto, options?: any): AxiosPromise<string> {
            return localVarFp.casingPipesPost(createCasingPipeDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CasingPipesApi - object-oriented interface
 * @export
 * @class CasingPipesApi
 * @extends {BaseAPI}
 */
export class CasingPipesApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CasingPipesApi
     */
    public casingPipesGet(id: string, options?: any) {
        return CasingPipesApiFp(this.configuration).casingPipesGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CasingPipesApi
     */
    public casingPipesGetAll(options?: any) {
        return CasingPipesApiFp(this.configuration).casingPipesGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateCasingPipeDto} createCasingPipeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CasingPipesApi
     */
    public casingPipesPost(createCasingPipeDto: CreateCasingPipeDto, options?: any) {
        return CasingPipesApiFp(this.configuration).casingPipesPost(createCasingPipeDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ContactApi - axios parameter creator
 * @export
 */
export const ContactApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateLeadCommand} createLeadCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactCreateLead: async (createLeadCommand: CreateLeadCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createLeadCommand' is not null or undefined
            assertParamExists('contactCreateLead', 'createLeadCommand', createLeadCommand)
            const localVarPath = `/api/Contact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createLeadCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContactApi - functional programming interface
 * @export
 */
export const ContactApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContactApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateLeadCommand} createLeadCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contactCreateLead(createLeadCommand: CreateLeadCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contactCreateLead(createLeadCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContactApi - factory interface
 * @export
 */
export const ContactApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContactApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateLeadCommand} createLeadCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactCreateLead(createLeadCommand: CreateLeadCommand, options?: any): AxiosPromise<number> {
            return localVarFp.contactCreateLead(createLeadCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContactApi - object-oriented interface
 * @export
 * @class ContactApi
 * @extends {BaseAPI}
 */
export class ContactApi extends BaseAPI {
    /**
     * 
     * @param {CreateLeadCommand} createLeadCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public contactCreateLead(createLeadCommand: CreateLeadCommand, options?: any) {
        return ContactApiFp(this.configuration).contactCreateLead(createLeadCommand, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CurrenciesApi - axios parameter creator
 * @export
 */
export const CurrenciesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currenciesGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('currenciesGet', 'id', id)
            const localVarPath = `/api/Currencies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currenciesGetAll: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Currencies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateCurrencyDto} createCurrencyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currenciesPost: async (createCurrencyDto: CreateCurrencyDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCurrencyDto' is not null or undefined
            assertParamExists('currenciesPost', 'createCurrencyDto', createCurrencyDto)
            const localVarPath = `/api/Currencies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCurrencyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateCurrencyDto} updateCurrencyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currenciesPut: async (id: string, updateCurrencyDto: UpdateCurrencyDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('currenciesPut', 'id', id)
            // verify required parameter 'updateCurrencyDto' is not null or undefined
            assertParamExists('currenciesPut', 'updateCurrencyDto', updateCurrencyDto)
            const localVarPath = `/api/Currencies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCurrencyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CurrenciesApi - functional programming interface
 * @export
 */
export const CurrenciesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CurrenciesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currenciesGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CurrencyDto2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currenciesGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currenciesGetAll(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CurrencyDto2>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currenciesGetAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateCurrencyDto} createCurrencyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currenciesPost(createCurrencyDto: CreateCurrencyDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currenciesPost(createCurrencyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateCurrencyDto} updateCurrencyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currenciesPut(id: string, updateCurrencyDto: UpdateCurrencyDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currenciesPut(id, updateCurrencyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CurrenciesApi - factory interface
 * @export
 */
export const CurrenciesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CurrenciesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currenciesGet(id: string, options?: any): AxiosPromise<CurrencyDto2> {
            return localVarFp.currenciesGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currenciesGetAll(options?: any): AxiosPromise<Array<CurrencyDto2>> {
            return localVarFp.currenciesGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateCurrencyDto} createCurrencyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currenciesPost(createCurrencyDto: CreateCurrencyDto, options?: any): AxiosPromise<string> {
            return localVarFp.currenciesPost(createCurrencyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateCurrencyDto} updateCurrencyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currenciesPut(id: string, updateCurrencyDto: UpdateCurrencyDto, options?: any): AxiosPromise<void> {
            return localVarFp.currenciesPut(id, updateCurrencyDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CurrenciesApi - object-oriented interface
 * @export
 * @class CurrenciesApi
 * @extends {BaseAPI}
 */
export class CurrenciesApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrenciesApi
     */
    public currenciesGet(id: string, options?: any) {
        return CurrenciesApiFp(this.configuration).currenciesGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrenciesApi
     */
    public currenciesGetAll(options?: any) {
        return CurrenciesApiFp(this.configuration).currenciesGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateCurrencyDto} createCurrencyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrenciesApi
     */
    public currenciesPost(createCurrencyDto: CreateCurrencyDto, options?: any) {
        return CurrenciesApiFp(this.configuration).currenciesPost(createCurrencyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateCurrencyDto} updateCurrencyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrenciesApi
     */
    public currenciesPut(id: string, updateCurrencyDto: UpdateCurrencyDto, options?: any) {
        return CurrenciesApiFp(this.configuration).currenciesPut(id, updateCurrencyDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EmissionSourcesApi - axios parameter creator
 * @export
 */
export const EmissionSourcesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emissionSourcesGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('emissionSourcesGet', 'id', id)
            const localVarPath = `/api/EmissionSources/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emissionSourcesGetAll: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/EmissionSources`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateEmissionSourceDto} createEmissionSourceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emissionSourcesPost: async (createEmissionSourceDto: CreateEmissionSourceDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createEmissionSourceDto' is not null or undefined
            assertParamExists('emissionSourcesPost', 'createEmissionSourceDto', createEmissionSourceDto)
            const localVarPath = `/api/EmissionSources`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createEmissionSourceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmissionSourcesApi - functional programming interface
 * @export
 */
export const EmissionSourcesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmissionSourcesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emissionSourcesGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmissionSourceDto2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emissionSourcesGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emissionSourcesGetAll(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmissionSourceDto2>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emissionSourcesGetAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateEmissionSourceDto} createEmissionSourceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emissionSourcesPost(createEmissionSourceDto: CreateEmissionSourceDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emissionSourcesPost(createEmissionSourceDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EmissionSourcesApi - factory interface
 * @export
 */
export const EmissionSourcesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmissionSourcesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emissionSourcesGet(id: string, options?: any): AxiosPromise<EmissionSourceDto2> {
            return localVarFp.emissionSourcesGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emissionSourcesGetAll(options?: any): AxiosPromise<Array<EmissionSourceDto2>> {
            return localVarFp.emissionSourcesGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateEmissionSourceDto} createEmissionSourceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emissionSourcesPost(createEmissionSourceDto: CreateEmissionSourceDto, options?: any): AxiosPromise<string> {
            return localVarFp.emissionSourcesPost(createEmissionSourceDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmissionSourcesApi - object-oriented interface
 * @export
 * @class EmissionSourcesApi
 * @extends {BaseAPI}
 */
export class EmissionSourcesApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmissionSourcesApi
     */
    public emissionSourcesGet(id: string, options?: any) {
        return EmissionSourcesApiFp(this.configuration).emissionSourcesGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmissionSourcesApi
     */
    public emissionSourcesGetAll(options?: any) {
        return EmissionSourcesApiFp(this.configuration).emissionSourcesGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateEmissionSourceDto} createEmissionSourceDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmissionSourcesApi
     */
    public emissionSourcesPost(createEmissionSourceDto: CreateEmissionSourceDto, options?: any) {
        return EmissionSourcesApiFp(this.configuration).emissionSourcesPost(createEmissionSourceDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InfoApi - axios parameter creator
 * @export
 */
export const InfoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        infoGetVersion: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Info/GetVersion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        infoGetVersionLong: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Info/GetVersionLong`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InfoApi - functional programming interface
 * @export
 */
export const InfoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InfoApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async infoGetVersion(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.infoGetVersion(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async infoGetVersionLong(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.infoGetVersionLong(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InfoApi - factory interface
 * @export
 */
export const InfoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InfoApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        infoGetVersion(options?: any): AxiosPromise<string> {
            return localVarFp.infoGetVersion(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        infoGetVersionLong(options?: any): AxiosPromise<string> {
            return localVarFp.infoGetVersionLong(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InfoApi - object-oriented interface
 * @export
 * @class InfoApi
 * @extends {BaseAPI}
 */
export class InfoApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InfoApi
     */
    public infoGetVersion(options?: any) {
        return InfoApiFp(this.configuration).infoGetVersion(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InfoApi
     */
    public infoGetVersionLong(options?: any) {
        return InfoApiFp(this.configuration).infoGetVersionLong(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MaterialsApi - axios parameter creator
 * @export
 */
export const MaterialsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {AddMaterialPropertyDto} addMaterialPropertyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialsAddMaterial: async (id: string, addMaterialPropertyDto: AddMaterialPropertyDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('materialsAddMaterial', 'id', id)
            // verify required parameter 'addMaterialPropertyDto' is not null or undefined
            assertParamExists('materialsAddMaterial', 'addMaterialPropertyDto', addMaterialPropertyDto)
            const localVarPath = `/api/Materials/{id}/properties`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addMaterialPropertyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialsGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('materialsGet', 'id', id)
            const localVarPath = `/api/Materials/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialsGetAll: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Materials`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateMaterialDto} createMaterialDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialsPost: async (createMaterialDto: CreateMaterialDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createMaterialDto' is not null or undefined
            assertParamExists('materialsPost', 'createMaterialDto', createMaterialDto)
            const localVarPath = `/api/Materials`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMaterialDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MaterialsApi - functional programming interface
 * @export
 */
export const MaterialsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MaterialsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {AddMaterialPropertyDto} addMaterialPropertyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async materialsAddMaterial(id: string, addMaterialPropertyDto: AddMaterialPropertyDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.materialsAddMaterial(id, addMaterialPropertyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async materialsGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaterialDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.materialsGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async materialsGetAll(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MaterialDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.materialsGetAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateMaterialDto} createMaterialDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async materialsPost(createMaterialDto: CreateMaterialDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.materialsPost(createMaterialDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MaterialsApi - factory interface
 * @export
 */
export const MaterialsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MaterialsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {AddMaterialPropertyDto} addMaterialPropertyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialsAddMaterial(id: string, addMaterialPropertyDto: AddMaterialPropertyDto, options?: any): AxiosPromise<string> {
            return localVarFp.materialsAddMaterial(id, addMaterialPropertyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialsGet(id: string, options?: any): AxiosPromise<MaterialDto> {
            return localVarFp.materialsGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialsGetAll(options?: any): AxiosPromise<Array<MaterialDto>> {
            return localVarFp.materialsGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateMaterialDto} createMaterialDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialsPost(createMaterialDto: CreateMaterialDto, options?: any): AxiosPromise<string> {
            return localVarFp.materialsPost(createMaterialDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MaterialsApi - object-oriented interface
 * @export
 * @class MaterialsApi
 * @extends {BaseAPI}
 */
export class MaterialsApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {AddMaterialPropertyDto} addMaterialPropertyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialsApi
     */
    public materialsAddMaterial(id: string, addMaterialPropertyDto: AddMaterialPropertyDto, options?: any) {
        return MaterialsApiFp(this.configuration).materialsAddMaterial(id, addMaterialPropertyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialsApi
     */
    public materialsGet(id: string, options?: any) {
        return MaterialsApiFp(this.configuration).materialsGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialsApi
     */
    public materialsGetAll(options?: any) {
        return MaterialsApiFp(this.configuration).materialsGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateMaterialDto} createMaterialDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialsApi
     */
    public materialsPost(createMaterialDto: CreateMaterialDto, options?: any) {
        return MaterialsApiFp(this.configuration).materialsPost(createMaterialDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MediaPipesApi - axios parameter creator
 * @export
 */
export const MediaPipesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mediaPipesGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('mediaPipesGet', 'id', id)
            const localVarPath = `/api/MediaPipes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mediaPipesGetAll: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/MediaPipes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateMediaPipeDto} createMediaPipeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mediaPipesPost: async (createMediaPipeDto: CreateMediaPipeDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createMediaPipeDto' is not null or undefined
            assertParamExists('mediaPipesPost', 'createMediaPipeDto', createMediaPipeDto)
            const localVarPath = `/api/MediaPipes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMediaPipeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MediaPipesApi - functional programming interface
 * @export
 */
export const MediaPipesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MediaPipesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mediaPipesGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MediaPipeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mediaPipesGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mediaPipesGetAll(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MediaPipeDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mediaPipesGetAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateMediaPipeDto} createMediaPipeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mediaPipesPost(createMediaPipeDto: CreateMediaPipeDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mediaPipesPost(createMediaPipeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MediaPipesApi - factory interface
 * @export
 */
export const MediaPipesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MediaPipesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mediaPipesGet(id: string, options?: any): AxiosPromise<MediaPipeDto> {
            return localVarFp.mediaPipesGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mediaPipesGetAll(options?: any): AxiosPromise<Array<MediaPipeDto>> {
            return localVarFp.mediaPipesGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateMediaPipeDto} createMediaPipeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mediaPipesPost(createMediaPipeDto: CreateMediaPipeDto, options?: any): AxiosPromise<string> {
            return localVarFp.mediaPipesPost(createMediaPipeDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MediaPipesApi - object-oriented interface
 * @export
 * @class MediaPipesApi
 * @extends {BaseAPI}
 */
export class MediaPipesApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaPipesApi
     */
    public mediaPipesGet(id: string, options?: any) {
        return MediaPipesApiFp(this.configuration).mediaPipesGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaPipesApi
     */
    public mediaPipesGetAll(options?: any) {
        return MediaPipesApiFp(this.configuration).mediaPipesGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateMediaPipeDto} createMediaPipeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaPipesApi
     */
    public mediaPipesPost(createMediaPipeDto: CreateMediaPipeDto, options?: any) {
        return MediaPipesApiFp(this.configuration).mediaPipesPost(createMediaPipeDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PipeSystemsApi - axios parameter creator
 * @export
 */
export const PipeSystemsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} pipeSystemId 
         * @param {AddPipeSystemDiameterDimensionDto} addPipeSystemDiameterDimensionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pipeSystemsAddPipeSystemDiameterDimension: async (pipeSystemId: string, addPipeSystemDiameterDimensionDto: AddPipeSystemDiameterDimensionDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pipeSystemId' is not null or undefined
            assertParamExists('pipeSystemsAddPipeSystemDiameterDimension', 'pipeSystemId', pipeSystemId)
            // verify required parameter 'addPipeSystemDiameterDimensionDto' is not null or undefined
            assertParamExists('pipeSystemsAddPipeSystemDiameterDimension', 'addPipeSystemDiameterDimensionDto', addPipeSystemDiameterDimensionDto)
            const localVarPath = `/api/PipeSystems/{pipeSystemId}/diameter`
                .replace(`{${"pipeSystemId"}}`, encodeURIComponent(String(pipeSystemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addPipeSystemDiameterDimensionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} pipeSystemId 
         * @param {string} pipeSystemDiameterDimensionId 
         * @param {string} pipeSystemPreInsulatedPipeKindDimensionId 
         * @param {AddPipeSystemInsulationSeriesDimensionDto} addPipeSystemInsulationSeriesDimensionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pipeSystemsAddPipeSystemInsulationSeriesDimension: async (pipeSystemId: string, pipeSystemDiameterDimensionId: string, pipeSystemPreInsulatedPipeKindDimensionId: string, addPipeSystemInsulationSeriesDimensionDto: AddPipeSystemInsulationSeriesDimensionDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pipeSystemId' is not null or undefined
            assertParamExists('pipeSystemsAddPipeSystemInsulationSeriesDimension', 'pipeSystemId', pipeSystemId)
            // verify required parameter 'pipeSystemDiameterDimensionId' is not null or undefined
            assertParamExists('pipeSystemsAddPipeSystemInsulationSeriesDimension', 'pipeSystemDiameterDimensionId', pipeSystemDiameterDimensionId)
            // verify required parameter 'pipeSystemPreInsulatedPipeKindDimensionId' is not null or undefined
            assertParamExists('pipeSystemsAddPipeSystemInsulationSeriesDimension', 'pipeSystemPreInsulatedPipeKindDimensionId', pipeSystemPreInsulatedPipeKindDimensionId)
            // verify required parameter 'addPipeSystemInsulationSeriesDimensionDto' is not null or undefined
            assertParamExists('pipeSystemsAddPipeSystemInsulationSeriesDimension', 'addPipeSystemInsulationSeriesDimensionDto', addPipeSystemInsulationSeriesDimensionDto)
            const localVarPath = `/api/PipeSystems/{pipeSystemId}/diameterDimensions/{pipeSystemDiameterDimensionId}/preInsulatedPipeKindDimensions/{pipeSystemPreInsulatedPipeKindDimensionId}/pipeSystemInsulationSeriesDimensions`
                .replace(`{${"pipeSystemId"}}`, encodeURIComponent(String(pipeSystemId)))
                .replace(`{${"pipeSystemDiameterDimensionId"}}`, encodeURIComponent(String(pipeSystemDiameterDimensionId)))
                .replace(`{${"pipeSystemPreInsulatedPipeKindDimensionId"}}`, encodeURIComponent(String(pipeSystemPreInsulatedPipeKindDimensionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addPipeSystemInsulationSeriesDimensionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} pipeSystemId 
         * @param {string} pipeSystemDiameterDimensionId 
         * @param {string} pipeSystemPreInsulatedPipeKindDimensionId 
         * @param {string} pipeSystemInsulationSeriesDimensionId 
         * @param {AddPipeSystemPreInsulatedPipeDto} addPipeSystemPreInsulatedPipeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pipeSystemsAddPipeSystemPreInsulatedPipeDimension: async (pipeSystemId: string, pipeSystemDiameterDimensionId: string, pipeSystemPreInsulatedPipeKindDimensionId: string, pipeSystemInsulationSeriesDimensionId: string, addPipeSystemPreInsulatedPipeDto: AddPipeSystemPreInsulatedPipeDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pipeSystemId' is not null or undefined
            assertParamExists('pipeSystemsAddPipeSystemPreInsulatedPipeDimension', 'pipeSystemId', pipeSystemId)
            // verify required parameter 'pipeSystemDiameterDimensionId' is not null or undefined
            assertParamExists('pipeSystemsAddPipeSystemPreInsulatedPipeDimension', 'pipeSystemDiameterDimensionId', pipeSystemDiameterDimensionId)
            // verify required parameter 'pipeSystemPreInsulatedPipeKindDimensionId' is not null or undefined
            assertParamExists('pipeSystemsAddPipeSystemPreInsulatedPipeDimension', 'pipeSystemPreInsulatedPipeKindDimensionId', pipeSystemPreInsulatedPipeKindDimensionId)
            // verify required parameter 'pipeSystemInsulationSeriesDimensionId' is not null or undefined
            assertParamExists('pipeSystemsAddPipeSystemPreInsulatedPipeDimension', 'pipeSystemInsulationSeriesDimensionId', pipeSystemInsulationSeriesDimensionId)
            // verify required parameter 'addPipeSystemPreInsulatedPipeDto' is not null or undefined
            assertParamExists('pipeSystemsAddPipeSystemPreInsulatedPipeDimension', 'addPipeSystemPreInsulatedPipeDto', addPipeSystemPreInsulatedPipeDto)
            const localVarPath = `/api/PipeSystems/{pipeSystemId}/diameterDimensions/{pipeSystemDiameterDimensionId}/preInsulatedPipeKindDimensions/{pipeSystemPreInsulatedPipeKindDimensionId}/pipeSystemInsulationSeriesDimensions/{pipeSystemInsulationSeriesDimensionId}/prePipes`
                .replace(`{${"pipeSystemId"}}`, encodeURIComponent(String(pipeSystemId)))
                .replace(`{${"pipeSystemDiameterDimensionId"}}`, encodeURIComponent(String(pipeSystemDiameterDimensionId)))
                .replace(`{${"pipeSystemPreInsulatedPipeKindDimensionId"}}`, encodeURIComponent(String(pipeSystemPreInsulatedPipeKindDimensionId)))
                .replace(`{${"pipeSystemInsulationSeriesDimensionId"}}`, encodeURIComponent(String(pipeSystemInsulationSeriesDimensionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addPipeSystemPreInsulatedPipeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} pipeSystemId 
         * @param {string} pipeSystemDiameterDimensionId 
         * @param {AddPipeSystemPreInsulatedPipeKindDimensionDto} addPipeSystemPreInsulatedPipeKindDimensionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pipeSystemsAddPipeSystemPreInsulatedPipeKindDimension: async (pipeSystemId: string, pipeSystemDiameterDimensionId: string, addPipeSystemPreInsulatedPipeKindDimensionDto: AddPipeSystemPreInsulatedPipeKindDimensionDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pipeSystemId' is not null or undefined
            assertParamExists('pipeSystemsAddPipeSystemPreInsulatedPipeKindDimension', 'pipeSystemId', pipeSystemId)
            // verify required parameter 'pipeSystemDiameterDimensionId' is not null or undefined
            assertParamExists('pipeSystemsAddPipeSystemPreInsulatedPipeKindDimension', 'pipeSystemDiameterDimensionId', pipeSystemDiameterDimensionId)
            // verify required parameter 'addPipeSystemPreInsulatedPipeKindDimensionDto' is not null or undefined
            assertParamExists('pipeSystemsAddPipeSystemPreInsulatedPipeKindDimension', 'addPipeSystemPreInsulatedPipeKindDimensionDto', addPipeSystemPreInsulatedPipeKindDimensionDto)
            const localVarPath = `/api/PipeSystems/{pipeSystemId}/diameterDimensions/{pipeSystemDiameterDimensionId}/preInsulatedPipeKindDimensions`
                .replace(`{${"pipeSystemId"}}`, encodeURIComponent(String(pipeSystemId)))
                .replace(`{${"pipeSystemDiameterDimensionId"}}`, encodeURIComponent(String(pipeSystemDiameterDimensionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addPipeSystemPreInsulatedPipeKindDimensionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreatePipeSystemDto} createPipeSystemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pipeSystemsCreatePipeSystem: async (createPipeSystemDto: CreatePipeSystemDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPipeSystemDto' is not null or undefined
            assertParamExists('pipeSystemsCreatePipeSystem', 'createPipeSystemDto', createPipeSystemDto)
            const localVarPath = `/api/PipeSystems`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPipeSystemDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pipeSystemsGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('pipeSystemsGet', 'id', id)
            const localVarPath = `/api/PipeSystems/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pipeSystemsGetAll: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/PipeSystems`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PipeSystemsApi - functional programming interface
 * @export
 */
export const PipeSystemsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PipeSystemsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} pipeSystemId 
         * @param {AddPipeSystemDiameterDimensionDto} addPipeSystemDiameterDimensionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pipeSystemsAddPipeSystemDiameterDimension(pipeSystemId: string, addPipeSystemDiameterDimensionDto: AddPipeSystemDiameterDimensionDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pipeSystemsAddPipeSystemDiameterDimension(pipeSystemId, addPipeSystemDiameterDimensionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} pipeSystemId 
         * @param {string} pipeSystemDiameterDimensionId 
         * @param {string} pipeSystemPreInsulatedPipeKindDimensionId 
         * @param {AddPipeSystemInsulationSeriesDimensionDto} addPipeSystemInsulationSeriesDimensionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pipeSystemsAddPipeSystemInsulationSeriesDimension(pipeSystemId: string, pipeSystemDiameterDimensionId: string, pipeSystemPreInsulatedPipeKindDimensionId: string, addPipeSystemInsulationSeriesDimensionDto: AddPipeSystemInsulationSeriesDimensionDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pipeSystemsAddPipeSystemInsulationSeriesDimension(pipeSystemId, pipeSystemDiameterDimensionId, pipeSystemPreInsulatedPipeKindDimensionId, addPipeSystemInsulationSeriesDimensionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} pipeSystemId 
         * @param {string} pipeSystemDiameterDimensionId 
         * @param {string} pipeSystemPreInsulatedPipeKindDimensionId 
         * @param {string} pipeSystemInsulationSeriesDimensionId 
         * @param {AddPipeSystemPreInsulatedPipeDto} addPipeSystemPreInsulatedPipeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pipeSystemsAddPipeSystemPreInsulatedPipeDimension(pipeSystemId: string, pipeSystemDiameterDimensionId: string, pipeSystemPreInsulatedPipeKindDimensionId: string, pipeSystemInsulationSeriesDimensionId: string, addPipeSystemPreInsulatedPipeDto: AddPipeSystemPreInsulatedPipeDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pipeSystemsAddPipeSystemPreInsulatedPipeDimension(pipeSystemId, pipeSystemDiameterDimensionId, pipeSystemPreInsulatedPipeKindDimensionId, pipeSystemInsulationSeriesDimensionId, addPipeSystemPreInsulatedPipeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} pipeSystemId 
         * @param {string} pipeSystemDiameterDimensionId 
         * @param {AddPipeSystemPreInsulatedPipeKindDimensionDto} addPipeSystemPreInsulatedPipeKindDimensionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pipeSystemsAddPipeSystemPreInsulatedPipeKindDimension(pipeSystemId: string, pipeSystemDiameterDimensionId: string, addPipeSystemPreInsulatedPipeKindDimensionDto: AddPipeSystemPreInsulatedPipeKindDimensionDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pipeSystemsAddPipeSystemPreInsulatedPipeKindDimension(pipeSystemId, pipeSystemDiameterDimensionId, addPipeSystemPreInsulatedPipeKindDimensionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreatePipeSystemDto} createPipeSystemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pipeSystemsCreatePipeSystem(createPipeSystemDto: CreatePipeSystemDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pipeSystemsCreatePipeSystem(createPipeSystemDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pipeSystemsGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PipeSystemDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pipeSystemsGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pipeSystemsGetAll(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PipeSystemDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pipeSystemsGetAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PipeSystemsApi - factory interface
 * @export
 */
export const PipeSystemsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PipeSystemsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} pipeSystemId 
         * @param {AddPipeSystemDiameterDimensionDto} addPipeSystemDiameterDimensionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pipeSystemsAddPipeSystemDiameterDimension(pipeSystemId: string, addPipeSystemDiameterDimensionDto: AddPipeSystemDiameterDimensionDto, options?: any): AxiosPromise<string> {
            return localVarFp.pipeSystemsAddPipeSystemDiameterDimension(pipeSystemId, addPipeSystemDiameterDimensionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} pipeSystemId 
         * @param {string} pipeSystemDiameterDimensionId 
         * @param {string} pipeSystemPreInsulatedPipeKindDimensionId 
         * @param {AddPipeSystemInsulationSeriesDimensionDto} addPipeSystemInsulationSeriesDimensionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pipeSystemsAddPipeSystemInsulationSeriesDimension(pipeSystemId: string, pipeSystemDiameterDimensionId: string, pipeSystemPreInsulatedPipeKindDimensionId: string, addPipeSystemInsulationSeriesDimensionDto: AddPipeSystemInsulationSeriesDimensionDto, options?: any): AxiosPromise<string> {
            return localVarFp.pipeSystemsAddPipeSystemInsulationSeriesDimension(pipeSystemId, pipeSystemDiameterDimensionId, pipeSystemPreInsulatedPipeKindDimensionId, addPipeSystemInsulationSeriesDimensionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} pipeSystemId 
         * @param {string} pipeSystemDiameterDimensionId 
         * @param {string} pipeSystemPreInsulatedPipeKindDimensionId 
         * @param {string} pipeSystemInsulationSeriesDimensionId 
         * @param {AddPipeSystemPreInsulatedPipeDto} addPipeSystemPreInsulatedPipeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pipeSystemsAddPipeSystemPreInsulatedPipeDimension(pipeSystemId: string, pipeSystemDiameterDimensionId: string, pipeSystemPreInsulatedPipeKindDimensionId: string, pipeSystemInsulationSeriesDimensionId: string, addPipeSystemPreInsulatedPipeDto: AddPipeSystemPreInsulatedPipeDto, options?: any): AxiosPromise<string> {
            return localVarFp.pipeSystemsAddPipeSystemPreInsulatedPipeDimension(pipeSystemId, pipeSystemDiameterDimensionId, pipeSystemPreInsulatedPipeKindDimensionId, pipeSystemInsulationSeriesDimensionId, addPipeSystemPreInsulatedPipeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} pipeSystemId 
         * @param {string} pipeSystemDiameterDimensionId 
         * @param {AddPipeSystemPreInsulatedPipeKindDimensionDto} addPipeSystemPreInsulatedPipeKindDimensionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pipeSystemsAddPipeSystemPreInsulatedPipeKindDimension(pipeSystemId: string, pipeSystemDiameterDimensionId: string, addPipeSystemPreInsulatedPipeKindDimensionDto: AddPipeSystemPreInsulatedPipeKindDimensionDto, options?: any): AxiosPromise<string> {
            return localVarFp.pipeSystemsAddPipeSystemPreInsulatedPipeKindDimension(pipeSystemId, pipeSystemDiameterDimensionId, addPipeSystemPreInsulatedPipeKindDimensionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreatePipeSystemDto} createPipeSystemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pipeSystemsCreatePipeSystem(createPipeSystemDto: CreatePipeSystemDto, options?: any): AxiosPromise<string> {
            return localVarFp.pipeSystemsCreatePipeSystem(createPipeSystemDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pipeSystemsGet(id: string, options?: any): AxiosPromise<PipeSystemDto> {
            return localVarFp.pipeSystemsGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pipeSystemsGetAll(options?: any): AxiosPromise<Array<PipeSystemDto>> {
            return localVarFp.pipeSystemsGetAll(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PipeSystemsApi - object-oriented interface
 * @export
 * @class PipeSystemsApi
 * @extends {BaseAPI}
 */
export class PipeSystemsApi extends BaseAPI {
    /**
     * 
     * @param {string} pipeSystemId 
     * @param {AddPipeSystemDiameterDimensionDto} addPipeSystemDiameterDimensionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipeSystemsApi
     */
    public pipeSystemsAddPipeSystemDiameterDimension(pipeSystemId: string, addPipeSystemDiameterDimensionDto: AddPipeSystemDiameterDimensionDto, options?: any) {
        return PipeSystemsApiFp(this.configuration).pipeSystemsAddPipeSystemDiameterDimension(pipeSystemId, addPipeSystemDiameterDimensionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} pipeSystemId 
     * @param {string} pipeSystemDiameterDimensionId 
     * @param {string} pipeSystemPreInsulatedPipeKindDimensionId 
     * @param {AddPipeSystemInsulationSeriesDimensionDto} addPipeSystemInsulationSeriesDimensionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipeSystemsApi
     */
    public pipeSystemsAddPipeSystemInsulationSeriesDimension(pipeSystemId: string, pipeSystemDiameterDimensionId: string, pipeSystemPreInsulatedPipeKindDimensionId: string, addPipeSystemInsulationSeriesDimensionDto: AddPipeSystemInsulationSeriesDimensionDto, options?: any) {
        return PipeSystemsApiFp(this.configuration).pipeSystemsAddPipeSystemInsulationSeriesDimension(pipeSystemId, pipeSystemDiameterDimensionId, pipeSystemPreInsulatedPipeKindDimensionId, addPipeSystemInsulationSeriesDimensionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} pipeSystemId 
     * @param {string} pipeSystemDiameterDimensionId 
     * @param {string} pipeSystemPreInsulatedPipeKindDimensionId 
     * @param {string} pipeSystemInsulationSeriesDimensionId 
     * @param {AddPipeSystemPreInsulatedPipeDto} addPipeSystemPreInsulatedPipeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipeSystemsApi
     */
    public pipeSystemsAddPipeSystemPreInsulatedPipeDimension(pipeSystemId: string, pipeSystemDiameterDimensionId: string, pipeSystemPreInsulatedPipeKindDimensionId: string, pipeSystemInsulationSeriesDimensionId: string, addPipeSystemPreInsulatedPipeDto: AddPipeSystemPreInsulatedPipeDto, options?: any) {
        return PipeSystemsApiFp(this.configuration).pipeSystemsAddPipeSystemPreInsulatedPipeDimension(pipeSystemId, pipeSystemDiameterDimensionId, pipeSystemPreInsulatedPipeKindDimensionId, pipeSystemInsulationSeriesDimensionId, addPipeSystemPreInsulatedPipeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} pipeSystemId 
     * @param {string} pipeSystemDiameterDimensionId 
     * @param {AddPipeSystemPreInsulatedPipeKindDimensionDto} addPipeSystemPreInsulatedPipeKindDimensionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipeSystemsApi
     */
    public pipeSystemsAddPipeSystemPreInsulatedPipeKindDimension(pipeSystemId: string, pipeSystemDiameterDimensionId: string, addPipeSystemPreInsulatedPipeKindDimensionDto: AddPipeSystemPreInsulatedPipeKindDimensionDto, options?: any) {
        return PipeSystemsApiFp(this.configuration).pipeSystemsAddPipeSystemPreInsulatedPipeKindDimension(pipeSystemId, pipeSystemDiameterDimensionId, addPipeSystemPreInsulatedPipeKindDimensionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreatePipeSystemDto} createPipeSystemDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipeSystemsApi
     */
    public pipeSystemsCreatePipeSystem(createPipeSystemDto: CreatePipeSystemDto, options?: any) {
        return PipeSystemsApiFp(this.configuration).pipeSystemsCreatePipeSystem(createPipeSystemDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipeSystemsApi
     */
    public pipeSystemsGet(id: string, options?: any) {
        return PipeSystemsApiFp(this.configuration).pipeSystemsGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipeSystemsApi
     */
    public pipeSystemsGetAll(options?: any) {
        return PipeSystemsApiFp(this.configuration).pipeSystemsGetAll(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SingleMediaPairPreInsulatedPipesApi - axios parameter creator
 * @export
 */
export const SingleMediaPairPreInsulatedPipesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        singleMediaPairPreInsulatedPipesGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('singleMediaPairPreInsulatedPipesGet', 'id', id)
            const localVarPath = `/api/SingleMediaPairPreInsulatedPipes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        singleMediaPairPreInsulatedPipesGetAll: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/SingleMediaPairPreInsulatedPipes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateSingleMediaPairPreInsulatedPipeDto} createSingleMediaPairPreInsulatedPipeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        singleMediaPairPreInsulatedPipesPost: async (createSingleMediaPairPreInsulatedPipeDto: CreateSingleMediaPairPreInsulatedPipeDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSingleMediaPairPreInsulatedPipeDto' is not null or undefined
            assertParamExists('singleMediaPairPreInsulatedPipesPost', 'createSingleMediaPairPreInsulatedPipeDto', createSingleMediaPairPreInsulatedPipeDto)
            const localVarPath = `/api/SingleMediaPairPreInsulatedPipes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSingleMediaPairPreInsulatedPipeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SingleMediaPairPreInsulatedPipesApi - functional programming interface
 * @export
 */
export const SingleMediaPairPreInsulatedPipesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SingleMediaPairPreInsulatedPipesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async singleMediaPairPreInsulatedPipesGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SingleMediaPairPreInsulatedPipeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.singleMediaPairPreInsulatedPipesGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async singleMediaPairPreInsulatedPipesGetAll(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SingleMediaPairPreInsulatedPipeDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.singleMediaPairPreInsulatedPipesGetAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateSingleMediaPairPreInsulatedPipeDto} createSingleMediaPairPreInsulatedPipeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async singleMediaPairPreInsulatedPipesPost(createSingleMediaPairPreInsulatedPipeDto: CreateSingleMediaPairPreInsulatedPipeDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.singleMediaPairPreInsulatedPipesPost(createSingleMediaPairPreInsulatedPipeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SingleMediaPairPreInsulatedPipesApi - factory interface
 * @export
 */
export const SingleMediaPairPreInsulatedPipesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SingleMediaPairPreInsulatedPipesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        singleMediaPairPreInsulatedPipesGet(id: string, options?: any): AxiosPromise<SingleMediaPairPreInsulatedPipeDto> {
            return localVarFp.singleMediaPairPreInsulatedPipesGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        singleMediaPairPreInsulatedPipesGetAll(options?: any): AxiosPromise<Array<SingleMediaPairPreInsulatedPipeDto>> {
            return localVarFp.singleMediaPairPreInsulatedPipesGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateSingleMediaPairPreInsulatedPipeDto} createSingleMediaPairPreInsulatedPipeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        singleMediaPairPreInsulatedPipesPost(createSingleMediaPairPreInsulatedPipeDto: CreateSingleMediaPairPreInsulatedPipeDto, options?: any): AxiosPromise<string> {
            return localVarFp.singleMediaPairPreInsulatedPipesPost(createSingleMediaPairPreInsulatedPipeDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SingleMediaPairPreInsulatedPipesApi - object-oriented interface
 * @export
 * @class SingleMediaPairPreInsulatedPipesApi
 * @extends {BaseAPI}
 */
export class SingleMediaPairPreInsulatedPipesApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SingleMediaPairPreInsulatedPipesApi
     */
    public singleMediaPairPreInsulatedPipesGet(id: string, options?: any) {
        return SingleMediaPairPreInsulatedPipesApiFp(this.configuration).singleMediaPairPreInsulatedPipesGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SingleMediaPairPreInsulatedPipesApi
     */
    public singleMediaPairPreInsulatedPipesGetAll(options?: any) {
        return SingleMediaPairPreInsulatedPipesApiFp(this.configuration).singleMediaPairPreInsulatedPipesGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateSingleMediaPairPreInsulatedPipeDto} createSingleMediaPairPreInsulatedPipeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SingleMediaPairPreInsulatedPipesApi
     */
    public singleMediaPairPreInsulatedPipesPost(createSingleMediaPairPreInsulatedPipeDto: CreateSingleMediaPairPreInsulatedPipeDto, options?: any) {
        return SingleMediaPairPreInsulatedPipesApiFp(this.configuration).singleMediaPairPreInsulatedPipesPost(createSingleMediaPairPreInsulatedPipeDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TwinMediaPreInsulatedPipesApi - axios parameter creator
 * @export
 */
export const TwinMediaPreInsulatedPipesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        twinMediaPreInsulatedPipesGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('twinMediaPreInsulatedPipesGet', 'id', id)
            const localVarPath = `/api/TwinMediaPreInsulatedPipes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        twinMediaPreInsulatedPipesGetAll: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/TwinMediaPreInsulatedPipes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateTwinMediaPreInsulatedPipeDto} createTwinMediaPreInsulatedPipeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        twinMediaPreInsulatedPipesPost: async (createTwinMediaPreInsulatedPipeDto: CreateTwinMediaPreInsulatedPipeDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createTwinMediaPreInsulatedPipeDto' is not null or undefined
            assertParamExists('twinMediaPreInsulatedPipesPost', 'createTwinMediaPreInsulatedPipeDto', createTwinMediaPreInsulatedPipeDto)
            const localVarPath = `/api/TwinMediaPreInsulatedPipes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTwinMediaPreInsulatedPipeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TwinMediaPreInsulatedPipesApi - functional programming interface
 * @export
 */
export const TwinMediaPreInsulatedPipesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TwinMediaPreInsulatedPipesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async twinMediaPreInsulatedPipesGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TwinMediaPreInsulatedPipeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.twinMediaPreInsulatedPipesGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async twinMediaPreInsulatedPipesGetAll(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TwinMediaPreInsulatedPipeDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.twinMediaPreInsulatedPipesGetAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateTwinMediaPreInsulatedPipeDto} createTwinMediaPreInsulatedPipeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async twinMediaPreInsulatedPipesPost(createTwinMediaPreInsulatedPipeDto: CreateTwinMediaPreInsulatedPipeDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.twinMediaPreInsulatedPipesPost(createTwinMediaPreInsulatedPipeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TwinMediaPreInsulatedPipesApi - factory interface
 * @export
 */
export const TwinMediaPreInsulatedPipesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TwinMediaPreInsulatedPipesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        twinMediaPreInsulatedPipesGet(id: string, options?: any): AxiosPromise<TwinMediaPreInsulatedPipeDto> {
            return localVarFp.twinMediaPreInsulatedPipesGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        twinMediaPreInsulatedPipesGetAll(options?: any): AxiosPromise<Array<TwinMediaPreInsulatedPipeDto>> {
            return localVarFp.twinMediaPreInsulatedPipesGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateTwinMediaPreInsulatedPipeDto} createTwinMediaPreInsulatedPipeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        twinMediaPreInsulatedPipesPost(createTwinMediaPreInsulatedPipeDto: CreateTwinMediaPreInsulatedPipeDto, options?: any): AxiosPromise<string> {
            return localVarFp.twinMediaPreInsulatedPipesPost(createTwinMediaPreInsulatedPipeDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TwinMediaPreInsulatedPipesApi - object-oriented interface
 * @export
 * @class TwinMediaPreInsulatedPipesApi
 * @extends {BaseAPI}
 */
export class TwinMediaPreInsulatedPipesApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TwinMediaPreInsulatedPipesApi
     */
    public twinMediaPreInsulatedPipesGet(id: string, options?: any) {
        return TwinMediaPreInsulatedPipesApiFp(this.configuration).twinMediaPreInsulatedPipesGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TwinMediaPreInsulatedPipesApi
     */
    public twinMediaPreInsulatedPipesGetAll(options?: any) {
        return TwinMediaPreInsulatedPipesApiFp(this.configuration).twinMediaPreInsulatedPipesGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateTwinMediaPreInsulatedPipeDto} createTwinMediaPreInsulatedPipeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TwinMediaPreInsulatedPipesApi
     */
    public twinMediaPreInsulatedPipesPost(createTwinMediaPreInsulatedPipeDto: CreateTwinMediaPreInsulatedPipeDto, options?: any) {
        return TwinMediaPreInsulatedPipesApiFp(this.configuration).twinMediaPreInsulatedPipesPost(createTwinMediaPreInsulatedPipeDto, options).then((request) => request(this.axios, this.basePath));
    }
}


