import { TcoCalculationItem } from "../../../../../models/calculation/tcoCalculationItem"
import { Box, Flex, HStack, Text } from "@chakra-ui/react"
import React from "react"
import { CapexGraph } from "../CapexGraph/CapexGraph"
import { numberFormatter, toDecimals } from "../../../utils/decimalConverter"
import { OpexGraph } from "../OpexGraph/OpexGraph"
import { PipeSystemTitle } from "../../shared/PipeSystemTitle"
import { useCompareSystemsCalculation } from "../../hooks/useCompareSystemsCalculation"
import { useFormData } from "../../../../../FormDataContext"
import { CurrencyCode } from "../../../../../models/currency"
import { ManualPricesSystems } from "../../SelectSystem/hooks/useManualPrices"
import { GraphHeading } from "../../../TcoResult"

type CapexOpexGraphProps = {
  title: string
  chosenSystemOne: TcoCalculationItem
  chosenSystemTwo: TcoCalculationItem
  manualPrices?: ManualPricesSystems
}

export const CapexOpexGraph: React.FC<CapexOpexGraphProps> = ({
  title,
  chosenSystemOne,
  chosenSystemTwo,
  manualPrices = null,
}) => {
  const { formData } = useFormData()
  const data = useCompareSystemsCalculation({
    calculationItems: [chosenSystemOne, chosenSystemTwo],
    manualPrices,
  }).data?.data

  if (data == null) {
    return null
  }

  const systemOneCapex = data.firstSystemData?.capex?.totalPrice || 0
  const systemTwoCapex = data.secondSystemData?.capex?.totalPrice || 0

  const systemOneOpex = data.firstSystemData?.opex?.totalPrice || 0
  const systemTwoOpex = data.secondSystemData?.opex?.totalPrice || 0
  const currency = formData?.tcoCalculation?.currency || CurrencyCode.Eur

  return (
    <>
      <GraphHeading>{title}</GraphHeading>
      <Box>
        <HStack justifyContent="center" alignItems="center" spacing={10}>
          <PipeSystemTitle tcoCalculationItem={chosenSystemOne} />
          <PipeSystemTitle tcoCalculationItem={chosenSystemTwo} />
        </HStack>
        <Flex justifyContent="center" alignItems="center">
          <Text>
            CAPEX: {numberFormatter.format(toDecimals(systemOneCapex, 2))}{" "}
            {currency}
          </Text>
          <Box h={200} width={600}>
            <CapexGraph
              chosenSystemOne={chosenSystemOne}
              chosenSystemTwo={chosenSystemTwo}
              manualPrices={manualPrices}
            />
          </Box>
          <Text>
            CAPEX: {numberFormatter.format(toDecimals(systemTwoCapex, 2))}{" "}
            {currency}
          </Text>
        </Flex>

        <Flex justifyContent="center" alignItems="center">
          <Text>
            OPEX: {numberFormatter.format(toDecimals(systemOneOpex, 2))}{" "}
            {currency}
          </Text>
          <Box h={200} width={600}>
            <OpexGraph
              chosenSystemOne={chosenSystemOne}
              chosenSystemTwo={chosenSystemTwo}
              manualPrices={manualPrices}
            />
          </Box>
          <Text>
            OPEX: {numberFormatter.format(toDecimals(systemTwoOpex, 2))}{" "}
            {currency}
          </Text>
        </Flex>
      </Box>
    </>
  )
}
