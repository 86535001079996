type KeyToKeyMap<T> = { [key in keyof T]: key }

export const getKeyToKeyMap = <T extends Record<string, unknown>>(
  source: T
): KeyToKeyMap<T> => {
  const keyToKeyMap: { [key: string]: string } = {}

  for (const key of Object.keys(source)) {
    keyToKeyMap[key] = key
  }

  return keyToKeyMap as KeyToKeyMap<T>
}
