import { TcoCalculationGroup } from "../../../models/calculation/tcoCalculationGroup"
import { Flex, Text } from "@chakra-ui/react"
import { HeatLossGraph } from "./HeatLossGraph/HeatLossGraph"
import { FormattedMessage, useIntl } from "react-intl"
import TagManager, { DataLayerArgs } from "react-gtm-module"
import { useEffect } from "react"
import { getGraphTitle } from "../utils/getGraphTitle"

type EnergyLossGraphsProps = {
  tcoCalculationGroups: TcoCalculationGroup[]
}
export const HeatLossGraphs: React.FC<EnergyLossGraphsProps> = ({
  tcoCalculationGroups,
}) => {
  const intl = useIntl()

  useEffect(() => {
    const dataLayerArgs: DataLayerArgs = {
      dataLayer: {
        gtmId: "GTM-MR49XPF",
        event: "showEnergyLoss",
      },
    }

    TagManager.dataLayer(dataLayerArgs)
  }, [])

  return (
    <Flex direction={"column"}>
      <Flex paddingX={0} paddingY={4} direction={"row"}>
        <Text>
          <FormattedMessage
            id="app.heatLossGraph.explanation"
            defaultMessage="Displaying energy loss in the first year for easy comparison between the different piping systems."
            description="Explanation for what the heatloss graph is showing"
          />
        </Text>
      </Flex>

      {tcoCalculationGroups.map((group, index) => (
        <HeatLossGraph
          key={index}
          title={getGraphTitle(
            intl,
            group.nominalDiameters,
            group.pipeSystemKind
          )}
          calculationItems={group.systems}
        />
      ))}
    </Flex>
  )
}
