import { useToast } from "@chakra-ui/react"
import { useEffect } from "react"

export const useShowNetworkError = (error: Response | null) => {
  const toast = useToast()

  useEffect(() => {
    if (error) {
      toast({
        title: `Network error: ${error.status}`,
        description: "Calculation failed",
        status: "error",
        duration: 4000,
        isClosable: true,
      })
    }
  }, [toast, error])
}
