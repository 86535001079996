import { useMutation } from "react-query"
import { useAuth } from "../keycloak"
import { BaseConfiguration } from "./BaseConfiguration"
import { CalculationApi } from "./openapi"

export const useDeleteFavorite = () => {
  const { token } = useAuth()

  return useMutation((savedQueryId: string) => {
    const api = new CalculationApi(new BaseConfiguration(token))
    return api.calculationDeleteSavedQuery({ savedQueryId: savedQueryId })
  })
}
