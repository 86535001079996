import { TcoForm } from "../components/TcoForm/TcoForm"
import { TcoResult } from "../components/TcoResult/TcoResult"
import { Box } from "@chakra-ui/react"
import { usePrefetchFavorites } from "../rest/useFavorites"

export const TcoPage = () => {
  usePrefetchFavorites()

  return (
    <Box pb={40}>
      <TcoForm />
      <TcoResult />
    </Box>
  )
}
