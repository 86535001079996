import { ChakraProvider } from "@chakra-ui/react"
import { IntlProvider } from "react-intl"
import { QueryClient, QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"
import messages_da from "./translations/da.json"
import messages_en from "./translations/en.json"
import messages_de from "./translations/de.json"
import messages_se from "./translations/se.json"
import messages_it from "./translations/it.json"
import messages_fr from "./translations/fr.json"
import messages_pl from "./translations/pl.json"
import messages_nl from "./translations/nl.json"
import messages_lt from "./translations/lt.json"
import chakraTheme from "./theme/chakraTheme"
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom"
import { TcoPage } from "./pages/TcoPage"
import { FavoritesPage } from "./pages/Favorites/FavoritesPage"
import "@fontsource/lato"
import ConfigureReport from "./pages/Report/ConfigureReport"
import { PreviewReport } from "./pages/Report/PreviewReport"
import "./Print.css"
import { AuthProvider, useAuth } from "./keycloak/AuthContext"
import { keycloak } from "./keycloak/keycloak"
import { LogoHeader } from "./components/shared/Header/LogoHeader"
import { FooterBox } from "./components/Footer"
import { FormDataProvider } from "./FormDataContext"
import TagManager, { TagManagerArgs } from "react-gtm-module"
import { useEffect } from "react"
import { useLocalStorage } from "./hooks/useLocalStorage"
import { langauageKey } from "./components/shared/Header/Menu/HeaderMenu"
import { getDefaultLanguage } from "./utils/utilFuctions"

// global react query client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: { refetchOnWindowFocus: false, refetchOnReconnect: false },
  },
})

// localization
const messages: Record<string, any> = {
  da: messages_da,
  en: messages_en,
  de: messages_de,
  se: messages_se,
  it: messages_it,
  fr: messages_fr,
  pl: messages_pl,
  nl: messages_nl,
  lt: messages_lt,
}
const languageMessages = (lang: string) => {
  switch (lang) {
    case "da":
      return messages.da
    case "en":
      return messages.en
    case "de":
      return messages.de
    case "se":
      return messages.se
    case "it":
      return messages.it
    case "fr":
      return messages.fr
    case "pl":
      return messages.pl
    case "nl":
      return messages.nl
    case "lt":
      return messages.lt
    default:
      return messages.en
  }
}

function App() {
  return (
    <ChakraProvider theme={chakraTheme}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider keycloak={keycloak}>
          <FormDataProvider>
            <InnerApp />
          </FormDataProvider>
        </AuthProvider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </ChakraProvider>
  )
}

export default App

const InnerApp = () => {
  const { isLoggedIn, isInternalUser } = useAuth()
  const [language] = useLocalStorage(langauageKey, getDefaultLanguage())

  useEffect(() => {
    if (isLoggedIn) {
      const tagManagerArgs: TagManagerArgs = {
        gtmId: "GTM-MR49XPF",
        dataLayer: {
          userType: isInternalUser ? "Internal" : "External",
        },
        events: {
          calculate: "Calculate TCO",
          showCo2Emission: "Show Co2 Emission",
          showCompareSystems: "Show Compare 2 Systems",
          showEnergyLoss: "Show Energy loss",
        },
      }

      TagManager.initialize(tagManagerArgs)
    }
  }, [isInternalUser, isLoggedIn])

  if (!isLoggedIn) {
    return null
  }

  return (
    <IntlProvider locale={language} messages={languageMessages(language)}>
      <Router>
        <Routes>
          <Route path="/report/preview" element={<PreviewReport />} />
          <Route
            path="/report"
            element={
              <>
                <LogoHeader />
                <ConfigureReport />
                <FooterBox />
              </>
            }
          />

          <Route path={"/favourites"} element={<FavoritesPage />} />
          <Route path={"/favorites"} element={<FavoritesPage />} />
          <Route
            path="/"
            element={
              <>
                <LogoHeader />
                <TcoPage />
                <FooterBox />
              </>
            }
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </IntlProvider>
  )
}
