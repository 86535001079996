import { InfoOutlineIcon } from "@chakra-ui/icons"
import {
  Menu,
  MenuButton,
  Portal,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  Link,
} from "@chakra-ui/react"
import { FormattedMessage, useIntl } from "react-intl"
import { useLocalStorage } from "../../../hooks/useLocalStorage"
import { getDefaultLanguage } from "../../../utils/utilFuctions"
import GuidePdfEN from "../../../assets/docs/HowToUseTCO.pdf"
import GuidePdfDA from "../../../assets/docs/SaadanbrugesTCO.pdf"
import GuidePdfDE from "../../../assets/docs/LeitfadenTCOTool.pdf"
import GuidePdfSE from "../../../assets/docs/SåhärAnvändarDuTCO.pdf"
import GuidePdfIT from "../../../assets/docs/ComeUsareTCO.pdf"
import GuidePdfFR from "../../../assets/docs/CommentUtiliserTCO.pdf"
import GuidePdfPL from "../../../assets/docs/HowToUseTCO_PL.pdf"
import GuidePdfNL from "../../../assets/docs/HowToUseTCO_NL.pdf"
import GuidePdfLT from "../../../assets/docs/HowToUseTCO_LT.pdf"
import { langauageKey } from "./Menu/HeaderMenu"

export const InfoPopover = () => {
  const intl = useIntl()
  const [language] = useLocalStorage(langauageKey, getDefaultLanguage())

  let pdf
  switch (language) {
    case "da":
      pdf = GuidePdfDA
      break
    case "en":
      pdf = GuidePdfEN
      break
    case "de":
      pdf = GuidePdfDE
      break
    case "se":
      pdf = GuidePdfSE
      break
    case "it":
      pdf = GuidePdfIT
      break
    case "fr":
      pdf = GuidePdfFR
      break
    case "pl":
      pdf = GuidePdfPL
      break
    case "nl":
      pdf = GuidePdfNL
      break
    case "lt":
      pdf = GuidePdfLT
      break
    default:
      pdf = GuidePdfEN
      break
  }

  return (
    <Menu isLazy>
      <MenuButton
        as={Link}
        aria-label="Options"
        color="white"
        fontSize="14px"
        fontWeight="normal"
      >
        <InfoOutlineIcon color="white" marginBottom="3px" marginRight="7px" />
        <FormattedMessage id="howtouse.title" defaultMessage="How to Use TCO" />
      </MenuButton>
      <Portal>
        <MenuList>
          <MenuOptionGroup
            title={intl.formatMessage({
              id: "howtouse.group.header",
              defaultMessage: "Guides and Manuals",
            })}
          >
            <MenuItemOption>
              <Link target="_blank" href={pdf} isExternal>
                <FormattedMessage
                  id="howtouse.guide"
                  defaultMessage="Download guide"
                />
              </Link>
            </MenuItemOption>
            <MenuItemOption>
              <Link href="https://youtu.be/CaOWKRPS7wc" isExternal>
                <FormattedMessage
                  id="howtouse.video"
                  defaultMessage="Video guide"
                />
              </Link>
            </MenuItemOption>
          </MenuOptionGroup>
        </MenuList>
      </Portal>
    </Menu>
  )
}
