import { TcoCalculationItem } from "../../../../../models/calculation/tcoCalculationItem"
import { EChartsOption } from "echarts"
import { useEffect, useState } from "react"
import { ChartColor } from "../../../TcoGraphs/TcoBarChart/barChartColors"
import { useCompareSystemsCalculation } from "../../hooks/useCompareSystemsCalculation"
import { numberFormatter, toDecimals } from "../../../utils/decimalConverter"
import { asTooltipFormat } from "../../../TcoResult"
import { ManualPricesSystems } from "../../SelectSystem/hooks/useManualPrices"
import { useIntl } from "react-intl"

export const useOpexEchartsOption = (
  chosenSystemOne: TcoCalculationItem,
  chosenSystemTwo: TcoCalculationItem,
  manualPrices?: ManualPricesSystems | null
): EChartsOption | null => {
  const intl = useIntl()
  const [option, setOption] = useState<EChartsOption | null>(null)
  const data = useCompareSystemsCalculation({
    calculationItems: [chosenSystemOne, chosenSystemTwo],
    manualPrices,
  }).data?.data

  const systemOneOpex = data?.firstSystemData?.opex
  const systemTwoOpex = data?.secondSystemData?.opex

  useEffect(() => {
    const option: EChartsOption = {
      legend: {
        top: "bottom",
      },
      tooltip: {
        trigger: "item",
        formatter: (p) =>
          asTooltipFormat(p).name +
          ": <b>" +
          numberFormatter.format(asTooltipFormat(p).value as number) +
          " %</b>",
      },
      textStyle: {
        fontFamily: "Lato",
      },
      series: [
        {
          type: "pie",
          label: {
            formatter: "{c} %",
            position: "inside",
          },
          center: ["30%", "50%"],
          radius: 60,
          data: [
            {
              value: toDecimals(systemOneOpex?.energyLossPercent || 0, 2),
              name: intl.formatMessage({
                id: "energy-loss",
                defaultMessage: "Energy loss",
              }),
              itemStyle: {
                color: ChartColor.Red,
              },
              label: {
                position: "inside",
              },
            },
            {
              value: toDecimals(systemOneOpex?.investmentPercent || 0, 2),
              name: intl.formatMessage({
                id: "investment",
                defaultMessage: "Investment",
              }),
              itemStyle: {
                color: ChartColor.Green,
              },
              label: {
                position: "inside",
              },
            },
            {
              value: toDecimals(systemOneOpex?.emissionPercent || 0, 2),
              name: intl.formatMessage({
                id: "co2-fee",
                defaultMessage: "CO₂ Quote fee",
              }),
              itemStyle: {
                color: ChartColor.LightGrey,
              },
              label: {
                position: "inside",
              },
            },
          ],
        },
        {
          type: "pie",
          label: {
            formatter: "{c}%",
            position: "inside",
          },
          center: ["70%", "50%"],
          radius: 60,
          data: [
            {
              value: toDecimals(systemTwoOpex?.energyLossPercent || 0, 2),
              name: intl.formatMessage({
                id: "energy-loss",
                defaultMessage: "Energy loss",
              }),
              itemStyle: {
                color: ChartColor.Red,
              },
              label: {
                position: "inside",
              },
            },
            {
              value: toDecimals(systemTwoOpex?.investmentPercent || 0, 2),
              name: intl.formatMessage({
                id: "investment",
                defaultMessage: "Investment",
              }),
              itemStyle: {
                color: ChartColor.Green,
              },
              label: {
                position: "inside",
              },
            },
            {
              value: toDecimals(systemTwoOpex?.emissionPercent || 0, 2),
              name: intl.formatMessage({
                id: "co2-fee",
                defaultMessage: "CO₂ Quote fee",
              }),
              itemStyle: {
                color: ChartColor.LightGrey,
              },
              label: {
                position: "inside",
              },
            },
          ],
        },
      ],
    }

    setOption(option)
  }, [systemOneOpex, systemTwoOpex, intl])
  return option
}
