import { PressureTemperature } from "../../../../models/pressureTemperature"
import { useEffect, useState } from "react"

import { useAvailablePipes } from "../../../../rest/useAvailablePipes"
import groupBy from "lodash/groupBy"
import { PipeSystemKind } from "../../../../models/pipeSystemKind"
import { Pipe } from "../../../../models/pipe"

export const useAvailablePipesGrouped = (
  pressureTemperature: PressureTemperature
) => {
  const [pipes, setPipes] = useState<Record<PipeSystemKind, Pipe[]>>({
    AluFlextra: [],
    CuFlex: [],
    PexFlextra: [],
    PertFlextra: [],
    BondedSteel: [],
  })

  const { data } = useAvailablePipes(pressureTemperature)

  useEffect(() => {
    if (data) {
      // group pipes by PipeSystemType
      const pipesByPipeSystemMap = groupBy(data, (pipe) => pipe.pipeSystemKind)

      // Object to be returned
      const grouped: Record<PipeSystemKind, Pipe[]> = {
        AluFlextra: [],
        CuFlex: [],
        PexFlextra: [],
        PertFlextra: [],
        BondedSteel: [],
      }

      // If PipeSystem (key) does not exist, set value to empty array to avoid undefined
      grouped.BondedSteel = pipesByPipeSystemMap.BondedSteel
        ? pipesByPipeSystemMap.BondedSteel
        : []
      grouped.AluFlextra = pipesByPipeSystemMap.AluFlextra
        ? pipesByPipeSystemMap.AluFlextra
        : []
      grouped.CuFlex = pipesByPipeSystemMap.CuFlex
        ? pipesByPipeSystemMap.CuFlex
        : []
      grouped.PexFlextra = pipesByPipeSystemMap.PexFlextra
        ? pipesByPipeSystemMap.PexFlextra
        : []
      grouped.PertFlextra = pipesByPipeSystemMap.PertFlextra
        ? pipesByPipeSystemMap.PertFlextra
        : []

      setPipes(grouped)
    }
  }, [data])

  return { pipes }
}
