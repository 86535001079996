import { Flex, Heading, Image, VStack, Divider } from "@chakra-ui/react"
import { FormattedMessage } from "react-intl"
import BondedPipe from "../../../../assets/images/BondedPipe.png"
import BondedPipeTwin from "../../../../assets/images/BondedPipeTwin.jpg"
import React from "react"
import { BondedPipeControl } from "./BondedPipeControl/BondedPipeControl"
import { useFieldArray } from "react-hook-form"
import { Pipe } from "../../../../models/pipe"

type BondedPipeSelectionProps = {
  bondedPipes: Pipe[]
  printMode?: boolean
}

export const BondedPipeSelection: React.FC<BondedPipeSelectionProps> = ({
  bondedPipes,
  printMode = false,
}) => {
  const fieldArrayMethods = useFieldArray({
    name: "bondedPipes",
  })

  return (
    <VStack alignItems="start" w="40%" m="0 auto 0 0">
      <Heading size="md" color="brand.red">
        <FormattedMessage
          id="app.tcoPipeSelection.bondedPipesTitle"
          defaultMessage="Bonded pipe system"
          description="Title for the bonded pipe system types"
        />
      </Heading>
      <Divider />
      <Flex mt={10} direction="row" alignItems="center">
        <Image
          src={BondedPipe}
          boxSize="50px"
          objectFit="cover"
          alt="Bonded pipe"
        />
        <Image
          ml={"-35"}
          src={BondedPipe}
          boxSize="50px"
          objectFit="cover"
          alt="Bonded pipe"
        />
        <Image
          src={BondedPipeTwin}
          boxSize="75px"
          objectFit="cover"
          objectPosition={"left"}
          alt="Bonded pipe twin"
        />
      </Flex>
      <BondedPipeControl
        pipes={bondedPipes}
        fieldArrayMethods={fieldArrayMethods}
        printMode={printMode}
      />
    </VStack>
  )
}
