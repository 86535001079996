import { useQuery } from "react-query"
import {
  CalculationApi,
  CompareSystemsQuery,
  PipeSolutionInput,
  PipeSystemInsulationSeriesKindDto,
  PipeSystemKindDto,
  PipeSystemPreInsulatedPipeKindDto,
} from "../../../../rest/openapi"
import { TcoFormData } from "../../../TcoForm/TcoForm"
import {
  mapToCalculationDataDto,
  mapToComplexityData,
  mapToDesignDataDto,
  mapToEmissionDataDto,
  mapToPipeSystemDiameterByTrenchDistance,
} from "../../../../rest/useCalculation"
import { TcoCalculationItem } from "../../../../models/calculation/tcoCalculationItem"
import { useFormData } from "../../../../FormDataContext"
import { useAuth } from "../../../../keycloak"
import {
  ManualPricesSystems,
  ValidManualPriceOrNull,
} from "../SelectSystem/hooks/useManualPrices"
import { BaseConfiguration } from "../../../../rest/BaseConfiguration"

type CompareSystemsCalculationProps = {
  calculationItems: TcoCalculationItem[] | null
  manualPrices?: ManualPricesSystems | null
}

export const useCompareSystemsCalculation = ({
  calculationItems,
  manualPrices = null,
}: CompareSystemsCalculationProps) => {
  const { token } = useAuth()
  const { formData } = useFormData()
  const api = new CalculationApi(new BaseConfiguration(token))

  const manualPricesSystemOne = ValidManualPriceOrNull(manualPrices?.systemOne)
  const manualPricesSystemTwo = ValidManualPriceOrNull(manualPrices?.systemTwo)

  let firstCalculation = JSON.parse(
    JSON.stringify(calculationItems?.[0])
  ) as TcoCalculationItem | null // Take copy of budget prices

  if (
    manualPricesSystemOne != null &&
    firstCalculation != null &&
    firstCalculation.insulationSeriesCost != null
  ) {
    firstCalculation.insulationSeriesCost.pipeInstallation =
      manualPricesSystemOne.pipeInstallation || 0
    firstCalculation.insulationSeriesCost.pipeMaterial =
      manualPricesSystemOne.pipeMaterial || 0
    firstCalculation.insulationSeriesCost.trenchExcavation =
      manualPricesSystemOne.trench || 0
  } else {
    firstCalculation = null
  }

  let secondCalculation = JSON.parse(
    JSON.stringify(calculationItems?.[1])
  ) as TcoCalculationItem | null // Take copy of budget prices

  if (
    manualPricesSystemTwo != null &&
    secondCalculation != null &&
    secondCalculation.insulationSeriesCost != null
  ) {
    secondCalculation.insulationSeriesCost.pipeInstallation =
      manualPricesSystemTwo.pipeInstallation || 0
    secondCalculation.insulationSeriesCost.pipeMaterial =
      manualPricesSystemTwo.pipeMaterial || 0
    secondCalculation.insulationSeriesCost.trenchExcavation =
      manualPricesSystemTwo.trench || 0
  } else {
    secondCalculation = null
  }

  const calculationsItems = [
    firstCalculation || calculationItems?.[0],
    secondCalculation || calculationItems?.[1],
  ].flatMap((c) => (c ? c : []))

  const requestDto = mapToRequestDto(formData, calculationsItems)

  return useQuery(
    ["compareSystemsCalculationKey", requestDto],
    () => api.calculationCompareSystems(requestDto),
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  )
}

const mapToRequestDto = (
  tcoFormData: TcoFormData | null,
  calculationItems: TcoCalculationItem[] | null
): CompareSystemsQuery => {
  if (tcoFormData == null || calculationItems == null) {
    return {}
  }

  return mapToCompareSystemsQueryDto(tcoFormData, calculationItems)
}

const mapToCompareSystemsQueryDto = (
  tcoFormData: TcoFormData,
  calculationItems: TcoCalculationItem[]
): CompareSystemsQuery => {
  return {
    designData: mapToDesignDataDto(tcoFormData.designData),
    calculationData: mapToCalculationDataDto(tcoFormData.tcoCalculation),
    emissionData: mapToEmissionDataDto(tcoFormData.co2Emission),
    pipeSystemDiameterByTrenchDistance: mapToPipeSystemDiameterByTrenchDistance(
      [...(tcoFormData.bondedPipes || []), ...(tcoFormData.flexPipes || [])]
    ),
    complexityData: mapToComplexityData(tcoFormData.complexityData),
    firstPipeSolution: mapToPipeSolution(calculationItems[0]),
    secondPipeSolution: mapToPipeSolution(calculationItems[1]),
  }
}

const mapToPipeSolution = (
  calculationItem: TcoCalculationItem
): PipeSolutionInput => {
  return {
    nominalDiameters: calculationItem.nominalDiameters,
    pipeSystemKind: calculationItem.pipeSystemKind as PipeSystemKindDto,
    pipeSystemPreInsulatedPipeKind:
      calculationItem.pipeSystemPreInsulatedPipeKind as PipeSystemPreInsulatedPipeKindDto,
    investmentPrices: {
      excavationPrice: calculationItem.insulationSeriesCost.trenchExcavation,
      installationPrice: calculationItem.insulationSeriesCost.pipeInstallation,
      materialPrice: calculationItem.insulationSeriesCost.pipeMaterial,
    },
    pipeSystemInsulationSeriesKind:
      calculationItem.insulationSeriesKind as PipeSystemInsulationSeriesKindDto,
  }
}
