import { Box, Divider, Flex } from "@chakra-ui/react"
import { FormProvider, useForm } from "react-hook-form"
import { ContentWidth } from "../../../components/shared/ContentWidth/ContentWidth"
import { TcoSliders } from "../../../components/Sliders/TcoSliders"
import { Co2Emission } from "../../../components/TcoForm/TcoDataInput/Co2Emission/Co2Emission"
import { DesignData } from "../../../components/TcoForm/TcoDataInput/DesignData/DesignData"
import { TCOCalculation } from "../../../components/TcoForm/TcoDataInput/TCOCalculation/TCOCalculation"
import {
  TcoFormData,
  tcoFormDataKeys,
} from "../../../components/TcoForm/TcoForm"
import { TcoPipeSelection } from "../../../components/TcoForm/TcoPipeSelection/TcoPipeSelection"
import { useFormData } from "../../../FormDataContext"
import { PressureTemperature } from "../../../models/pressureTemperature"

export const ReportInputData = () => {
  const { formData } = useFormData()
  const methods = useForm<TcoFormData>({
    defaultValues: {
      bondedPipes: formData?.bondedPipes,
      complexityData: formData?.complexityData,
      co2Emission: formData?.co2Emission,
      tcoCalculation: formData?.tcoCalculation,
      designData: formData?.designData,
      flexPipes: formData?.flexPipes,
    },
  })

  const temperatureArray = [
    Number(formData?.designData.tFlowWinter),
    Number(formData?.designData.tFlowSummer),
    Number(formData?.designData.tReturnWinter),
    Number(formData?.designData.tReturnSummer),
  ]
  const minTemperature = Math.min(...temperatureArray)
  const maxTemperature = Math.max(...temperatureArray)
  const pressure = Number(formData?.designData.pressure)

  const pressureTemperature: PressureTemperature = {
    pressureBar: pressure,
    maxMediaTemperature: maxTemperature,
    minMediaTemperature: minTemperature,
  }

  return (
    <FormProvider {...methods}>
      <Box mb="4">
        <ContentWidth>
          <Flex marginTop={12} justifyContent="space-between">
            <DesignData
              namePrefix={tcoFormDataKeys.designData + "."}
              printMode
            />
            <TCOCalculation
              namePrefix={tcoFormDataKeys.tcoCalculation + "."}
              printMode
            />
            <Co2Emission
              namePrefix={tcoFormDataKeys.co2Emission + "."}
              printMode
            />
          </Flex>
        </ContentWidth>
      </Box>
      <ContentWidth>
        <TcoPipeSelection pressureTemperature={pressureTemperature} printMode />
        <Flex mt={10} mb={10} justifyContent="center">
          <TcoSliders
            onSubmit={() => {
              /* No action for print mode */
            }}
            printMode
          />
        </Flex>
      </ContentWidth>
      <Divider mt="5" />
    </FormProvider>
  )
}
