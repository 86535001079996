import { TcoCalculationItem } from "../../../../models/calculation/tcoCalculationItem"
import { Box, Flex, Heading } from "@chakra-ui/react"
import React from "react"

import ReactECharts from "echarts-for-react"
import { useHeatLossEchartOption } from "./hooks/useHeatLossEchartOption"

type EnergyLossGraphProps = {
  title: string
  calculationItems: TcoCalculationItem[]
}
export const HeatLossGraph: React.FC<EnergyLossGraphProps> = ({
  title,
  calculationItems,
}) => {
  const echartsOption = useHeatLossEchartOption(calculationItems)

  return (
    <>
      <Flex flexDirection={"row"} h={600}>
        <Box w={250}>
          <Heading textAlign={"left"} color={"brand.grey"} fontSize={20}>
            {title}
          </Heading>
        </Box>

        <Box flex={1}>
          {echartsOption && (
            <ReactECharts
              option={echartsOption}
              style={{ height: "100%", width: "100%" }}
              opts={{ renderer: "svg" }}
            />
          )}
        </Box>
        {/*TODO: move one level up, otherwise it gets repeated for each graph*/}
        {/*<Flex w={300} alignItems={"center"}>*/}
        {/*  <Text fontSize={"sm"}>DN20-DN200 note regarding lambda values:</Text>*/}
        {/*</Flex>*/}
      </Flex>
    </>
  )
}
