import { Flex, Text } from "@chakra-ui/react"
import React from "react"
import { useIntl } from "react-intl"
import { TcoCalculationItem } from "../../../../models/calculation/tcoCalculationItem"
import {
  isSmallPipe,
  mapToLocalisedPreInsulatedPipe,
} from "../../mappers/mapToLocalisedPreInsulatedPipeKind"

type PipeSystemTitleProps = {
  tcoCalculationItem: TcoCalculationItem
}
export const PipeSystemTitle: React.FC<PipeSystemTitleProps> = ({
  tcoCalculationItem,
}) => {
  const intl = useIntl()
  const title = mapToLocalisedPreInsulatedPipe(
    tcoCalculationItem.pipeSystemPreInsulatedPipeKind,
    intl,
    tcoCalculationItem.lambda,
    isSmallPipe(tcoCalculationItem),
    tcoCalculationItem.insulationSeriesKind
  )

  const formattedTitle = title.split("\n").map((item, idx) => {
    return (
      <span key={idx}>
        {item}
        <br />
      </span>
    )
  })

  return (
    <Flex flexDirection="column" justifyContent="center" textAlign="center">
      <Text fontSize={18} fontWeight="bold">
        {formattedTitle}
      </Text>
    </Flex>
  )
}
