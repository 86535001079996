import { PipeSystemPreInsulatedPipeKind } from "../../models/calculation/tcoCalculationItem"
import { PipeSystemPreInsulatedPipeKindDto } from "../openapi"

export const mapToPipeSystemPreInsulatedPipeKind = (
  dto: PipeSystemPreInsulatedPipeKindDto
): PipeSystemPreInsulatedPipeKind => {
  switch (dto) {
    case "TraditionalPairOfPipeWithoutDiffusionBarrier":
      return "TraditionalPairOfPipeWithoutDiffusionBarrier"
    case "ContiPairOfPipeWithAndWithoutDiffusionBarrier":
      return "ContiPairOfPipeWithAndWithoutDiffusionBarrier"
    case "ContiPairOfPipeWithDiffusionBarrier":
      return "ContiPairOfPipeWithDiffusionBarrier"
    case "TraditionalTwinPipeWithoutDiffusionBarrier":
      return "TraditionalTwinPipeWithoutDiffusionBarrier"
    case "ContiTwinPipeWithDiffusionBarrier":
      return "ContiTwinPipeWithDiffusionBarrier"
    case "PexFlextraPairOfPipe":
      return "PexFlextraPairOfPipe"
    case "PexFlextraTwinPipe":
      return "PexFlextraTwinPipe"
    case "AluFlextraPairOfPipe":
      return "AluFlextraPairOfPipe"
    case "AluFlextraTwinPipe":
      return "AluFlextraTwinPipe"
    case "CuFlexPairOfPipe":
      return "CuFlexPairOfPipe"
    case "CuFlexTwinPipe":
      return "CuFlexTwinPipe"
    case "PertFlextraPairOfPipe":
      return "PertFlextraPairOfPipe"
    case "PertFlextraTwinPipe":
      return "PertFlextraTwinPipe"
    default:
      throw Error(
        `mapToPipeSystemPreInsulatedPipeKind received invalid PipeSystemPreInsulatedPipeKindDto: ${dto}`
      )
  }
}
