import { Box, VStack, Text } from "@chakra-ui/react"
import { useNavigate, useLocation } from "react-router-dom"
import { ReportFooter } from "../../components/Report/ReportFooter"
import { BackLink } from "../../components/shared/BackLink"
import { TcoResult } from "../../components/TcoResult/TcoResult"
import { ReportHeader } from "./components/ReportHeader"
import { ReportInputData } from "./components/ReportInputData"
import { ReportPipesAndConfig } from "./ConfigureReport"
import PrintOptions from "./PrintOptions"
import { GetFullVersion } from "../../rest/useVersion"
import { FormattedMessage } from "react-intl"

export const PreviewReport = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const pipesAndConfig = state as ReportPipesAndConfig | undefined
  const version = GetFullVersion()

  if (pipesAndConfig == null) {
    navigate("/")
    return null
  }

  return (
    <>
      <BackLink mt="10" ms="8" />
      <VStack w="100%" align="start" spacing="0">
        <PrintOptions />
        <ReportHeader />
        <Box as="main" pt="8" w="100%">
          <ReportInputData />
          <TcoResult selectedPipes={pipesAndConfig?.pipes} printMode />

          {version && (
            <Text mb="48" mt="8" textAlign="center" fontSize={12}>
              <FormattedMessage
                id="report.header.tool_version"
                defaultMessage="Tool Version: "
                description="Tool version"
              />
              {version}
            </Text>
          )}
        </Box>
        <ReportFooter />
      </VStack>
    </>
  )
}
