import { useMutation } from "react-query"

// function readFile(file: File) {
//   return new Promise<string>((resolve, reject) => {
//     const fr = new FileReader()
//     fr.onload = (x) => resolve(fr.result as string)
//     fr.onerror = reject
//     fr.readAsDataURL(file)
//   })
// }

export const useUploadLogo = () => {
  // const api = new ReportApi()

  return useMutation(async (logo: File) => {
    // const body = await readFile(logo)
    // return api.reportUploadLogo({ body: body || "" })
  })
}
