import { TcoCalculationItem } from "../../../../../models/calculation/tcoCalculationItem"
import { EChartsOption } from "echarts"
import { useEffect, useState } from "react"
import { ChartColor } from "../../../TcoGraphs/TcoBarChart/barChartColors"
import { SeriesData, xAxisData } from "../../../echart.models"
import { useFormData } from "../../../../../FormDataContext"
import { asTooltipFormat } from "../../../TcoResult"
import { LabelOption } from "echarts/types/src/util/types"
import { CurrencyCode } from "../../../../../models/currency"
import { numberFormatter } from "../../../utils/decimalConverter"
import {
  isSmallPipe,
  mapToLocalisedPreInsulatedPipe,
} from "../../../mappers/mapToLocalisedPreInsulatedPipeKind"
import { ManualPrices } from "./useManualPrices"
import { IntlShape, useIntl } from "react-intl"

export const useSelectSystemEchartOption = (
  tcoCalculationItemOne: TcoCalculationItem | null,
  tcoCalculationItemTwo: TcoCalculationItem | null,
  manualPrices?: {
    systemOne?: ManualPrices | null
    systemTwo?: ManualPrices | null
  }
): EChartsOption | null => {
  const [option, setOption] = useState<EChartsOption | null>(null)
  const { formData } = useFormData()
  const intl = useIntl()
  const currency = formData?.tcoCalculation.currency

  useEffect(() => {
    const xAxis: xAxisData[] = []
    const pipeMaterial: SeriesData[] = []
    const trenchExcavation: SeriesData[] = []
    const pipeInstallation: SeriesData[] = []
    const energy: SeriesData[] = []
    const emission: SeriesData[] = []

    const axisNameOne = mapToLocalisedXAxisName(
      tcoCalculationItemOne,
      currency || CurrencyCode.Eur,
      true,
      intl,
      manualPrices?.systemOne
    )
    xAxis.push(toAxisData(axisNameOne))

    const axisNameTwo = mapToLocalisedXAxisName(
      tcoCalculationItemTwo,
      currency || CurrencyCode.Eur,
      true,
      intl,
      manualPrices?.systemTwo
    )
    xAxis.push(toAxisData(axisNameTwo))

    const addSeriesData = (
      tcoCalculationItem: TcoCalculationItem,
      manualPrices?: ManualPrices | null
    ) => {
      pipeMaterial.push({
        value:
          manualPrices?.pipeMaterial ??
          tcoCalculationItem.insulationSeriesCost.pipeMaterial,
      })
      trenchExcavation.push({
        value:
          manualPrices?.trench ??
          tcoCalculationItem.insulationSeriesCost.trenchExcavation,
      })
      pipeInstallation.push({
        value:
          manualPrices?.pipeInstallation ??
          tcoCalculationItem.insulationSeriesCost.pipeInstallation,
      })
      energy.push({
        value: tcoCalculationItem.insulationSeriesCost.energyPresentValue,
      })
      emission.push({
        value: tcoCalculationItem.insulationSeriesCost.emissionPresentValue,
      })
    }

    if (tcoCalculationItemOne) {
      addSeriesData(tcoCalculationItemOne, manualPrices?.systemOne)
    } else {
      // Put empty elements in the graph in case it's only the second pipe system that is chosen
      // Otherwise, the second pipe system is showing up on the first pipe systems position
      pipeMaterial.push({
        value: 0,
      })
      trenchExcavation.push({
        value: 0,
      })
      pipeInstallation.push({
        value: 0,
      })
      energy.push({
        value: 0,
      })
      emission.push({
        value: 0,
      })
    }
    if (tcoCalculationItemTwo) {
      addSeriesData(tcoCalculationItemTwo, manualPrices?.systemTwo)
    }

    const option = getCompareSystemOption(
      xAxis,
      pipeMaterial,
      trenchExcavation,
      pipeInstallation,
      energy,
      emission,
      currency,
      intl
    )
    setOption(option)
  }, [
    tcoCalculationItemOne,
    tcoCalculationItemTwo,
    manualPrices?.systemOne,
    manualPrices?.systemTwo,
    currency,
    intl,
  ])

  return option
}

const toAxisData = (axisName: string): xAxisData => {
  const axisData: LabelOption = {
    rich: {
      b: {
        fontSize: 25,
        color: ChartColor.DarkGrey,
      },
      l: {
        fontSize: 20,
        color: ChartColor.DarkGrey,
      },
    },
  }

  return {
    value: axisName,
    textStyle: axisData,
  }
}

export const mapToLocalisedXAxisName = (
  tcoCalculationItem: TcoCalculationItem | null,
  currency: string,
  includeCost: boolean,
  intl: IntlShape,
  manualPrices?: ManualPrices | null
): string => {
  if (tcoCalculationItem == null) {
    return ""
  }

  let name = mapToLocalisedPreInsulatedPipe(
    tcoCalculationItem.pipeSystemPreInsulatedPipeKind,
    intl,
    tcoCalculationItem.lambda,
    isSmallPipe(tcoCalculationItem),
    tcoCalculationItem.insulationSeriesKind
  )

  const totalCostText = intl.formatMessage({
    id: "total_tco_cost",
    defaultMessage: "Total TCO cost",
  })

  if (includeCost) {
    const totalPrice =
      (manualPrices?.pipeMaterial ??
        tcoCalculationItem.insulationSeriesCost.pipeMaterial) +
      (manualPrices?.pipeInstallation ??
        tcoCalculationItem.insulationSeriesCost.pipeInstallation) +
      (manualPrices?.trench ??
        tcoCalculationItem.insulationSeriesCost.trenchExcavation) +
      tcoCalculationItem.insulationSeriesCost.energyPresentValue +
      (tcoCalculationItem.insulationSeriesCost.emissionPresentValue || 0)
    name = `{l|\n${totalCostText}}\n{b|${numberFormatter.format(
      totalPrice
    )} ${currency}}\n\n${name}`
  }

  return name
}

const getCompareSystemOption = (
  xAxis: xAxisData[],
  pipeMaterial: SeriesData[],
  trenchExcavation: SeriesData[],
  pipeInstallation: SeriesData[],
  energy: SeriesData[],
  emission: SeriesData[],
  currency: string | undefined,
  intl: IntlShape
): EChartsOption => {
  return {
    tooltip: {
      formatter: (p) =>
        asTooltipFormat(p).seriesName +
        ": <b>" +
        numberFormatter.format(asTooltipFormat(p).value as number) +
        ` ${currency}</b>`,
    },
    legend: {
      top: "bottom",
      data: [
        intl.formatMessage({
          id: "pipe-material",
          defaultMessage: "Pipe material",
        }),
        intl.formatMessage({
          id: "trench-excavation",
          defaultMessage: "Trench excavation",
        }),
        intl.formatMessage({
          id: "pipe-installation",
          defaultMessage: "Pipe installation",
        }),
        intl.formatMessage({
          id: "energy-loss",
          defaultMessage: "Energy loss",
        }),
        intl.formatMessage({
          id: "co2-fee",
          defaultMessage: "CO₂ Quote fee",
        }),
      ],
    },
    grid: {
      containLabel: true,
    },
    textStyle: {
      fontFamily: "Lato",
    },
    xAxis: [
      {
        type: "category",
        data: xAxis,
        axisLabel: {
          fontSize: 14,
          color: "black",
          formatter: `{value}`,
        },
      },
    ],
    yAxis: [
      {
        show: true,
        name: `${currency}`,
        nameLocation: "end",
        type: "value",
        axisLabel: {
          formatter: (value: number) => numberFormatter.format(value as number),
        },
        axisLine: {
          show: true,
        },
      },
    ],
    series: [
      {
        name: intl.formatMessage({
          id: "pipe-material",
          defaultMessage: "Pipe material",
        }),
        type: "bar",
        stack: "1",
        barMaxWidth: 150,
        color: ChartColor.Blue,
        data: pipeMaterial,
      },
      {
        name: intl.formatMessage({
          id: "trench-excavation",
          defaultMessage: "Trench excavation",
        }),
        stack: "1",
        type: "bar",
        barMaxWidth: 150,
        color: ChartColor.DarkGrey,
        data: trenchExcavation,
      },
      {
        name: intl.formatMessage({
          id: "pipe-installation",
          defaultMessage: "Pipe installation",
        }),
        stack: "1",
        type: "bar",
        barMaxWidth: 150,
        color: ChartColor.Yellow,
        data: pipeInstallation,
      },
      {
        name: intl.formatMessage({
          id: "energy-loss",
          defaultMessage: "Energy loss",
        }),
        stack: "1",
        type: "bar",
        barMaxWidth: 150,
        color: ChartColor.Red,
        data: energy,
      },
      {
        name: intl.formatMessage({
          id: "co2-fee",
          defaultMessage: "CO₂ Quote fee",
        }),
        stack: "1",
        type: "bar",
        barMaxWidth: 150,
        color: ChartColor.LightGrey,
        data: emission,
      },
    ],
  }
}
