import { Heading, HStack } from "@chakra-ui/react"
import { FlexPipeSelection } from "./FlexPipeSelection/FlexPipeSelection"
import React from "react"
import { PressureTemperature } from "../../../models/pressureTemperature"
import { FormattedMessage } from "react-intl"
import { useAvailablePipesGrouped } from "./hook/useAvailablePipesGrouped"
import { BondedPipeSelection } from "./BondedPipeSelection/BondedPipeSelection"

export type TcoPipeSelectionProps = {
  pressureTemperature: PressureTemperature
  printMode?: boolean
}

export const TcoPipeSelection: React.FC<TcoPipeSelectionProps> = ({
  pressureTemperature,
  printMode = false,
}) => {
  const { pipes } = useAvailablePipesGrouped(pressureTemperature)

  return (
    <>
      {/*Enter meter trench title*/}
      {!printMode && (
        <Heading mt="5" size="lg" fontSize="24px">
          <FormattedMessage
            id="app.tcoPipeSelection.meterTrenchTitle"
            defaultMessage="Enter metre trench pipe by dimension"
            description="'Enter metre trench pipe by dimension'"
          />
        </Heading>
      )}
      <HStack alignItems="flex-start" justifyContent="space-evenly" mt="10">
        <BondedPipeSelection
          bondedPipes={pipes.BondedSteel}
          printMode={printMode}
        />
        <FlexPipeSelection
          aluFlexPipes={pipes.AluFlextra}
          cuFlexPipes={pipes.CuFlex}
          pexFlexPipes={pipes.PexFlextra}
          pertFlexPipes={pipes.PertFlextra}
          printMode={printMode}
        />
      </HStack>
    </>
  )
}
