import { CalculationApi, CurrencyCodeKindDto, CurrencyDto } from "./openapi"
import { useQuery } from "react-query"
import { CurrencyCode, CurrencyIdExchange } from "../models/currency"
import { useAuth } from "../keycloak"
import { BaseConfiguration } from "./BaseConfiguration"

export const useCurrencyAndExchangeRate = () => {
  const { token } = useAuth()
  const fetchCurrencyExchangeRate = (): Promise<CurrencyIdExchange[]> => {
    const calculationApi = new CalculationApi(new BaseConfiguration(token))
    return calculationApi
      .calculationGetCurrencies()
      .then((dtos) => dtos.data.map(mapFromDto))
  }
  return useQuery("currencyExchangeRateQueryKey", fetchCurrencyExchangeRate)
}

const mapFromDto = (dto: CurrencyDto): CurrencyIdExchange => ({
  id: dto.id!, // TODO: fix ! when DTO's are not nullable
  code: mapToCurrencyCode(dto.code!), // TODO: fix ! when DTO's are not nullable
  exchangeRate: dto.rate!.toString(), // TODO: fix ! when DTO's are not nullable
})

const mapToCurrencyCode = (dto: CurrencyCodeKindDto): CurrencyCode => {
  switch (dto) {
    case CurrencyCodeKindDto.Cad:
      return CurrencyCode.Cad
    case CurrencyCodeKindDto.Chf:
      return CurrencyCode.Chf
    case CurrencyCodeKindDto.Czk:
      return CurrencyCode.Czk
    case CurrencyCodeKindDto.Dkk:
      return CurrencyCode.Dkk
    case CurrencyCodeKindDto.Eur:
      return CurrencyCode.Eur
    case CurrencyCodeKindDto.Gbp:
      return CurrencyCode.Gbp
    case CurrencyCodeKindDto.Isk:
      return CurrencyCode.Isk
    case CurrencyCodeKindDto.Ltl:
      return CurrencyCode.Ltl
    case CurrencyCodeKindDto.Nok:
      return CurrencyCode.Nok
    case CurrencyCodeKindDto.Pln:
      return CurrencyCode.Pln
    case CurrencyCodeKindDto.Ron:
      return CurrencyCode.Ron
    case CurrencyCodeKindDto.Sek:
      return CurrencyCode.Sek
    case CurrencyCodeKindDto.Usd:
      return CurrencyCode.Usd
    default:
      throw Error(
        `mapToCurrencyCode received invalid CurrencyCodeKindDto: ${dto}`
      )
  }
}
