import React from "react"
import { Box, Flex, Text, Heading } from "@chakra-ui/react"
import { ROIGraph } from "./ROIGraph"
import { FormattedMessage, useIntl } from "react-intl"
import { CompareItem } from "../CompareSystemGraphs"
import { getGraphTitle } from "../../utils/getGraphTitle"
import { TcoCalculationGroup } from "../../../../models/calculation/tcoCalculationGroup"
import { ManualPricesSystems } from "../SelectSystem/hooks/useManualPrices"

type ROIGraphsProps = {
  tcoCalculationGroups: TcoCalculationGroup[]
  pipes: Array<CompareItem | null>
  manualPrices: ManualPricesSystems[]
}

export const ROIGraphs: React.FC<ROIGraphsProps> = ({
  tcoCalculationGroups,
  pipes,
  manualPrices,
}) => {
  const intl = useIntl()

  return (
    <Flex flexDirection={"column"} alignItems={"center"}>
      <Flex paddingX={0} paddingY={4} direction={"row"} alignSelf="flex-start">
        <Text>
          <FormattedMessage
            id="app.roi.explanation"
            defaultMessage="Illustrates the point at which the additional investment in a lower heat loss pipe system is repaid, based on a simple payback period."
            description="Explanation for what the roi graph is showing"
          />
        </Text>
      </Flex>

      {pipes.map(
        (pipeSystem, index) =>
          pipeSystem && (
            <React.Fragment key={index}>
              <Heading alignSelf={"start"} color={"brand.grey"} fontSize={20}>
                {getGraphTitle(
                  intl,
                  tcoCalculationGroups[index].nominalDiameters,
                  tcoCalculationGroups[index].pipeSystemKind
                )}
              </Heading>
              <Box w={"100%"} mt={8}>
                <ROIGraph
                  chosenSystemOne={pipeSystem.systemOne}
                  chosenSystemTwo={pipeSystem.systemTwo}
                  manualPrices={manualPrices[index]}
                />
              </Box>
            </React.Fragment>
          )
      )}
    </Flex>
  )
}
