import { TcoCalculationItem } from "../../../models/calculation/tcoCalculationItem"

export const getLambda = (
  tcoCalculationItems: TcoCalculationItem[]
): number => {
  if (tcoCalculationItems.length > 0) {
    return tcoCalculationItems[0].lambda
  } else {
    return 0
  }
}
