import { EChartsOption } from "echarts"
import {
  PipeSystemPreInsulatedPipeKind,
  TcoCalculationItem,
} from "../../../../../models/calculation/tcoCalculationItem"
import { useEffect, useState } from "react"
import groupBy from "lodash/groupBy"
import forEach from "lodash/forEach"
import {
  isSmallPipes,
  mapToLocalisedPreInsulatedPipe,
} from "../../../mappers/mapToLocalisedPreInsulatedPipeKind"
import { mapToLocalisedInsulationSeries } from "../../../mappers/mapToLocalisedInsulationSeries"
import { numberFormatter, toDecimals } from "../../../utils/decimalConverter"
import { ChartColor } from "../../../TcoGraphs/TcoBarChart/barChartColors"
import { SeriesData, xAxisData } from "../../../echart.models"
import { getLambda } from "../../../utils/getLambda"
import { asTooltipFormat } from "../../../TcoResult"
import { IntlShape, useIntl } from "react-intl"

export const useEmissionEchartOption = (
  calculationItems: TcoCalculationItem[]
): EChartsOption | null => {
  const intl = useIntl()
  const [echartOption, setEchartOption] = useState<EChartsOption | null>(null)

  useEffect(() => {
    // variables
    const xAxisSavingsInPercent: xAxisData[] = []
    const xAxisInsulationSeries: xAxisData[] = []
    const xAxisPreInsulatedPipe: xAxisData[] = []
    const emissionSeries: SeriesData[] = []
    const emissionSavingSeries: SeriesData[] = []

    // group by PipeSystemPreInsulatedPipeKind
    const groupedByPreInsulatedPipeKind = groupBy(
      calculationItems,
      (data) => data.pipeSystemPreInsulatedPipeKind
    )

    forEach(groupedByPreInsulatedPipeKind, (preInsulatedPipeGroupList, key) => {
      const pipeSystemPreInsulatedPipe = key as PipeSystemPreInsulatedPipeKind
      xAxisPreInsulatedPipe.push(
        mapToLocalisedPreInsulatedPipe(
          pipeSystemPreInsulatedPipe,
          intl,
          getLambda(preInsulatedPipeGroupList),
          isSmallPipes(preInsulatedPipeGroupList)
        )
      )

      forEach(preInsulatedPipeGroupList, (series) => {
        xAxisPreInsulatedPipe.push("")
        xAxisInsulationSeries.push(
          mapToLocalisedInsulationSeries(series.insulationSeriesKind)
        )

        const createSavingPercentItem = (value: number) => {
          return {
            value: `${value}%`,
            textStyle: {
              color: "black",
              lineHeight: -15,
            },
          }
        }

        xAxisSavingsInPercent.push(
          createSavingPercentItem(
            toDecimals(series.emissionComparison.emissionSavingsPercent, 0)
          )
        )

        const createSeriesData = (cost: number) => ({
          value: cost,
        })

        const emission = createSeriesData(
          toDecimals(series.emissionComparison.emission, 2)
        )
        const emissionSaving = createSeriesData(
          toDecimals(series.emissionComparison.emissionSavings, 2)
        )

        emissionSeries.push(emission)
        emissionSavingSeries.push(emissionSaving)
      })

      const invisibleStack = {
        value: 0,
        itemStyle: {
          opacity: 0,
        },
      }
      // Inserting empty/invisible values to mimic grouping which library doesn't support
      emissionSeries.push(invisibleStack)
      emissionSavingSeries.push(invisibleStack)
      xAxisSavingsInPercent.push("")
      xAxisInsulationSeries.push("")
    })

    const options = getEnergyLossOption(
      xAxisSavingsInPercent,
      xAxisInsulationSeries,
      xAxisPreInsulatedPipe,
      emissionSeries,
      emissionSavingSeries,
      intl
    )
    setEchartOption(options)
  }, [calculationItems, intl])

  return echartOption
}

const getEnergyLossOption = (
  xAxisSavingsInPercent: xAxisData[],
  xAxisInsulationSeries: xAxisData[],
  xAxisPreInsulatedPipe: xAxisData[],
  emissionSeries: SeriesData[],
  emissionSavingSeries: SeriesData[],
  intl: IntlShape
): EChartsOption => {
  return {
    tooltip: {
      formatter: (p) =>
        asTooltipFormat(p).seriesName +
        ": <b>" +
        numberFormatter.format(asTooltipFormat(p).value as number) +
        " Ton</b>",
    },
    legend: {
      top: "bottom",
      textStyle: {
        fontSize: 14,
      },
      data: [
        intl.formatMessage({
          id: "emission.chart.savings",
          defaultMessage: "Savings",
        }),
        intl.formatMessage({
          id: "emission.chart.co2",
          defaultMessage: "CO₂ emission",
        }),
      ],
    },
    grid: {
      containLabel: true,
    },
    xAxis: [
      {
        type: "category",
        data: xAxisInsulationSeries,
      },
      {
        type: "category",
        name: intl.formatMessage({
          id: "emission.chart.savings-percent",
          defaultMessage: "Savings (%)",
        }),
        nameTextStyle: {
          color: "black",
        },
        nameLocation: "start",
        offset: 50,
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        data: xAxisSavingsInPercent,
      },
      {
        position: "bottom",
        offset: 30,
        axisTick: {
          show: false,
        },
        data: xAxisPreInsulatedPipe,
        axisLine: {
          show: false,
        },
        axisLabel: {
          margin: 0,
          align: "left",
          interval: 0,
        },
      },
    ],
    yAxis: [
      {
        show: true,
        name: intl.formatMessage({
          id: "emission.chart.ton",
          defaultMessage: "Ton",
        }),
        nameLocation: "end",
        axisLine: {
          show: true,
        },
        axisLabel: {
          formatter: (value: number) => numberFormatter.format(value as number),
        },
      },
    ],
    series: [
      {
        name: intl.formatMessage({
          id: "emission.chart.co2",
          defaultMessage: "CO₂ emission",
        }),
        type: "bar",
        barMaxWidth: 40,
        stack: "1",
        color: ChartColor.LightGrey,
        label: {
          rotate: 90,
          show: true,
          color: "black",
          formatter: (p) => numberFormatter.format(p.value as number),
        },
        data: emissionSeries,
      },
      {
        name: intl.formatMessage({
          id: "emission.chart.savings",
          defaultMessage: "Savings",
        }),
        stack: "1",
        type: "bar",
        color: ChartColor.Green,
        barMaxWidth: 40,
        data: emissionSavingSeries,
      },
    ],
  }
}
