import { Box } from "@chakra-ui/react"

type Props = {
  children?: React.ReactNode
}

export const ContentWidth: React.FC<Props> = ({ children }) => {
  return (
    <Box maxW={1650} margin="0 auto" paddingX={8} paddingY={4}>
      {children}
    </Box>
  )
}
