export type CurrencyIdExchange = {
  id: string
  code: CurrencyCode
  exchangeRate: string
}

export enum CurrencyCode {
  Cad = "CAD",
  Chf = "CHF",
  Czk = "CZK",
  Dkk = "DKK",
  Eur = "EUR",
  Gbp = "GBP",
  Isk = "ISK",
  Ltl = "LTL",
  Nok = "NOK",
  Pln = "PLN",
  Ron = "RON",
  Sek = "SEK",
  Usd = "USD",
}
