import {
  PipeSystemPreInsulatedPipeKind,
  TcoCalculationItem,
} from "../../../../../models/calculation/tcoCalculationItem"
import { EChartsOption } from "echarts"
import { useEffect, useState } from "react"
import groupBy from "lodash/groupBy"
import forEach from "lodash/forEach"
import {
  isSmallPipes,
  mapToLocalisedPreInsulatedPipe,
} from "../../../mappers/mapToLocalisedPreInsulatedPipeKind"
import { mapToLocalisedInsulationSeries } from "../../../mappers/mapToLocalisedInsulationSeries"
import { ChartColor } from "../../../TcoGraphs/TcoBarChart/barChartColors"
import { numberFormatter, toDecimals } from "../../../utils/decimalConverter"
import { SeriesData, xAxisData } from "../../../echart.models"
import { getLambda } from "../../../utils/getLambda"
import { asTooltipFormat } from "../../../TcoResult"
import { IntlShape, useIntl } from "react-intl"

export const useHeatLossEchartOption = (
  calculationItem: TcoCalculationItem[]
): EChartsOption | null => {
  const [echartOption, setEchartOption] = useState<EChartsOption | null>(null)
  const intl = useIntl()

  useEffect(() => {
    // variables
    const xAxisSavingsInPercent: xAxisData[] = []
    const xAxisInsulationSeries: xAxisData[] = []
    const xAxisPreInsulatedPipe: xAxisData[] = []
    const energyLossSeries: SeriesData[] = []
    const energySavingSeries: SeriesData[] = []

    // group by PipeSystemPreInsulatedPipeKind
    const groupedByPreInsulatedPipeKind = groupBy(
      calculationItem,
      (data) => data.pipeSystemPreInsulatedPipeKind
    )

    forEach(groupedByPreInsulatedPipeKind, (preInsulatedPipeGroupList, key) => {
      const pipeSystemPreInsulatedPipe = key as PipeSystemPreInsulatedPipeKind
      xAxisPreInsulatedPipe.push(
        mapToLocalisedPreInsulatedPipe(
          pipeSystemPreInsulatedPipe,
          intl,
          getLambda(preInsulatedPipeGroupList),
          isSmallPipes(preInsulatedPipeGroupList)
        )
      )

      forEach(preInsulatedPipeGroupList, (series) => {
        xAxisPreInsulatedPipe.push("")
        xAxisInsulationSeries.push(
          mapToLocalisedInsulationSeries(series.insulationSeriesKind)
        )

        const createSavingPercentItem = (value: number) => {
          return {
            value: `${value}%`,
            textStyle: {
              color: "black",
              lineHeight: -15,
            },
          }
        }

        xAxisSavingsInPercent.push(
          createSavingPercentItem(
            toDecimals(series.energyLossFirstYear.energySavingsPercent, 0)
          )
        )

        const createSeriesData = (cost: number) => ({
          value: cost,
        })

        const energyLoss = createSeriesData(
          toDecimals(series.energyLossFirstYear.energyLoss, 2)
        )
        const energySaving = createSeriesData(
          toDecimals(series.energyLossFirstYear.energySavings, 2)
        )

        energyLossSeries.push(energyLoss)
        energySavingSeries.push(energySaving)
      })

      const invisibleStack = {
        value: 0,
        itemStyle: {
          opacity: 0,
        },
      }
      // Inserting empty/invisible values to mimic grouping which library doesn't support
      energyLossSeries.push(invisibleStack)
      energySavingSeries.push(invisibleStack)
      xAxisSavingsInPercent.push("")
      xAxisInsulationSeries.push("")
    })

    const options = getEnergyLossOption(
      xAxisSavingsInPercent,
      xAxisInsulationSeries,
      xAxisPreInsulatedPipe,
      energyLossSeries,
      energySavingSeries,
      intl
    )
    setEchartOption(options)
  }, [calculationItem, intl])

  return echartOption
}

const getEnergyLossOption = (
  xAxisSavingsInPercent: xAxisData[],
  xAxisInsulationSeries: xAxisData[],
  xAxisPreInsulatedPipe: xAxisData[],
  energyLossSeries: SeriesData[],
  energySavingSeries: SeriesData[],
  intl: IntlShape
): EChartsOption => {
  return {
    tooltip: {
      formatter: (p) =>
        asTooltipFormat(p).seriesName +
        ": <b>" +
        numberFormatter.format(asTooltipFormat(p).value as number) +
        " MWh/year</b>",
    },
    legend: {
      top: "bottom",
      textStyle: {
        fontSize: 14,
      },
      data: [
        intl.formatMessage({
          id: "emission.chart.savings",
          defaultMessage: "Savings",
        }),
        intl.formatMessage({
          id: "energy-loss",
          defaultMessage: "Energy loss",
        }),
      ],
    },
    grid: {
      containLabel: true,
    },
    xAxis: [
      {
        type: "category",
        data: xAxisInsulationSeries,
      },
      {
        type: "category",
        name:
          intl.formatMessage({
            id: "emission.chart.savings",
            defaultMessage: "Savings",
          }) + " (%)",
        nameTextStyle: {
          color: "black",
        },
        nameLocation: "start",
        offset: 50,
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        data: xAxisSavingsInPercent,
      },
      {
        position: "bottom",
        offset: 30,
        axisTick: {
          show: false,
        },
        data: xAxisPreInsulatedPipe,
        axisLine: {
          show: false,
        },
        axisLabel: {
          margin: 0,
          align: "left",
          interval: 0,
        },
      },
    ],
    yAxis: [
      {
        show: true,
        name: "MWh/year",
        nameLocation: "end",
        axisLine: {
          show: true,
        },
        axisLabel: {
          formatter: (value: number) => numberFormatter.format(value as number),
        },
      },
    ],
    series: [
      {
        name: intl.formatMessage({
          id: "energy-loss",
          defaultMessage: "Energy loss",
        }),
        type: "bar",
        barMaxWidth: 40,
        stack: "1",
        color: ChartColor.Red,
        label: {
          rotate: 90,
          show: true,
          color: "white",
          formatter: (p) => numberFormatter.format(p.value as number),
        },
        data: energyLossSeries,
      },
      {
        name: intl.formatMessage({
          id: "emission.chart.savings",
          defaultMessage: "Savings",
        }),
        stack: "1",
        type: "bar",
        color: ChartColor.Green,
        barMaxWidth: 40,
        data: energySavingSeries,
      },
    ],
  }
}
