import { TcoCalculationItem } from "../../../models/calculation/tcoCalculationItem"
import { Box, Flex, Heading, VStack, Text } from "@chakra-ui/react"
import React from "react"
import { TcoBarChart } from "./TcoBarChart/TcoBarChart"
import { TcoPieChart } from "./TcoPieChart/TcoPieChart"
import { FormattedMessage } from "react-intl"
import {
  isFlexPipes,
  isSmallPipesMax100,
  isSmallPipesOver100,
} from "../mappers/mapToLocalisedPreInsulatedPipeKind"

type TcoGraphProps = {
  title: string
  tcoDataItems: TcoCalculationItem[]
}
export const TcoGraph: React.FC<TcoGraphProps> = ({ title, tcoDataItems }) => {
  return (
    <>
      <Flex flexDirection={"row"} h={600}>
        <Box w={250}>
          <Heading textAlign={"left"} color={"brand.grey"} fontSize={20}>
            {title}
          </Heading>

          <TcoPieChart tcoDataItems={tcoDataItems} />

          {isSmallPipesMax100(tcoDataItems) && <TcoGraphDisclaimer20 />}
          {isSmallPipesOver100(tcoDataItems) && <TcoGraphDisclaimer125 />}
          {isFlexPipes(tcoDataItems) && <TcoGraphDisclaimerFlex />}
        </Box>
        <Box flex={1} mb="16">
          <TcoBarChart tcoDataItems={tcoDataItems} />
        </Box>
      </Flex>
    </>
  )
}

const TcoGraphDisclaimer20 = () => {
  return (
    <VStack
      w="100%"
      alignItems="left"
      borderRadius={2}
      spacing="0"
      paddingTop="8"
    >
      <Text as="b" fontSize="xs" color="grey">
        <FormattedMessage
          id="app.pipe_disclaimer.header20_100"
          defaultMessage="DN20-DN100 note regarding lambda values:"
        />
      </Text>
      <Text color="grey" fontSize="xs">
        <FormattedMessage
          id="app.pipe_disclaimer.twin_pipes"
          defaultMessage="* TwinPipe Conti DN100 series 2 and series 3 are TwinPipe Traditional pipes and have a lambda value of 0.027 W/mK"
        />
      </Text>
    </VStack>
  )
}

const TcoGraphDisclaimer125 = () => {
  return (
    <VStack
      w="100%"
      alignItems="left"
      borderRadius={2}
      spacing="0"
      paddingTop="8"
    >
      <Text as="b" fontSize="xs" color="grey">
        <FormattedMessage
          id="app.pipe_disclaimer.header125_200"
          defaultMessage="DN125-DN200 note regarding lambda values:"
        />
      </Text>
      <Text color="grey" fontSize="xs">
        <FormattedMessage
          id="app.pipe_disclaimer.pair_of_pipes"
          defaultMessage="* Pair of pipes, Conti in DN200 series 2 and series 3 have lambda value at 0,025 W/mK"
        />
      </Text>
    </VStack>
  )
}

const TcoGraphDisclaimerFlex = () => {
  return (
    <VStack
      w="100%"
      alignItems="left"
      borderRadius={2}
      spacing="0"
      paddingTop="8"
    >
      <Text color="grey" fontSize="xs">
        <FormattedMessage
          id="app.pipe_disclaimer.flex"
          defaultMessage="Standard dimensions according to the LOGSTOR product catalogue"
        />
      </Text>
    </VStack>
  )
}
