import {
  Button,
  ButtonGroup,
  ButtonProps,
  FormControl,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import axios, { AxiosError } from "axios"
import { useEffect } from "react"
import { useState } from "react"
import { FaStar } from "react-icons/fa"
import { FormattedMessage, useIntl } from "react-intl"
import { useQueryClient } from "react-query"
import { useNavigate } from "react-router-dom"
import { useFormData } from "../../../FormDataContext"
import { useSavedQuery } from "../../../hooks/useSavedQuery"
import { favoritesQueryKey } from "../../../rest/useFavorites"
import { useSaveFavorite } from "../../../rest/useSaveFavorite"
import { BrandedButton } from "../../shared/BrandedButton"

export const FavoriteButton: React.FC<ButtonProps> = (props) => {
  const intl = useIntl()
  const queryClient = useQueryClient()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const savedQuery = useSavedQuery()
  const [name, setName] = useState<string | undefined>(savedQuery?.name)
  const { mutate, isLoading } = useSaveFavorite(
    savedQuery != null && savedQuery.name === name
  )
  const { formData } = useFormData()
  const navigate = useNavigate()
  useEffect(() => {
    if (savedQuery) setName(savedQuery.name)
  }, [savedQuery])
  const toast = useToast()
  const onSubmit = () => {
    if (formData != null) {
      mutate(
        {
          name: name || "",
          formData: formData,
          id: savedQuery?.id,
        },
        {
          onSuccess: (data) => {
            const id = data.data
            queryClient.invalidateQueries(favoritesQueryKey)
            onClose()
            navigate({
              pathname: "/",
              search: `?savedQueryId=${id.replaceAll('"', "")}`,
            })
            toast({
              title: `${name} ${intl.formatMessage({
                id: "favourites.toast.success.title",
                defaultMessage: "successfully added to favourites.",
              })}`,
              status: "success",
              position: "bottom",
            })
          },
          onError: (error) => {
            if (
              axios.isAxiosError(error) &&
              (error as AxiosError).response?.status === 409
            ) {
              toast({
                title: intl.formatMessage({
                  id: "favourites.toast.error.title",
                  defaultMessage: "Title already in use.",
                }),
                description: intl.formatMessage({
                  id: "favourites.toast.error.description",
                  defaultMessage:
                    "There's already a title with that name. Please provide a unique name for the title.",
                }),
                status: "error",
                position: "bottom",
              })
            }
          },
        }
      )
    }
  }

  return (
    <>
      <Button
        {...props}
        borderColor="brand.grey"
        borderWidth="1px"
        background="white"
        color="black"
        size="lg"
        mt="10"
        mb="10"
        leftIcon={<FaStar />}
        onClick={onOpen}
      >
        <FormattedMessage
          id="app.add-to-favorites"
          defaultMessage="Add to favourites"
        />
      </Button>

      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <FormattedMessage
              id="app.add-to-favorites.header"
              defaultMessage="Add to favourites"
            />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Text mb="4">
              <FormattedMessage
                id="app.add-to-favorites.description"
                defaultMessage="Give your calculation a title so you can find it next time"
              />
            </Text>
            <FormControl>
              <Input
                value={name}
                autoFocus
                placeholder={intl.formatMessage({
                  id: "app.add-to-favorites.name",
                  defaultMessage: "Name",
                })}
                onChange={(e) => setName(e.target.value)}
                onKeyPress={(e) => e.key === "Enter" && onSubmit()}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <ButtonGroup>
              <Button onClick={onClose}>
                <FormattedMessage id="cancel" defaultMessage="Cancel" />
              </Button>
              <BrandedButton
                type="submit"
                isDisabled={name?.length === 0}
                primary
                isLoading={isLoading}
                onClick={onSubmit}
              >
                {savedQuery && savedQuery.name !== name ? (
                  <FormattedMessage
                    id="app.add-to-favorites.save-as"
                    defaultMessage="Save as new favourite"
                  />
                ) : (
                  <FormattedMessage
                    id="app.add-to-favorites.save"
                    defaultMessage="Save favourite"
                  />
                )}
              </BrandedButton>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
